import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { ProductImg2, CustomerImg1, ProductImg1 } from "../../constant";
import "../../assets/css/ecommerce.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProductDetails = () => {
	const [Review, SetReview] = useState("d-none");
	const [Description, SetDescription] = useState("");
	function ChangeData() {
		if (Description == "") {
			SetDescription("d-none");
			SetReview("");
		} else {
			SetDescription("");
			SetReview("d-none");
		}
	}
	const settings = {
		dots: false,
		arrows: true,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					arrows: false,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					arrows: false,
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					arrows: false,
					dots : true
				}
			}
		]
	};
	const prod = [
		{
			id: 1,
		},
		{
			id: 1,
		},
		{
			id: 1,
		},
		{
			id: 1,
		},
		{
			id: 1,
		},
	];
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}
			<section className="ecommerce-products pt-5">
				<div className="container">
					<div className="product-details pt-3">
						<div className="row align-items-center">
							<div className="col-md-5 pe-lg-2">
								<figure className="product-image">
									<img src={ProductImg2} className="img-fluid" alt="" />
								</figure>
							</div>
							<div className="col-md-6">
								<div className="about-product">
									<h2>Product Name </h2>
									<div className="reviews">
										<span className="rating">
											<i className="fa fa-star"></i>
											<i className="fa fa-star"></i>
											<i className="fa fa-star"></i>
											<i className="fa fa-star"></i>
											<i className="fa fa-star"></i>
										</span>

										<span onClick={ChangeData} className="total-review">
											<a href="#description-reviews">(4 customer reviews)</a>
										</span>
									</div>
									<div className="details-para mb-3">
										<h5 className="small">Quick Overview</h5>
										<p>
											Habitant morbi tristique senectus et. Nullam vehicula
											ipsum a arcu cursus. Aliquam pu rus sit amet luctus
											venenatis lectus magna fringilla urna. Malesuada
											pellentesque elit eget gravida cum sociis natoque.
											Faucibus ornare suspendisse sed nisi lacus.{" "}
										</p>
										<div className="price mb-4">
											<span>£600 </span>
											<s>£850</s>
										</div>
										<div className="quantity-counter">
											<button>-</button>
											<span>3</span>
											<button>+</button>
										</div>
									</div>
									<Link to="/cart" className="btn">
										Add To Cart
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="description-reviews" id="description-reviews">
						<div className="tabs-handler">
							<button
								onClick={ChangeData}
								className={Description == "" ? "active" : ""}
							>
								Description
							</button>
							<button
								onClick={ChangeData}
								className={Review == "" ? "active" : ""}
							>
								Reviews
							</button>
						</div>
						<div className="total-data mt-4 pt-2">
							<div className={"description " + Description}>
								<p className="mb-5">
									Habitant morbi tristique senectus et. Nullam vehicula ipsum a
									arcu cursus. Aliquam purus sit amet luctus venenatis lectus
									magna fringilla urna. Malesuada pellentesque elit eget gravida
									cum sociis natoque. Faucibus ornare suspendisse sed nisi
									lacus. Consectetur adipiscing elit duis tristique sollicitudin
									nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
									egestas sed tempus urna et pharetra pharetra massa. Enim ut
									tellus elementum sagittis vitae et leo duis ut. ntesque elit
									eget gravida cum sociis natoque. Faucibus ornare suspendisse
									sed nisi lacus. Consectetur adipiscing elit duis tristique
									sollicitudin nibh sit amet. Non curabitur gravida arcu ac
									tortor. Ac turHabitant morbi tristique senectus et. Nullam
									vehicula ipsum a arcu cursus. Aliquam purus sit amet luctus
									venenatis lectus magna fringilla urna. Malesuada pellentesque
									elit eget gravida cum sociis natoque. Faucibus ornare
									suspendisse sed nisi lacus. Consectetur adipiscing elit duis
									tristique sollicitudin nibh sit amet. Non curabitur gravida
									arcu ac tortor.
								</p>
								<p>
									Ac turpis egestas sed tempus urna et pharetra pharetra massa.
									Enim ut tellus elementum sagittis vitae et leo duis ut.
									ntesque elit eget gravida cum sociis natoque. Faucibus ornare
									suspendisse sed nisi lacus. Consectetur adipiscing elit duis
									tristique sollicitudin nibh sit amet. Non curabitur gravida
									arcu ac tortor. Ac tur
								</p>
								<div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto px-xl-4 mt-5 pt-2">
									<div className="top-heading text-center form-headingin">
										<h6 className="small-heading mb-0">
											Manchester Islamic Centre
										</h6>
										<h3 className="mb-0 large-heading">Popular Products</h3>
									</div>
								</div>
								<div className="products-list">
									<div className="row">
										<Slider {...settings}>
											{prod.map((data, index) => (
												<div className="col-md-3 pb-3" key={index}>
													<div className="single-product">
														<figure>
															<img
																src={ProductImg1}
																alt=""
																className="img-fluid"
															/>
														</figure>
														<div className="text">
															<div className="name-rating">
																<h5>Product name</h5>
																<span>
																	<i className="fa fa-star"></i> 4.5
																</span>
															</div>
															<p>Diam ut venenatis tellus in metus </p>
															<div className="price">
																<span>£600 </span>
																<s>£850</s>
															</div>
															<Link to="/" className="cart-btn">
																Add to cart
															</Link>
														</div>
													</div>
												</div>
											))}
										</Slider>
									</div>
								</div>
							</div>
							<div className={"reviews-list " + Review}>
								<div className="single-review">
									<figure className="customer-image">
										<img src={CustomerImg1} alt="" />
									</figure>
									<div className="review-box">
										<div className="cus-info-rating">
											<div className="cus-info">
												<span className="cus-name">Cobus Bester</span>
												<span className="review-date">- Nov 29 ,2022</span>
											</div>
											<span className="cus-rating">
												<i className="fa fa-star"></i>
												<i className="fa fa-star"></i>
												<i className="fa fa-star"></i>
												<i className="fa fa-star"></i>
												<i className="fa fa-star"></i>
											</span>
										</div>
										<div className="review-data">
											<p>
												Habitant morbi tristique senectus et. Nullam vehicula
												ipsum a arcu cursus. Aliquam pu rus sit amet luctus
												venenatis lectus magna
											</p>
										</div>
									</div>
								</div>
								<div className="single-review">
									<figure className="customer-image">
										<img src={CustomerImg1} alt="" />
									</figure>
									<div className="review-box">
										<div className="cus-info-rating">
											<div className="cus-info">
												<span className="cus-name">Cobus Bester</span>
												<span className="review-date">- Nov 29 ,2022</span>
											</div>
											<span className="cus-rating">
												<i className="fa fa-star"></i>
												<i className="fa fa-star"></i>
												<i className="fa fa-star"></i>
												<i className="fa fa-star"></i>
												<i className="fa fa-star"></i>
											</span>
										</div>
										<div className="review-data">
											<p>
												Habitant morbi tristique senectus et. Nullam vehicula
												ipsum a arcu cursus. Aliquam pu rus sit amet luctus
												venenatis lectus magna
											</p>
										</div>
									</div>
								</div>
								<div className="single-review">
									<figure className="customer-image">
										<img src={CustomerImg1} alt="" />
									</figure>
									<div className="review-box">
										<div className="cus-info-rating">
											<div className="cus-info">
												<span className="cus-name">Cobus Bester</span>
												<span className="review-date">- Nov 29 ,2022</span>
											</div>
											<span className="cus-rating">
												<i className="fa fa-star"></i>
												<i className="fa fa-star"></i>
												<i className="fa fa-star"></i>
												<i className="fa fa-star"></i>
												<i className="fa fa-star"></i>
											</span>
										</div>
										<div className="review-data">
											<p>
												Habitant morbi tristique senectus et. Nullam vehicula
												ipsum a arcu cursus. Aliquam pu rus sit amet luctus
												venenatis lectus magna
											</p>
										</div>
									</div>
								</div>
								<div className="single-review">
									<figure className="customer-image">
										<img src={CustomerImg1} alt="" />
									</figure>
									<div className="review-box">
										<div className="cus-info-rating">
											<div className="cus-info">
												<span className="cus-name">Cobus Bester</span>
												<span className="review-date">- Nov 29 ,2022</span>
											</div>
											<span className="cus-rating">
												<i className="fa fa-star"></i>
												<i className="fa fa-star"></i>
												<i className="fa fa-star"></i>
												<i className="fa fa-star"></i>
												<i className="fa fa-star"></i>
											</span>
										</div>
										<div className="review-data">
											<p>
												Habitant morbi tristique senectus et. Nullam vehicula
												ipsum a arcu cursus. Aliquam pu rus sit amet luctus
												venenatis lectus magna
											</p>
										</div>
									</div>
								</div>
								<div className="review-btn-wrapper text-center">
									<button className="btn mx-auto">Load More</button>
								</div>
								<div className="review-add mt-5 pt-4">
									<h4>Add Review</h4>
									<p className="mt-2">
										Your email address will not be published.Required field are
										marked *
									</p>
									<form>
										<div className="row">
											<div className="col-md-12 mb-4">
												<p className="mb-2">Your rating</p>
												<span>
													<i className="fa fa-star"></i>
													<i className="fa fa-star"></i>
													<i className="fa fa-star"></i>
													<i className="fa fa-star"></i>
													<i className="fa fa-star"></i>
												</span>
											</div>
											<div className="col-md-4 mb-3">
												<div className="form-group">
													<label htmlFor="">Name *</label>
													<input type="text" className="form-control" />
												</div>
											</div>
											<div className="col-md-4 mb-3">
												<div className="form-group">
													<label htmlFor="">Email Address *</label>
													<input type="text" className="form-control" />
												</div>
											</div>
											<div className="col-md-12 mb-3">
												<div className="form-group">
													<label htmlFor="">Your Review *</label>
													<textarea
														type="text"
														className="form-control lg"
														placeholder="Your answer"
													></textarea>
												</div>
											</div>
										</div>
										<div className="d-flex justify-content-end">
											<button className="btn px-5">Submit</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default ProductDetails;
