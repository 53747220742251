import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactSec from "../../components/ContactSec";
import FormHeadings from "../../components/FormHeadings";
import { Link } from "react-router-dom";
import { ClipIcon } from "../../constant";
import { useNavigate } from "react-router-dom";
const EnquirForm = () => {
	const navigate = useNavigate();
	const handleSubmit = (event) => {
		event.preventDefault();
		// perform form submission logic here
		navigate("/checkout"); // navigate to the success page
	};
	return (
		<>
			<Header />
			<section className="custom-forms">
				<div className="container">
					<FormHeadings
						SmallHeading="Manchester Islamic Centre"
						MainHeading="Enquiry Form"
						Text=" Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas "
					/>
					<div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
						<div className="custom-form-start">
							<form onSubmit={handleSubmit}>
								<div className="row">
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="FirstName">First Name *</label>
											<input
												type="text"
												name="FirstName"
												id="FirstName"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="MiddleName">Middle Name</label>
											<input
												type="text"
												name="MiddleName"
												id="MiddleName"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="Surname">Surname *</label>
											<input
												type="text"
												name="Surname"
												id="Surname"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="PhoneNumber">Phone Number *</label>
											<input
												type="text"
												name="PhoneNumber"
												id="PhoneNumber"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="EmailAddress">Email Address *</label>
											<input
												type="text"
												name="EmailAddress"
												id="EmailAddress"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="Ethnicity">Ethnicity</label>
											<input
												type="text"
												name="Ethnicity"
												id="SecondLineOfAddress"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>

									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">Preferred Language *</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													Arabic
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													English
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													Other
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="statequestion">
												Please state your question *
											</label>
											<input
												type="text"
												name="statequestion"
												id="statequestion"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12 mt-2">
										<div className="form-group d-flex gap-3">
											<button type="submit" className="btn px-5 ms-auto">
												Submit
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			<ContactSec />
			<Footer />
		</>
	);
};
export default EnquirForm;
