import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { AcrivityImg1, AcrivityImg2, AcrivityImg3 } from "../../constant";
// import { Link } from "react-router-dom";
import ContactSec from "../../components/ContactSec";

const ActivityDetails = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,

    });
  });
  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}
      {/* Family starts here */}
      <section className="about-mission">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-mission-content-wrapper mb-4">
                <h6 className="small-heading">Manchester Islamic Centre</h6>
                <h2>Activity Detail</h2>
                <p>
                  Habitant morbi tristique senectus et. Nullam vehicula ipsum a
                  arcu cursus. Aliquam purus sit amet luctus venenatis lectus
                  magna fringilla urna. Malesuada pellentesque elit eget gravida
                  cum sociis natoque. Faucibus ornare suspendisse sed nisi
                  lacus. Consectetur adipiscing elit duis tristique sollicitudin
                  nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
                  egestas sed tempus urna et pharetra pharetra massa. Enim ut
                  tellus elementum sagittis vitae et leo duis ut. ntesque elit
                  eget gravida cum sociis natoque. Faucibus ornare suspendisse
                  sed nisi lacus. Consectetur adipiscing
                </p>
                <p>
                  Habitant morbi tristique senectus et. Nullam vehicula ipsum a
                  arcu cursus. Aliquam purus sit amet luctus venenatis lectus
                  magna fringilla urna. Malesuada pellentesque elit eget gravida
                  cum sociis natoque. Faucibus ornare suspendisse sed nisi
                  lacus. Consectetur adipiscing elit duis tristique sollicitudin
                  nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
                  egestas sed tempus urna
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-img-wrapper">
                <figure>
                  <img src={AcrivityImg1} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Family End here */}
      {/* Apply Now starts here */}
      <section className="apply-now  with-position position-2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-1"></div>
            <div className="col-lg-5">
              <div className="apply-now-content-wrapper">
                <h6>Manchester Islamic Centre</h6>
                <h2>Activity Detail</h2>
                <p>
                  Habitant morbi tristique senectus et. Nullam vehicula ipsum a
                  arcu cursus. Aliquam purus sit amet luctus venenatis lectus
                  magna fringilla urna. Malesuada pellentesque elit eget gravida
                  cum sociis natoque. Faucibus ornare suspendisse sed nisi
                  lacus. Consectetur adipiscing elit duis tristique sollicitudin
                  nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
                  egestas sed tempus urna et pharetra pharetra massa. Enim ut
                  tellus elementum sagittis vitae et leo duis ut. ntesque elit
                  eget gravida cum sociis natoque. Faucibus ornare suspendisse
                  sed nisi lacus. Consectetur adipiscing elit duis tristique
                  sollicitudin nibh sit amet. Non curabitur gravida arcu ac
                  tortor. Ac tur
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="family-starts-here">
                <figure>
                  <img src={AcrivityImg2} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Apply Now End here */}
      {/* Family Counceling starts here */}
      <section className="about-mission">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-img-wrapper">
                <figure>
                  <img src={AcrivityImg3} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-mission-content-wrapper mb-4">
                <h6 className="small-heading">Manchester Islamic Centre</h6>
                <h2>Activity Detail</h2>
                <p>
                  Habitant morbi tristique senectus et. Nullam vehicula ipsum a
                  arcu cursus. Aliquam purus sit amet luctus venenatis lectus
                  magna fringilla urna. Malesuada pellentesque elit eget gravida
                  cum sociis natoque. Faucibus ornare suspendisse sed nisi
                  lacus. Consectetur adipiscing elit duis tristique sollicitudin
                  nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
                  egestas sed tempus urna et pharetra pharetra massa. Enim ut
                  tellus elementum sagittis vitae et leo duis ut. ntesque elit
                  eget gravida cum sociis natoque. Faucibus ornare suspendisse
                  sed nisi lacus. Consectetur adipiscing
                </p>
                <p>
                  Habitant morbi tristique senectus et. Nullam vehicula ipsum a
                  arcu cursus. Aliquam purus sit amet luctus venenatis lectus
                  magna fringilla urna. Malesuada pellentesque elit eget gravida
                  cum sociis natoque. Faucibus ornare suspendisse sed nisi
                  lacus. Consectetur adipiscing elit duis tristique sollicitudin
                  nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
                  egestas sed tempus urna
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ContactSec />
      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

export default ActivityDetails;
