import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactSec from "../../components/ContactSec";
import FormHeadings from "../../components/FormHeadings";
import { Link } from "react-router-dom";
import { ClipIcon } from "../../constant";
import { useNavigate } from "react-router-dom";
const TalaqWitness2Details = () => {
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    // perform form submission logic here
    navigate("/checkout"); // navigate to the success page
  };
  return (
    <>
      <Header />
      <section className="custom-forms">
        <div className="container">
          <FormHeadings
            SmallHeading="Manchester Islamic Centre"
            MainHeading="Talaq Application"
            Text="Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas "
          />
          <div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
            <div className="custom-form-start">
              <h6 className="form-inner-heading mt-1 mb-3">
                Witness 2 Personal Details
              </h6>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="FirstName">First Name </label>
                      <input
                        type="text"
                        name="FirstName"
                        id="FirstName"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="MiddleName">Middle Name</label>
                      <input
                        type="text"
                        name="MiddleName"
                        id="MiddleName"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="Surname">Surname </label>
                      <input
                        type="text"
                        name="Surname"
                        id="Surname"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="DateOfBirth">Date Of Birth </label>
                      <input
                        type="text"
                        name="DateOfBirth"
                        id="DateOfBirth"
                        className="form-control"
                        placeholder="DD/MM/YYYY"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="Address">Address </label>
                      <input
                        type="text"
                        name="Address"
                        id="Address"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="Address1">Address 1 </label>
                      <input
                        type="text"
                        name="Address1"
                        id="Address1"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="Address2">Address 2</label>
                      <input
                        type="text"
                        name="Address2"
                        id="Address2"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="City">City </label>
                      <select
                        name="City"
                        id="City"
                        className="form-select form-control"
                      >
                        <option value="Your Answer"></option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="Postcode">Postcode </label>
                      <input
                        type="text"
                        name="Postcode"
                        id="Postcode"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="TelephoneNumber">Telephone Number </label>
                      <input
                        type="text"
                        name="TelephoneNumber"
                        id="TelephoneNumber"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="EmailAddress">Email Address </label>
                      <input
                        type="email"
                        name="EmailAddress"
                        id="EmailAddress"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="Nationality">Nationality </label>
                      <input
                        type="text"
                        name="Nationality"
                        id="Nationality"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">ID Type </label>
                      <div className="custom-check-box-default">
                        <label className="label-checkbox">
                          Passport
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          Driving License
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          Residence Card
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          Other
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3 pt-3">
                      <label htmlFor="">Please Upload Your Photo ID Here</label>
                      <div className="file-uploader-custom">
                        <label htmlFor="UploadFile" className="fileuploader">
                          <input
                            type="file"
                            name="UploadFile"
                            hidden
                            id="UploadFile"
                          />
                          <span>
                            Drag & Drop .PDF Files here or click to upload
                          </span>
                        </label>
                        <div className="col-md-6 px-0">
                          <div className="files-list">
                            <div className="single-file">
                              <div className="icon-text">
                                <img src={ClipIcon} alt="" />
                                <span>Attachment 1 .pdf</span>
                              </div>
                              <span className="removefile">Remove</span>
                            </div>
                            <div className="single-file">
                              <div className="icon-text">
                                <img src={ClipIcon} alt="" />
                                <span>Attachment 1 .pdf</span>
                              </div>
                              <span className="removefile">Remove</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="IDNumber">ID Number</label>
                      <input
                        type="text"
                        name="IDNumber"
                        id="IDNumber"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mt-2">
                    <div className="form-group d-flex gap-3">
                      <Link
                        to="/talaq-witness1-details"
                        className="btn ms-auto px-5"
                      >
                        Back
                      </Link>
                      <button type="submit" className="btn px-5">
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ContactSec />
      <Footer />
    </>
  );
};
export default TalaqWitness2Details;
