import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
	MemberImg1,
	MemberImg2,
	MemberImg3,
	MemberImg4,
	ConfernceImg1,
	ConfernceImg2,
	ConfernceImg3,
	ConfernceImg4,
} from "../../constant";
import ContactSec from "../../components/ContactSec";
import "../../assets/css/team-member.css";
import FaqSection from "../../components/FaqSection";
import ApplyNow from "../../components/ApplyNow";

const UpcomingConfrence = () => {
	const SmallHeading = "Manchester Islamic Centre";
	const MainHeading = "Conference Name 1";
	const Text =
		"Habitant morbi tristique senectus et. Nullam vehicula ipsum a arcu cursus. Aliquam purus sit amet luctus venenatis lectus magna fringilla urna. Malesuada pellentesque elit eget gravida cum sociis natoque. Faucibus ornare suspendisse sed nisi lacus. Consectetur adipiscing elit duis tristique sollicitudin nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis egestas sed tempus urna";
	let MemberName = "Mr Fawzi Al Haffar";
	let MemberPost = "Chairman";
	const Conferences = [
		{
			smallheaidng: SmallHeading,
			mainheading: MainHeading,
			text: Text,
			Img: ConfernceImg1,
		},
		{
			smallheaidng: SmallHeading,
			mainheading: MainHeading,
			text: Text,
			Img: ConfernceImg2,
		},
		{
			smallheaidng: SmallHeading,
			mainheading: MainHeading,
			text: Text,
			Img: ConfernceImg3,
		},
		{
			smallheaidng: SmallHeading,
			mainheading: MainHeading,
			text: Text,
			Img: ConfernceImg4,
		},
	];
	const Members = [
		{
			name: MemberName,
			post: MemberPost,
			Img: MemberImg1,
		},
		{
			name: MemberName,
			post: MemberPost,
			Img: MemberImg2,
		},
		{
			name: MemberName,
			post: MemberPost,
			Img: MemberImg3,
		},
		{
			name: MemberName,
			post: MemberPost,
			Img: MemberImg4,
		},
	];
	console.log(Members);
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}
			<section className="team-members">
				<div className="container">
					<div className="tean-merbers-start">
						<div className="col-lg-6 mx-auto px-xl-4">
							<div className="top-heading text-center">
								<h6 className="small-heading mb-0">
									Manchester Islamic Centre
								</h6>
								<h2 className="mb-0">Upcoming Conference</h2>
								<p className="mb-0">
									Sed cras ornare arcu dui vivamus arcu felis bibendum ut.
									Tempus egestas sed sed risus pretium quam vulputate dignissim.
									Quam pellentesque nec nam aliquam
								</p>
							</div>
						</div>
						<div className="conference-list">
							<div className="row">
								{Conferences.map((Conference, index) => (
									<div className="col-md-12 mt-5" key={index}>
										<div className="single-conference">
											<div className="row">
												<div className="col-md-6 pe-xxl-5 pe-xl-4">
													<figure>
														<img
															src={Conference.Img}
															className="w-100"
															alt=""
														/>
													</figure>
												</div>
												<div className="col-md-6">
													<div className="confoerence-data">
														<div className="text">
															<h6 className="small-heading">
																{Conference.smallheaidng}
															</h6>
															<h3 className="large-heading">
																{Conference.mainheading}
															</h3>
															<p>{Conference.text}</p>
															<Link
																className="btn px-5 "
																to="/conference-register"
															>
																Register
															</Link>
														</div>
														<div className="speakers mt-4">
															<h5>Speakers</h5>
															<div className="members-list">
																<div className="row">
																	{Members.map((data, index) => (
																		<div
																			className="col-md-3 mt-2 px-1"
																			key={index}
																		>
																			<div className="single-member">
																				<figure>
																					<img
																						src={data.Img}
																						className="w-100"
																						alt=""
																					/>
																				</figure>
																				<div className="member-data">
																					<h4>{data.name}</h4>
																					<h5 className="small-pnik-heading">
																						{data.post}
																					</h5>
																				</div>
																			</div>
																		</div>
																	))}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
						<div className="paginations-start mt-5">
							<ul className="pagination-list">
								<li>
									<Link className="normal active">01.</Link>
								</li>
								<li>
									<Link className="normal">02.</Link>
								</li>
								<li>
									<Link className="normal">03.</Link>
								</li>
								<li>
									<Link className="normal">Next</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
			<ContactSec />
			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default UpcomingConfrence;
