import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { EidDetails, documentimg } from "../../constant";
import "../../assets/css/about.css";
import ContactSec from "../../components/ContactSec";
import { Link } from "react-router-dom";
import {
	GalImg1,
	GalImg2,
	GalImg3,
	GalImg4,
	GalImg5,
	GalImg6,
	GalImg7,
	IconContact,
	GirlActivityImg1,
} from "../../constant";
const GirlsActitvity = () => {
	const GalleryImages = [
		{
			id: 1,
			image: GalImg1,
		},
		{
			id: 2,
			image: GalImg2,
		},
		{
			id: 3,
			image: GalImg3,
		},
		{
			id: 4,
			image: GalImg4,
		},

		{
			id: 5,
			image: GalImg5,
		},
		{
			id: 6,
			image: GalImg6,
		},
		{
			id: 7,
			image: GalImg7,
		},
		{
			id: 8,
			image: GalImg2,
		},
		{
			id: 9,
			image: GalImg4,
		},
		{
			id: 7,
			image: GalImg7,
		},
		{
			id: 8,
			image: GalImg2,
		},
		{
			id: 9,
			image: GalImg4,
		},
	];
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}
			{/* About starts here */}
			<section className="about-mission">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="about-mission-content-wrapper mb-4">
								<h6 className="small-heading">Manchester Islamic Centre</h6>
								<h2>Girls Activities</h2>
								<p>
									The Manchester Islamic Centre was formerly the Albert Park
									Methodist Church , which opened for worship in 1883, but in
									1962, the church closed down. In 1967, it was bought by some
									members of the Syrian Arab community to be used as a Mosque
									for the Manchester Muslim community. The property consists of
									two large buildings, one is used as a Mosque and library, and
									the other includes a prayer hall for women, social events
									hall, classrooms, a kitchen and an ablution facility.
								</p>
								<a href="#" className="btn">
									<span className="icon">
										<img src={documentimg} alt="" />
									</span>
									<span>Application Form</span>
								</a>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-img-wrapper">
								<figure>
									<img src={GirlActivityImg1} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* About End here */}
			{/* Activities Start Here  */}
			<section className="activites-section">
				<div className="container">
					<div className="activities-start-here">
						<div className="lecture-filter">
							<button className="norma-btn active">All Activities</button>
							<button className="norma-btn">Popular Activities</button>
						</div>
						<div className="activities-list">
							<div className="row">
								<div className="col-lg-4 col-md-6 mb-4 pt-4 pb-3 px-0 column">
									<div className="single-activity">
										<h4>Activity</h4>
										<p>
											Habitant morbi tristique senectus et. Nullam vehicula
											ipsum a arcu cursus. Aliquam purus sit amet luctus
											venenatis lectus magna fringilla urna. Malesuada
											pellentesque elit eget gravida cum sociis natoque.{" "}
										</p>
										<Link to="" className="btn">
											Register
										</Link>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-4 pt-4 pb-3 px-0 column">
									<div className="single-activity">
										<h4>Activity</h4>
										<p>
											Habitant morbi tristique senectus et. Nullam vehicula
											ipsum a arcu cursus. Aliquam purus sit amet luctus
											venenatis lectus magna fringilla urna. Malesuada
											pellentesque elit eget gravida cum sociis natoque.{" "}
										</p>
										<Link to="" className="btn">
											Register
										</Link>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-4 pt-4 pb-3 px-0 column">
									<div className="single-activity">
										<h4>Activity</h4>
										<p>
											Habitant morbi tristique senectus et. Nullam vehicula
											ipsum a arcu cursus. Aliquam purus sit amet luctus
											venenatis lectus magna fringilla urna. Malesuada
											pellentesque elit eget gravida cum sociis natoque.{" "}
										</p>
										<Link to="" className="btn">
											Register
										</Link>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-4 pt-4 pb-3 px-0 column">
									<div className="single-activity">
										<h4>Activity</h4>
										<p>
											Habitant morbi tristique senectus et. Nullam vehicula
											ipsum a arcu cursus. Aliquam purus sit amet luctus
											venenatis lectus magna fringilla urna. Malesuada
											pellentesque elit eget gravida cum sociis natoque.{" "}
										</p>
										<Link to="" className="btn">
											Register
										</Link>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-4 pt-4 pb-3 px-0 column">
									<div className="single-activity">
										<h4>Activity</h4>
										<p>
											Habitant morbi tristique senectus et. Nullam vehicula
											ipsum a arcu cursus. Aliquam purus sit amet luctus
											venenatis lectus magna fringilla urna. Malesuada
											pellentesque elit eget gravida cum sociis natoque.{" "}
										</p>
										<Link to="" className="btn">
											Register
										</Link>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 mb-4 pt-4 pb-3 px-0 column">
									<div className="single-activity">
										<h4>Activity</h4>
										<p>
											Habitant morbi tristique senectus et. Nullam vehicula
											ipsum a arcu cursus. Aliquam purus sit amet luctus
											venenatis lectus magna fringilla urna. Malesuada
											pellentesque elit eget gravida cum sociis natoque.{" "}
										</p>
										<Link to="" className="btn">
											Register
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="btn-wrapper text-center">
						<button className="btn mx-auto">Load More</button>
					</div>
				</div>
			</section>
			{/* Activities End Here  */}
			{/* Gallery Start Here */}
			<section className="photo-gallery">
				<div className="container">
					<div className="col-xxl-11 mx-auto">
						<div className="gallery-start">
							<div className="col-lg-7 mx-auto px-xl-4">
								<div className="top-heading text-center">
									<h6 className="small-heading mb-0">
										Manchester Islamic Centre
									</h6>
									<h2 className="mb-0">Previous Activites Gallery</h2>
									<p className="mb-0">
										Sed cras ornare arcu dui vivamus arcu felis bibendum ut.
										Tempus egestas sed sed risus pretium quam vulputate
										dignissim. Quam pellentesque nec nam aliquam
									</p>
								</div>
							</div>
							<div className="galler-images gallery-scrollabe">
								<div className="row">
									{GalleryImages.map((data, index) => (
										<div className="col-md-4 p-2" key={index}>
											<figure>
												<img src={data.image} className="img-fluid" alt="" />
											</figure>
										</div>
									))}
								</div>
							</div>
							{/* <div className="row">
								<div className="col-lg-12 pt-5">
									<div className="btn-wrapper text-center">
										<button className="btn mx-auto">Load more </button>
									</div>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</section>
			{/* Gallery End Here */}
			<ContactSec />
			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default GirlsActitvity;
