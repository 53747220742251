import React from "react";
import { Link } from 'react-router-dom'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
    MemberImg1,
    MemberImg2,
    MemberImg3,
    MemberImg4,
} from "../../constant";
import ContactSec from "../../components/ContactSec";
import "../../assets/css/team-member.css";
import FaqSection from "../../components/FaqSection";
import ApplyNow from "../../components/ApplyNow";

const TeamMembers = () => {
    let MemberName = "Mr Fawzi Al Haffar";
    let MemberPost = "Chairman";

    const Members = [
        {
            name: MemberName,
            post: MemberPost,
            Img: MemberImg1
        },
        {
            name: MemberName,
            post: MemberPost,
            Img: MemberImg2
        },
        {
            name: MemberName,
            post: MemberPost,
            Img: MemberImg3
        },
        {
            name: MemberName,
            post: MemberPost,
            Img: MemberImg4
        },
        {
            name: MemberName,
            post: MemberPost,
            Img: MemberImg1
        },
        {
            name: MemberName,
            post: MemberPost,
            Img: MemberImg2
        },
        {
            name: MemberName,
            post: MemberPost,
            Img: MemberImg3
        },
        {
            name: MemberName,
            post: MemberPost,
            Img: MemberImg4
        },
        {
            name: MemberName,
            post: MemberPost,
            Img: MemberImg1
        },
        {
            name: MemberName,
            post: MemberPost,
            Img: MemberImg3
        },
        {
            name: MemberName,
            post: MemberPost,
            Img: MemberImg3
        },
        {
            name: MemberName,
            post: MemberPost,
            Img: MemberImg4
        },
    ]
    console.log(Members);
    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}
            <section className="team-members">
                <div className="container">
                    <div className="tean-merbers-start">
                        <div className="col-lg-6 mx-auto px-xl-4">
                            <div className="top-heading text-center">
                                <h6 className="small-heading mb-0">
                                    Manchester Islamic Centre
                                </h6>
                                <h2 className="mb-0">
                                    List Of Team Members
                                </h2>
                                <p className="mb-0">
                                    Sed cras ornare arcu dui vivamus arcu felis bibendum ut.
                                    Tempus egestas sed sed risus pretium quam vulputate
                                    dignissim. Quam pellentesque nec nam aliquam
                                </p>
                            </div>
                        </div>
                        <div className="members-list">
                            <div className="row">
                                {Members.map((data, index) => (
                                    <div className="col-md-3 mt-5" key={index}>
                                        <div className="single-member">
                                            <Link to="">
                                                <figure>
                                                    <img src={data.Img} className="w-100" alt="" />
                                                </figure>
                                                <div className="member-data">
                                                    <h4>{data.name}</h4>
                                                    <h5 className="small-pnik-heading">{data.post}</h5>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="text-center mt-5">
                                <button className="btn mx-auto">Load More</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ContactSec />
            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

export default TeamMembers;