
import React from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import ContactSec from '../../components/ContactSec';
import FormHeadings from '../../components/FormHeadings';
import { Link } from 'react-router-dom'
import { ClipIcon } from '../../constant';
import { useNavigate } from 'react-router-dom';
const AppilcantDetailsWifeDivorce = () => {
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        // perform form submission logic here
        navigate('/wife-divorce-husband-details'); // navigate to the success page
    }
    return (
        <>
            <Header />
            <section className="custom-forms">
                <div className="container">
                    <FormHeadings SmallHeading="Manchester Islamic Centre" MainHeading="Wife Seeking Divorce From Her Husband" containerSize="col-xl-9 col-lg-9 col-md-10 col-sm-12 mx-auto px-xl-4" Text="Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas " />
                    <div className="col-lg-10 mx-auto mt-3 pb-5">
                        <div className="main-points-service main-point-box-shadow">
                            <div className="col-lg-11 mx-auto px-4">

                                <p>This form is to be used if the wife is petitioning for Islamic divorce/ Talaq. Please ensure all questions are answered in this application form otherwise it may cause a delay in your case being processed. To help us process this application quickly , please provide us with as much information as possible to support you application by the way of evidence.
                                    <br /> Please enclose a copy of the following:
                                </p>
                                <ol>
                                    <li>Form of ID for the applicant (e.g. passport )</li>
                                    <li>Form of ID for the respondent (e.g. passport )</li>
                                    <li>Your Nikah Certificate</li>
                                    <li>Your Civil Marriage Certificate (if Applicable)</li>
                                    <li>Your Decree Nisi or Decree Absolute (if Applicable)</li>
                                    <li>Letter from the solicitor or court (if Applicable)</li>
                                    <li>A Statement of Dissolution</li>
                                </ol>
                                <p className='mb-0'>PAYMENT</p>
                                <ul>
                                    <li>a) First Stage - Settlement</li>
                                    <li>i. The first payment of £250 to be paid via bank transfer, cash or debit/credit card before starting the case.</li>
                                    <li>ii. We will contact the Husband and initiate the Khula process. </li>
                                    <li>iii. We will seek solution for both parties (reconciliation or divorce). </li>
                                    <li className='mt-3'>b) Second Stage- Divorce Judgment <br />
                                        Additional £150 will be charged if the parties failed to reach divorce settlement or if Respondent (the Husband) is not reachable or not cooperating with us. We will notify you when we have reached this stage.
                                    </li>
                                </ul>
                                <p className='mb-0'>Please note marriages which take place abroad, are recognised by the relevant law of the land and therefore recognised by the English Law as a legal marriage.
                                    Please ensure you fill all relevant sections of the form. Any incomplete forms will either be returned to the applicant or not recognised.</p>
                            </div>
                        </div>
                    </div>
                    <FormHeadings SmallHeading="Manchester Islamic Centre" MainHeading="Applicant Details" Text="Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas " />
                    <div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
                        <div className="custom-form-start">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="FirstName">First Name *</label>
                                            <input type="text" name='FirstName' id='FirstName' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="MiddleName">Middle Name</label>
                                            <input type="text" name='MiddleName' id='MiddleName' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Surname">Surname *</label>
                                            <input type="text" name='Surname' id='Surname' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="DateOfBirth">Date Of Birth *</label>
                                            <input type="text" name='DateOfBirth' id='DateOfBirth' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="CountryOfBirth">Country Of Birth *</label>
                                            <input type="text" name='CountryOfBirth' id='CountryOfBirth' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Nationality">Nationality *</label>
                                            <input type="text" name='Nationality' id='Nationality' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Language">First Language / Home Language </label>
                                            <input type="text" name='Language' id='Language' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Ethnicity">Ethnicity *</label>
                                            <input type="text" name='Ethnicity' id='Ethnicity' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Address1">Address 1 *</label>
                                            <input type="text" name='Address1' id='Address1' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Address2">Address 2</label>
                                            <input type="text" name='Address2' id='Address2' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="City">City *</label>
                                            <select name="City" id="City" className='form-select form-control'>
                                                <option value="Your Answer"></option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Postcode">Postcode *</label>
                                            <input type="text" name='Postcode' id='Postcode' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="TelephoneNumber">Telephone Number *</label>
                                            <input type="text" name='TelephoneNumber' id='TelephoneNumber' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="EmailAddress">Email Address </label>
                                            <input type="email" name='EmailAddress' id='EmailAddress' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="MotherName">Mother Full Name</label>
                                            <input type="text" name='MotherName' id='MotherName' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="FatherName">Father Full Name</label>
                                            <input type="text" name='FatherName' id='FatherName' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="">ID Type *</label>
                                            <div className="custom-check-box-default">
                                                <label className="label-checkbox">Passport

                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Driving License
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Residence Card
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Other
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3 pt-3">
                                            <label htmlFor="">Please Upload Your Photo ID Here</label>
                                            <div className="file-uploader-custom">
                                                <label htmlFor="UploadFile" className='fileuploader'>
                                                    <input type="file" name="UploadFile" hidden id="UploadFile" />
                                                    <span>Drag & Drop .PDF Files here or click to upload</span>
                                                </label>
                                                <div className="col-md-6 px-0">
                                                    <div className="files-list">
                                                        <div className="single-file">
                                                            <div className="icon-text">
                                                                <img src={ClipIcon} alt="" />
                                                                <span>Attachment 1 .pdf</span>
                                                            </div>
                                                            <span className='removefile'>Remove</span>
                                                        </div>
                                                        <div className="single-file">
                                                            <div className="icon-text">
                                                                <img src={ClipIcon} alt="" />
                                                                <span>Attachment 1 .pdf</span>
                                                            </div>
                                                            <span className='removefile'>Remove</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-lg-3 mt-2">
                                        <div className="form-group mb-3">
                                            <label htmlFor="">Employment Status *</label>
                                            <div className="custom-check-box-default">
                                                <label className="label-checkbox">Employed
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Self Employed
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Unemployed
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Ocupation">Occupation *</label>
                                            <input type="text" name='Ocupation' id='Ocupation' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <div className="form-group mb-3">
                                            <label htmlFor="">Was Your Spouse Related To You *</label>
                                            <div className="custom-check-box-default">
                                                <label className="label-checkbox">Yes
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">No
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <div className="form-group mb-3">
                                            <label htmlFor="StateRelationship">If Yes Please State Relationship *</label>
                                            <input type="text" name='StateRelationship' id='StateRelationship' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <div className="form-group d-flex gap-3">
                                            <Link to="" className='btn ms-auto px-5'>Back</Link>
                                            <button type='submit' className='btn px-5'>Next</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section >
            <ContactSec />
            <Footer />
        </>
    )
}
export default AppilcantDetailsWifeDivorce;