
import React from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { Link } from 'react-router-dom'
import ContactSec from '../../components/ContactSec';
import FormHeadings from '../../components/FormHeadings';
const FamilyCounsellingForm = () => {
    return (
        <>
            <Header />
            <section className="custom-forms">
                <div className="container">
                    <FormHeadings SmallHeading="Manchester Islamic Centre" MainHeading="Family Counselling Form" Text="Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas " />
                    <div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
                        <div className="custom-form-start">
                            <form  >
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="FullName">Full Name *</label>
                                            <input type="text" name='FullName' id='FullName' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Surname">Surname *</label>
                                            <input type="text" name='Surname' id='Surname' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Age">Age *</label>
                                            <input type="text" name='Age' id='Age' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="PlaceOfBirth">Place Of Birth *</label>
                                            <input type="text" name='PlaceOfBirth' id='PlaceOfBirth' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Nationality">Nationality *</label>
                                            <select name="Nationality" id="Nationality" className='form-select form-control'>
                                                <option value="Your answer">Your answer</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Religion">Religion *</label>
                                            <select name="Religion" id="Religion" className='form-select form-control'>
                                                <option value="Your answer">Your answer</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Address1">Address 1 *</label>
                                            <input type="text" name='Address1' id='Address1' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Address2">Address 2 *</label>
                                            <input type="text" name='Address2' id='Address2' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="City">City *</label>
                                            <select name="City" id="City" className='form-select form-control'>
                                                <option value="Your answer">Your answer</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Postcode">Postcode *</label>
                                            <input type="text" name='Postcode' id='Postcode' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="TelephoneNumber">Telephone Number *</label>
                                            <input type="text" name='TelephoneNumber' id='TelephoneNumber' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Email">Email *</label>
                                            <input type="text" name='Email' id='Email' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="MethodOfContact">Preferred  Method Of Contact *</label>
                                            <select type="text" name='MethodOfContact' id='MethodOfContact' className="form-select   form-control" >
                                                <option value="Your answer">Your answer</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="">Preferred  Time Of Contact *</label>
                                            <div className="custom-check-box-default">
                                                <label className="label-checkbox">Morning
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Afternoon
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Evening
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Anytime
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="">Reason For Referral *</label>
                                            <div className="custom-check-box-default">
                                                <label className="label-checkbox">Family Support
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Personal Support
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Parental Support
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Child Support
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Spousal Support
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Other
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="GiveBriefOutline">Please Give A Brief Outline Here *</label>
                                            <input type="text" name='GiveBriefOutline' id='GiveBriefOutline' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6 me-md-4">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Preferred Language">Preferred  Language To Speak To Us In *</label>
                                            <select type="text" name='Preferred Language' id='Preferred Language' className="form-select   form-control" >
                                                <option value="Your answer">Your answer</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="AuthoritiesInvolved">Are There Any Authorities Involved? *</label>
                                            <select type="text" name='AuthoritiesInvolved' id='AuthoritiesInvolved' className="form-select   form-control" >
                                                <option value="Your answer">Your answer</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="OtherPleaseSpecify">If Other Please Specify</label>
                                            <input name='OtherPleaseSpecify' id='OtherPleaseSpecify' className="form-control lg" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="additionalinformation">Any additional information that is relevant to your application</label>
                                            <input name='additionalinformation' id='additionalinformation' className="form-control lg" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="">How did you hear about our services? *</label>
                                            <div className="custom-check-box-default">
                                                <label className="label-checkbox">Word of Mouth
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Social Media
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Family/ Friend
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Flyer/Poster
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Wesbite
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">App (Watsapp)
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <div className="form-group">
                                            <Link to="/checkout" className='btn ms-auto px-5'>Submit</Link>
                                            {/* <button type="submit" className='btn ms-auto px-5'>Submit</button> */}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <ContactSec />
            <Footer />
        </>
    )
}
export default FamilyCounsellingForm;


