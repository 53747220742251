import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactSec from "../../components/ContactSec";
import FormHeadings from "../../components/FormHeadings";
import { Link } from "react-router-dom";
import { ClipIcon } from "../../constant";
import { useNavigate } from "react-router-dom";
const EvidenceUploadWifeDivorce = () => {
	const navigate = useNavigate();
	const handleSubmit = (event) => {
		event.preventDefault();
		// perform form submission logic here
		navigate("/declaration-civil-marriage"); // navigate to the success page
	};
	return (
		<>
			<Header />
			<section className="custom-forms">
				<div className="container">
					<FormHeadings
						SmallHeading="Manchester Islamic Centre"
						MainHeading="Wife Seeking Divorce From Her Husband"
						containerSize="col-xl-9 col-lg-9 col-md-10 col-sm-12 mx-auto px-xl-4"
						Text="Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas "
					/>

					<div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
						<div className="custom-form-start">
							<h6 className="form-inner-heading mt-1 mb-3">Evidence Upload</h6>
							<p>
								This section allows you to upload any documentation which can be
								used as evidence to support your application{" "}
							</p>
							<form onSubmit={handleSubmit}>
								<div className="row">
									<div className="col-md-12">
										<div className="form-group mb-3 pt-3">
											<label htmlFor="">Evidence One</label>
											<div className="file-uploader-custom">
												<label htmlFor="UploadFile" className="fileuploader">
													<input
														type="file"
														name="UploadFile"
														hidden
														id="UploadFile"
													/>
													<span>
														Drag & Drop .PDF Files here or click to upload
													</span>
												</label>
												<div className="col-md-6 px-0">
													<div className="files-list">
														<div className="single-file">
															<div className="icon-text">
																<img src={ClipIcon} alt="" />
																<span>Attachment 1 .pdf</span>
															</div>
															<span className="removefile">Remove</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3 pt-3">
											<label htmlFor="">Evidence Two</label>
											<div className="file-uploader-custom">
												<label htmlFor="EvidenceTwo" className="fileuploader">
													<input
														type="file"
														name="EvidenceTwo"
														hidden
														id="EvidenceTwo"
													/>
													<span>
														Drag & Drop .PDF Files here or click to upload
													</span>
												</label>
												<div className="col-md-6 px-0">
													<div className="files-list">
														<div className="single-file">
															<div className="icon-text">
																<img src={ClipIcon} alt="" />
																<span>Attachment 1 .pdf</span>
															</div>
															<span className="removefile">Remove</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3 pt-3">
											<label htmlFor="">Evidence Three</label>
											<div className="file-uploader-custom">
												<label htmlFor="EvidenceThree" className="fileuploader">
													<input
														type="file"
														name="EvidenceThree"
														hidden
														id="EvidenceThree"
													/>
													<span>
														Drag & Drop .PDF Files here or click to upload
													</span>
												</label>
												<div className="col-md-6 px-0">
													<div className="files-list">
														<div className="single-file">
															<div className="icon-text">
																<img src={ClipIcon} alt="" />
																<span>Attachment 1 .pdf</span>
															</div>
															<span className="removefile">Remove</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									{/* <div className="col-md-12">
                                        <div className="form-group mb-3 pt-3">
                                            <label htmlFor="">Evidence One</label>
                                            <div className="file-uploader-custom">
                                                <label htmlFor="UploadFile" className='fileuploader'>
                                                    <input type="file" name="UploadFile" hidden id="UploadFile" />
                                                    <span>Drag & Drop .PDF Files here or click to upload</span>
                                                </label>
                                                <div className="col-md-6 px-0">
                                                    <div className="files-list">
                                                        <div className="single-file">
                                                            <div className="icon-text">
                                                                <img src={ClipIcon} alt="" />
                                                                <span>Attachment 1 .pdf</span>
                                                            </div>
                                                            <span className='removefile'>Remove</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
									<div className="col-md-12 mt-2">
										<div className="form-group d-flex gap-3">
											<Link
												to="/children-details-civil-marriage"
												className="btn ms-auto px-5"
											>
												Back
											</Link>
											<button type="submit" className="btn px-5">
												Next
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			<ContactSec />
			<Footer />
		</>
	);
};
export default EvidenceUploadWifeDivorce;
