import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
	aboutmisimg,
	historyimg,
	micvideo,
	shariahImg,
	trusti1,
	trusti2,
	trusti3,
	trusti4,
	video1,
} from "../../constant";
import "../../assets/css/about.css";
import ContactSec from "../../components/ContactSec";
import "../../assets/css/shariah-family-council.css";
import FaqSection from "../../components/FaqSection";

const ShariahFamilyCouncil = () => {
	const managment = [
		{
			id: 1,
			img: trusti1,
			name: "Mr Fawzi Al Haffar",
			desgination: "Chairman",
		},
		{
			id: 2,
			img: trusti2,
			name: "Mr Fawzi Al Haffar",
			desgination: "Chairman",
		},
		{
			id: 3,
			img: trusti3,
			name: "Mr Fawzi Al Haffar",
			desgination: "Chairman",
		},
		{
			id: 4,
			img: trusti4,
			name: "Mr Fawzi Al Haffar",
			desgination: "Chairman",
		},
	];
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}
			{/* About starts here */}
			<section className="about-mission">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="about-mission-content-wrapper mb-4">
								<h6 className="small-heading">Manchester Islamic Centre</h6>
								<h2>Shariah Family Council</h2>
								<p>
									Habitant morbi tristique senectus et. Nullam vehicula ipsum a
									arcu cursus. Aliquam purus sit amet luctus venenatis lectus
									magna fringilla urna. Malesuada pellentesque elit eget gravida
									cum sociis natoque. Faucibus ornare suspendisse sed nisi
									lacus. Consectetur adipiscing elit duis tristique sollicitudin
									nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
									egestas sed tempus urna et pharetra pharetra massa. Enim ut
									tellus elementum sagittis vitae et leo duis ut. ntesque elit
									eget gravida cum sociis natoque. Faucibus ornare suspendisse
									sed nisi lacus. Consectetur adipiscing
								</p>
								<p>
									Habitant morbi tristique senectus et. Nullam vehicula ipsum a
									arcu cursus. Aliquam purus sit amet luctus venenatis lectus
									magna fringilla urna. Malesuada pellentesque elit eget gravida
									cum sociis natoque. Faucibus ornare suspendisse sed nisi
									lacus. Consectetur adipiscing elit duis tristique sollicitudin
									nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
									egestas sed tempus urna
								</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-img-wrapper">
								<figure>
									<img src={shariahImg} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* About End here */}
			{/* Mic video starts here */}
			<section className="mic-video-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="mic-content-wrapper">
								<h6 className="small-heading">Manchester Islamic Centre</h6>
								<h2>Mic & Didsbury Mosque Video Intro</h2>
								<p>
									Diam ut venenatis tellus in metus vulputate eu scelerisque.
									Commodo nulla facilisi nullam vehicula ipsum a arcu cursus
									vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae
									congue mauris rhoncus aenean vel. Et egestas
								</p>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
					<div className="row mt-5">
						<div className="col-lg-12">
							<div className="mic-img-wrapper">
								<figure>
									{/* <img src={micvideo} className="img-fluid" alt="" /> */}
									<video src={video1} autoPlay muted className="img-fluid w-100"></video>
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Mic video End here */}
			{/* Our managment starts here */}
			<section className="our-managment">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="managment-content-wrapper">
								<h6 className="small-heading">Manchester Islamic Centre</h6>
								<h2>Our Management</h2>
								<p>
									Diam ut venenatis tellus in metus vulputate eu scelerisque.
									Commodo nulla facilisi nullam vehicula ipsum a arcu cursus
									vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae
									congue mauris rhoncus aenean vel. Et egestas
								</p>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
					<div className="managment-row-wrapper">
						{managment.map((item, index) => {
							return (
								<>
									<div key={index} className="managemnet-card-wrapper">
										<div className="managment-img-wrapper">
											<figure>
												<img src={item?.img} className="img-fluid" alt="" />
											</figure>
										</div>
										<div className="managment-content-wrapper">
											<h4>{item?.name}</h4>
											<h5>{item?.desgination}</h5>
										</div>
									</div>
								</>
							);
						})}
					</div>
					<div className="load-more mt-5">
						<button className="btn mx-auto">Load More</button>
					</div>
				</div>
			</section>
			{/* Our managment End here */}
			{/* Faq starts here */}
			<FaqSection />
			{/* Faq End here */}
			<ContactSec />
			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default ShariahFamilyCouncil;
