import React from "react";
import { IconContact } from "../constant/index";
const ContactSec = () => {
	return (
		<>
			{/* Contact Us Start Here */}
			<section className="contact-us-home">
				<div className="container">
					<div className="col-xxl-11 mx-auto">
						<div className="contact-home-start">
							<div className="row align-items-center">
								<div className="col-md-5">
									<div className="top-heading text-start">
										<h6 className="small-heading mb-0">
											Manchester Islamic Centre
										</h6>
										<h2 className="mb-0">
											Contact Us{" "}
											<img src={IconContact} className="ps-2" alt="" />
										</h2>
										<p className="mb-0">
											Sed cras ornare arcu dui vivamus arcu felis bibendum ut.
											Tempus egestas sed sed risus pretium quam vulputate
											dignissim. Quam pellentesque nec nam aliquam
										</p>
									</div>
								</div>
								<div className="col-md-7">
									<div className="contact-form">
										<form  >
											<div className="row">
												<div className="col mb-3-md-6">
													<div className="form-group">
														<label htmlFor="Name">Name</label>
														<input
															type="text"
															name="Name"
															className="form-control"
															placeholder="Your answer"
															id="Name"
														/>
													</div>
												</div>
												<div className="col-md-6 mb-3">
													<div className="form-group">
														<label htmlFor="Email">Email Address</label>
														<input
															type="email"
															name="Email"
															className="form-control"
															placeholder="Your answer"
															id="Email"
														/>
													</div>
												</div>
												<div className="col-md-12 mb-3">
													<div className="form-group">
														<label htmlFor="Subject">Subject</label>
														<input
															type="text"
															name="Subject"
															className="form-control"
															placeholder="Your answer"
															id="Subject"
														/>
													</div>
												</div>
												<div className="col-md-12 mb-3">
													<div className="form-group">
														<label htmlFor="Message">Message</label>
														<textarea
															name="Message"
															className="form-control"
															placeholder="Your answer"
															id="Message"
														></textarea>
													</div>
												</div>
												<div className="col-md-12 d-flex justify-content-end">
													<button type="submit" className="btn">
														Submit
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Contact Us End Here */}
		</>
	);
};

export default ContactSec;
