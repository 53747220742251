import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const Privacy = () => {
	return (
		<>
			<Header />
			{/* terms starts here */}
			<section className="terms-sec py-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="terms-content-wrapper">
								<h2>Privacy Policy </h2>
								<div className="terms-para-wrapper mt-4">
									<p>
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Ipsa tempore quisquam consequuntur autem. Dolore facere eius
										voluptas, commodi enim sunt eos odit debitis harum ad omnis
										aliquam recusandae earum hic. Esse repellendus, amet at
										laudantium consequuntur velit dolore neque, explicabo
										voluptas quae sapiente deleniti maxime dignissimos debitis
										enim quam! Eos?
									</p>
									<p>
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Ipsa tempore quisquam consequuntur autem. Dolore facere eius
										voluptas, commodi enim sunt eos odit debitis harum ad omnis
										aliquam recusandae earum hic. Esse repellendus, amet at
										laudantium consequuntur velit dolore neque, explicabo
										voluptas quae sapiente deleniti maxime dignissimos debitis
										enim quam! Eos?
									</p>
									<p>
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Ipsa tempore quisquam consequuntur autem. Dolore facere eius
										voluptas, commodi enim sunt eos odit debitis harum ad omnis
										aliquam recusandae earum hic. Esse repellendus, amet at
										laudantium consequuntur velit dolore neque, explicabo
										voluptas quae sapiente deleniti maxime dignissimos debitis
										enim quam! Eos?
									</p>
									<p>
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Ipsa tempore quisquam consequuntur autem. Dolore facere eius
										voluptas, commodi enim sunt eos odit debitis harum ad omnis
										aliquam recusandae earum hic. Esse repellendus, amet at
										laudantium consequuntur velit dolore neque, explicabo
										voluptas quae sapiente deleniti maxime dignissimos debitis
										enim quam! Eos?
									</p>
									<p>
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Ipsa tempore quisquam consequuntur autem. Dolore facere eius
										voluptas, commodi enim sunt eos odit debitis harum ad omnis
										aliquam recusandae earum hic. Esse repellendus, amet at
										laudantium consequuntur velit dolore neque, explicabo
										voluptas quae sapiente deleniti maxime dignissimos debitis
										enim quam! Eos?
									</p>
									<p>
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Ipsa tempore quisquam consequuntur autem. Dolore facere eius
										voluptas, commodi enim sunt eos odit debitis harum ad omnis
										aliquam recusandae earum hic. Esse repellendus, amet at
										laudantium consequuntur velit dolore neque, explicabo
										voluptas quae sapiente deleniti maxime dignissimos debitis
										enim quam! Eos?
									</p>
									<p>
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Ipsa tempore quisquam consequuntur autem. Dolore facere eius
										voluptas, commodi enim sunt eos odit debitis harum ad omnis
										aliquam recusandae earum hic. Esse repellendus, amet at
										laudantium consequuntur velit dolore neque, explicabo
										voluptas quae sapiente deleniti maxime dignissimos debitis
										enim quam! Eos?
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* terms ends here */}
			<Footer />
		</>
	);
};

export default Privacy;
