import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "../../assets/css/contact.css";
import { IconContact } from "../../constant";
const ContactUs = () => {
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}
			{/* Contact us starts here */}
			<section className="contact-us">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<div className="contact-card-wrapper">
								<div className="contact-content-wrapper">
									<h6>Manchester Islamic Centre</h6>
									<h2>
										Contact Us <img src={IconContact} alt="" />
									</h2>
									<p>
										Donec ac odio tempor orci dapibus ultrices in iaculis nunc.
										Metus vulputate eu scelerisque felis imperdiet proin.
										Consequat ac felis donec et. Tincidunt nunc
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-8">
							<div className="row mt-3">
								<div className="col-lg-8">
									<div className="contact-heading-wrapper">
										<h6 className="small-heading">Manchester Islamic Centre</h6>
										<h2>Write Us A Message</h2>
										<p>
											Donec ac odio tempor orci dapibus ultrices in iaculis
											nunc. Metus vulputate eu scelerisque felis imperdiet
											proin. Consequat ac felis donec et. Tincidunt nunc
										</p>
									</div>
								</div>
							</div>
							<div className="contact-form-wrapper">
								<form action="">
									<div className="form-group row">
										<div className="col-lg-6">
											<label htmlFor="">First Name</label>
											<input
												type="text"
												placeholder="Your answer"
												className="form-control"
											/>
										</div>
										<div className="col-lg-6">
											<label htmlFor="">Last Name</label>
											<input
												type="text"
												placeholder="Your answer"
												className="form-control"
											/>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-lg-6">
											<label htmlFor="">Email Address</label>
											<input
												type="text"
												placeholder="Your answer"
												className="form-control"
											/>
										</div>
										<div className="col-lg-6">
											<label htmlFor="">Contact Number</label>
											<input
												type="text"
												placeholder="Your answer"
												className="form-control"
											/>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-lg-12">
											<label htmlFor="">Your Message</label>
											<textarea
												name=""
												className="form-control"
												placeholder="Your message"
												id=""
												cols="30"
												rows="10"
											></textarea>
										</div>
									</div>
									<div className="form-group row mt-4">
										<div className="col-lg-6"></div>
										<div className="col-lg-6">
											<div className="btn-wrapper ">
												<button className="btn ms-auto">Submit</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Contact us ends here */}
			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default ContactUs;
