import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Banner from "./Banner";
// Css Imported Here
import "../../assets/css/forms-pages.css";
import {
  GoogleIcon,
  AppStoreIcon,
  PayCard1,
  PayCard2,
  PayCard3,
  PayCard4,
  NewsPaperIcon,
} from "../../constant";
import {
  GalleryImg1,
  GalleryImg2,
  GalleryImg3,
  GalleryImg4,
  GalleryImg5,
  GalleryImg6,
  GalleryImg7,
  GalleryImg8,
  GalleryImg9,
  IconContact,
} from "../../constant";
import ContactSec from "../../components/ContactSec";
const Home = () => {
  const GalleryImages = [
    {
      id: 1,
      image: GalleryImg1,
    },
    {
      id: 2,
      image: GalleryImg2,
    },
    {
      id: 3,
      image: GalleryImg3,
    },
    {
      id: 4,
      image: GalleryImg4,
    },

    {
      id: 5,
      image: GalleryImg5,
    },
    {
      id: 6,
      image: GalleryImg6,
    },
    {
      id: 7,
      image: GalleryImg7,
    },
    {
      id: 8,
      image: GalleryImg8,
    },
    {
      id: 9,
      image: GalleryImg9,
    },
    {
      id: 7,
      image: GalleryImg7,
    },
    {
      id: 8,
      image: GalleryImg8,
    },
    {
      id: 9,
      image: GalleryImg9,
    },
  ];
  let NewsHeading = "How to Become a muslim";
  let NewsBody =
    "Sed cras ornare arcu dui vivamus arcu felis bibendum ut. Tempus egestas sed sed risus pretium quam vulputate dignissim. Quam pellentesque nec nam aliquam. Tristique senectus et netus et malesuada. Sit amet nisl purus in mollis nunc sed id semper.";
  let NewsDate = "18 MAY 2022";
  const NewsData = [
    {
      NewsHeading: NewsHeading,
      NewsBody: NewsBody,
      NewsDate: NewsDate,
    },
    {
      NewsHeading: NewsHeading,
      NewsBody: NewsBody,
      NewsDate: NewsDate,
    },
    {
      NewsHeading: NewsHeading,
      NewsBody: NewsBody,
      NewsDate: NewsDate,
    },
    {
      NewsHeading: NewsHeading,
      NewsBody: NewsBody,
      NewsDate: NewsDate,
    },
  ];
  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}

      {/* Banner Start Here */}
      <Banner />
      {/* Banner End Here */}
      {/* Link Boxes Start Here */}
      <section className="social-boxe">
        <div className="container">
          <div className="social-boxes-start">
            <div className="row">
              <div className="col-lg-3 px-2">
                <div className="single-box dark mobile-app-back">
                  <div className="left-text">
                    <h5 className="line-clamp-2">Download App</h5>
                    <p className="line-clamp-2">
                      Sed cras ornare arcu dui vivamus arcu felis bibendum
                    </p>
                    <div className="button-group store-btns">
                      <button>
                        <img src={GoogleIcon} alt="" />
                      </button>
                      <button>
                        <img src={AppStoreIcon} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 px-2">
                <div className="single-box light youtube-back">
                  <div className="left-text">
                    <h5 className="line-clamp-3">
                      Youtube Live Or Recorded Stream
                    </h5>
                    <div className="button-group youtube-btns">
                      <button>
                        <div className="text">
                          <h6 className="pop-bold">Watch Now</h6>
                          <p className="small-text">ras ornare arcu </p>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 px-2">
                <div className="single-box light zakat-back">
                  <div className="left-text">
                    <h5 className="line-clamp-2">Zakat Calculation</h5>
                    <p className="line-clamp-2">
                      Sed cras ornare arcu dui vivamus arcu felis bibendum
                    </p>
                    <div className="button-group zakat-btns">
                      <Link to="/zakat">Learn More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 px-2">
                <div className="single-box dark volunter-back">
                  <div className="left-text">
                    <h5 className="line-clamp-2">Become A Volunteer</h5>
                    <p className="line-clamp-2">
                      Sed cras ornare arcu dui vivamus arcu felis
                    </p>
                    <div className="button-group volunter-btns">
                      <Link to="/volunteer-form">Learn More</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Link Boxes End Here */}
      {/* Instant Donation Start Here */}
      <section className="instant-donation">
        <div className="container">
          <div className="instant-donation-box">
            <div className="row">
              <div className="col-md-8">
                <div className="heading-cards">
                  <div className="text">
                    <h6 className="small-heading">13.1.11</h6>
                    <h2>Instant Donation</h2>
                    <p>www.mohid.net 844-MY-MOHID</p>
                  </div>
                  <div className="atm-cards">
                    <figure>
                      <img src={PayCard1} alt="" />
                    </figure>
                    <figure>
                      <img src={PayCard2} alt="" />
                    </figure>
                    <figure>
                      <img src={PayCard3} alt="" />
                    </figure>
                    <figure>
                      <img src={PayCard4} alt="" />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="donation-amounts">
                  <div className="single-payment">
                    <span>£3</span>
                  </div>
                  <div className="single-payment">
                    <span>£3</span>
                  </div>
                  <div className="single-payment">
                    <span>£3</span>
                  </div>
                  <div className="single-payment">
                    <span>£3</span>
                  </div>
                  <div className="single-payment">
                    <span>£3</span>
                  </div>
                  <div className="single-payment">
                    <span>£3</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Instant Donation End Here */}
      {/* Gallery Start Here */}
      <section className="photo-gallery">
        <div className="container">
          <div className="col-xxl-11 mx-auto">
            <div className="gallery-start">
              <div className="col-lg-6 mx-auto px-xl-4">
                <div className="top-heading text-center">
                  <h6 className="small-heading mb-0">
                    Manchester Islamic Centre
                  </h6>
                  <h2 className="mb-0">Photo Gallery</h2>
                  <p className="mb-0">
                    Sed cras ornare arcu dui vivamus arcu felis bibendum ut.
                    Tempus egestas sed sed risus pretium quam vulputate
                    dignissim. Quam pellentesque nec nam aliquam
                  </p>
                </div>
              </div>
              <div className="galler-images gallery-scrollabe">
                <div className="row">
                  {GalleryImages.map((data, index) => (
                    <div className="col-md-4 p-2" key={index}>
                      <figure>
                        <img src={data.image} className="img-fluid" alt="" />
                      </figure>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Gallery End Here */}
      {/* News Releases Start Here */}
      <section className="news-relese">
        <div className="container">
          <div className="col-xxl-11 mx-auto">
            <div className="news-relese-start">
              <div className="col-lg-6 mx-auto px-xl-4">
                <div className="top-heading text-center">
                  <h6 className="small-heading mb-0">
                    Manchester Islamic Centre
                  </h6>
                  <h2 className="mb-0">
                    News Releases <img src={NewsPaperIcon} alt="" />
                  </h2>
                  <p className="mb-0">
                    Sed cras ornare arcu dui vivamus arcu felis bibendum ut.
                    Tempus egestas sed sed risus pretium quam vulputate
                    dignissim. Quam pellentesque nec nam aliquam
                  </p>
                </div>
              </div>
              <div className="news-list">
                <div className="row">
                  {NewsData.map((data, index) => (
                    <div className="col-md-6 px-xl-4 mt-4 pt-2">
                      <div className="single-news">
                        <h3 className="line-clamp-1">How to Become a muslim</h3>
                        <span className="date">18 MAY 2022</span>
                        <p className="pe-lg-4 line-clamp-4">
                          Sed cras ornare arcu dui vivamus arcu felis bibendum
                          ut. Tempus egestas sed sed risus pretium quam
                          vulputate dignissim. Quam pellentesque nec nam
                          aliquam. Tristique senectus et netus et malesuada. Sit
                          amet nisl purus in mollis nunc sed id semper.
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="d-flex mt-5">
                  <Link to="/news" className="btn mx-auto">
                    Load More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* News Releases End Here */}
      <ContactSec />
      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

export default Home;
