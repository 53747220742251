import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
  SermonImg1,
  SermonImg2,
  SermonImg3,
  PrayerImg2,
  PrayerImg1,
  PrayerImg3,
  PlayIcon,
  documentimg,
} from "../../constant";
import "../../assets/css/about.css";
import ContactSec from "../../components/ContactSec";

const prayersTimings = [
  {
    id: 1,
    salah: "Fajr",
    iqamah: "6:00 AM",
    azaan: "5:00 AM",
    name: "١لفجر",
  },
  {
    id: 2,
    salah: "Duhar",
    iqamah: "1:30 AM",
    azaan: "1:30 PM",
    name: "١ظهر",
  },
  {
    id: 3,
    salah: "Asr",
    iqamah: "5:30 AM",
    azaan: "5:30 PM",
    name: "١عصر",
  },
  {
    id: 4,
    salah: "Maghrib",
    iqamah: "8:22 AM",
    azaan: "8:22 PM",
    name: "١لمغرب",
  },
  {
    id: 5,
    salah: "Isha",
    iqamah: "9:37 AM",
    azaan: "9:37 PM",
    name: "العشاء",
  },
];

const Prayers = () => {
  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}
      {/* About starts here */}
      <section className="about-mission pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-mission-content-wrapper mb-4">
                <h6 className="small-heading">Manchester Islamic Centre</h6>
                <h2>Prayers </h2>
                <p>
                  Habitant morbi tristique senectus et. Nullam vehicula ipsum a
                  arcu cursus. Aliquam purus sit amet luctus venenatis lectus
                  magna fringilla urna. Malesuada pellentesque elit eget gravida
                  cum sociis natoque. Faucibus ornare suspendisse sed nisi
                  lacus. Consectetur adipiscing elit duis tristique sollicitudin
                  nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
                  egestas sed tempus urna et pharetra pharetra massa. Enim ut
                  tellus elementum sagittis vitae et leo duis ut. ntesque elit
                  eget gravida cum sociis natoque. Faucibus ornare suspendisse
                  sed nisi lacus. Consectetur adipiscing
                </p>
                <p>
                  t duis tristique sollicitudin nibh sit amet. Non curabitur
                  gravida arcu ac tortor. Ac turpis egestas sed tempus urna et
                  pharetra pharetra massa. Enim ut tellus elem
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-img-wrapper">
                <figure>
                  <img src={PrayerImg1} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About End here */}
      {/* Our polices starts here */}
      <section className="our-policies">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="policies-content-wrapper">
                <h6>Manchester Islamic Centre</h6>
                <h2>Prayer Time</h2>
                <p>
                  Habitant morbi tristique senectus et. Nullam vehicula ipsum a
                  arcu cursus. Aliquam purus
                </p>
              </div>
            </div>
            <div className="col-lg-7 ms-auto">
              <div className="prayer-main-about">
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      {prayersTimings.map((prayers) => (
                        <tr key={prayers.id}>
                          <td>{prayers.salah}</td>
                          <td>{prayers.iqamah}</td>
                          <td>{prayers.azaan}</td>
                          <td>{prayers.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="progress mx-auto" data-value="80">
                  <figure>
                    <img src={PrayerImg3} className="img-fluid" alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Our polices End here */}
      {/* History  starts here */}
      <section className="History-mission">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-img-wrapper">
                <figure>
                  <img src={PrayerImg2} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-mission-content-wrapper mb-4">
                <h6 className="small-heading">Manchester Islamic Centre</h6>
                <h2>Download Monthly Timetable</h2>
                <p>
                  Habitant morbi tristique senectus et. Nullam vehicula ipsum a
                  arcu cursus. Aliquam purus sit amet luctus venenatis lectus
                  magna fringilla urna. Malesuada pellentesque elit eget gravida
                  cum sociis natoque. Faucibus ornare suspendisse sed nisi
                  lacus. Consectetur adipiscing elit
                </p>
                <Link to="" className="btn btn-second mt-4">
                  <span className="icon">
                    <img src={documentimg} alt="" />
                  </span>
                  <span>Download File</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* History  End here */}
      <section className="friday-sermons pb-5 mb-3">
        <div className="container">
          <div className="sermon-videos-start">
            <div className="top-headings mb-5 pb-4">
              <div className="col-lg-7 col-md-8 mx-auto text-center">
                <div className="mic-content-wrapper">
                  <h6 className="small-heading">Manchester Islamic Centre</h6>
                  <h2>Videos for Friday Sermons </h2>
                  <p>
                    Diam ut venenatis tellus in metus vulputate eu scelerisque.
                    Commodo nulla facilisi nullam vehicula ipsum a arcu cursus
                    vitae. Sed risus ultricies tristique nulla. Arcu cursus
                    vitae congue mauris rhoncus aenean vel. Et egestas
                  </p>
                </div>
              </div>
            </div>
            <div className="videos-here video-scroll">
              <div className="row">
                <div className="col-md-8 pe-md-0">
                  <div className="large-video">
                    <figure className="main-video">
                      <img src={SermonImg1} className="img-fluid" alt="" />
                      <img src={PlayIcon} className="play-icon" alt="" />
					  
                      <div className="prayer-time ps-3 pe-lg-4">
                        <div className="row">
                          <div className="col-xxl-5 col-xl-4">
                            <h5 className="mb-0">Prayer Time</h5>
                          </div>
                          <div className="col-xxl-7 col-xl-7 ms-auto">
                            <p className="mb-0">
                              Donec ac odio tempor orci dapibus ultrices in
                              iaculis nunc. Metus vulputate eu scelerisque felis
                              imperdiet proin.
                            </p>
                          </div>
                        </div>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="small-videos">
                    <figure className="main-video mb-3">
                      <img src={SermonImg2} alt="" className="img-fluid" />
                      <img src={PlayIcon} className="play-icon" alt="" />
                    </figure>
                    <figure className="main-video mb-3">
                      <img src={SermonImg3} alt="" className="img-fluid" />
                      <img src={PlayIcon} className="play-icon" alt="" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactSec />
      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

export default Prayers;
