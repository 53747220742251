
import React from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import ContactSec from '../../components/ContactSec';
import FormHeadings from '../../components/FormHeadings';
const VolunteerForm = () => {
    return (
        <>
            <Header />
            <section className="custom-forms">
                <div className="container">
                    <FormHeadings SmallHeading="Manchester Islamic Centre" MainHeading="Become a Volunteer" Text="Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas " />
                    <div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
                        <div className="custom-form-start">
                            <form  >
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="FullName">Full Name *</label>
                                            <input type="text" name='FullName' id='FullName' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="LastName">Last Name *</label>
                                            <input type="text" name='LastName' id='LastName' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Address">Address *</label>
                                            <input type="text" name='Address' id='Address' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="City">City</label>
                                            <select name='City' id='City' className='form-select form-control'>
                                                <option value="Your answer">Your answer</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="State">State</label>
                                            <select name='State' id='State' className='form-select form-control'>
                                                <option value="Your answer">Your answer</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Zipcode">Zipcode</label>
                                            <input type="text" name='Zipcode' id='Zipcode' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Country">Country</label>
                                            <select name='Country' id='Country' className='form-select form-control'>
                                                <option value="Your answer">Your answer</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3 multi-select">
                                            <label htmlFor="MultiSelect">Which days of the week do you want to work?</label>
                                            <select name='MultiSelect' id='MultiSelect' className="form-select form-control" >
                                                <option value="Multi Select">Multi Select</option>
                                            </select>
                                            <div className="tags-list">
                                                <span className="single-tag">
                                                    Monday
                                                    <span>x</span>
                                                </span>
                                                <span className="single-tag">
                                                Tuesday
                                                    <span>x</span>
                                                </span>
                                                <span className="single-tag">
                                                Wednesday
                                                    <span>x</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Enterworkinghoursperweek">Enter working hours per week</label>
                                            <input type="text" name='Enterworkinghoursperweek' id='Enterworkinghoursperweek' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="VolunteeringField">Volunteering Field</label>
                                            <select type="text" name='VolunteeringField' id='VolunteeringField' className="form-select form-control" >
                                                <option value="Your answer">Your answer</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="yourcomments">Enter your comments</label>
                                            <input type="text" name='yourcomments' id='yourcomments' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <div className="form-group">
                                            <button type="submit" className='btn ms-auto px-5'>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <ContactSec />
            <Footer />
        </>
    )
}
export default VolunteerForm;


