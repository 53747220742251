import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import FormHeadings from "../../components/FormHeadings";
import {
  documentimg,
  ComImg1,
  ComImg2,
  ComImg3,
  dawahImg1,
  dawahImg2,
  dawahImg3,
  dawahImg4,
  HelpImg1,
  HelpImg2,
  HelpImg3,
  HelpImg4,
} from "../../constant";
import { Link } from "react-router-dom";
import "../../assets/css/about.css";
import ContactSec from "../../components/ContactSec";
import "../../assets/css/shariah-family-council.css";

const CommunityEngagement = () => {
  let Activityname = "Activity # 1";
  let ActivityText =
    "Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi ";

  const Members = [
    {
      name: Activityname,
      text: ActivityText,
      Img: dawahImg1,
    },
    {
      name: Activityname,
      text: ActivityText,
      Img: dawahImg2,
    },
    {
      name: Activityname,
      text: ActivityText,
      Img: dawahImg3,
    },
    {
      name: Activityname,
      text: ActivityText,
      Img: dawahImg4,
    },
  ];
  const HelpAcivities = [
    {
      name: Activityname,
      text: ActivityText,
      Img: HelpImg1,
    },
    {
      name: Activityname,
      text: ActivityText,
      Img: HelpImg2,
    },
    {
      name: Activityname,
      text: ActivityText,
      Img: HelpImg3,
    },
    {
      name: Activityname,
      text: ActivityText,
      Img: HelpImg4,
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  });
  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}
      {/* Family starts here */}
      <section className="about-mission">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-mission-content-wrapper mb-4">
                <h6 className="small-heading">Manchester Islamic Centre</h6>
                <h2>Community Engagement Activities</h2>
                <p>
                  Habitant morbi tristique senectus et. Nullam vehicula ipsum a
                  arcu cursus. Aliquam purus sit amet luctus venenatis lectus
                  magna fringilla urna. Malesuada pellentesque elit eget gravida
                  cum sociis natoque. Faucibus ornare suspendisse sed nisi
                  lacus. Consectetur adipiscing elit duis tristique sollicitudin
                  nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
                  egestas sed tempus urna et pharetra pharetra massa. Enim ut
                  tellus elementum sagittis vitae et leo duis ut. ntesque elit
                  eget gravida cum sociis natoque. Faucibus ornare suspendisse
                  sed nisi lacus. Consectetur adipiscing
                </p>
                <p>
                  Habitant morbi tristique senectus et. Nullam vehicula ipsum a
                  arcu cursus. Aliquam purus sit amet luctus venenatis lectus
                  magna fringilla urna. Malesuada pellentesque elit eget gravida
                  cum sociis natoque. Faucibus ornare suspendisse sed nisi
                  lacus. Consectetur adipiscing elit duis tristique sollicitudin
                  nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
                  egestas sed tempus urna
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-img-wrapper">
                <figure>
                  <img src={ComImg1} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Family End here */}
      {/* Apply Now starts here */}
      <section className="apply-now  with-position position-2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-1"></div>
            <div className="col-lg-6">
              <div className="apply-now-content-wrapper">
                <h6>Manchester Islamic Centre</h6>
                <h2>How To Become Muslim</h2>
                <p>
                  Habitant morbi tristique senectus et. Nullam vehicula ipsum a
                  arcu cursus. Aliquam purus sit amet luctus venenatis lectus
                  magna fringilla urna. Malesuada pellentesque elit eget gravida
                  cum sociis natoque. Faucibus ornare suspendisse sed nisi
                  lacus. Consectetur adipiscing elit duis tristique sollicitudin
                  nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
                  egestas sed tempus urna et pharetra pharetra massa. Enim ut
                  tellus elementum sagittis vitae et leo duis ut. ntesque elit
                  eget gravida cum sociis natoque. Faucibus ornare suspendisse
                  sed nisi lacus. Consectetur adipiscing elit duis tristique
                  sollicitudin nibh sit amet. Non curabitur gravida arcu ac
                  tortor. Ac tur
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="family-starts-here">
                <figure>
                  <img src={ComImg2} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Apply Now End here */}
      {/* Family Counceling starts here */}
      <section className="about-mission">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-img-wrapper">
                <figure>
                  <img src={ComImg3} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-mission-content-wrapper mb-4">
                <h6 className="small-heading">Manchester Islamic Centre</h6>
                <h2>Introduction to Islam</h2>
                <p>
                  Habitant morbi tristique senectus et. Nullam vehicula ipsum a
                  arcu cursus. Aliquam purus sit amet luctus venenatis lectus
                  magna fringilla urna. Malesuada pellentesque elit eget gravida
                  cum sociis natoque. Faucibus ornare suspendisse sed nisi
                  lacus. Consectetur adipiscing elit duis tristique sollicitudin
                  nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
                  egestas sed tempus urna et pharetra pharetra massa. Enim ut
                  tellus elementum sagittis vitae et leo duis ut. ntesque elit
                  eget gravida cum sociis natoque. Faucibus ornare suspendisse
                  sed nisi lacus. Consectetur adipiscing
                </p>
                <p>
                  Habitant morbi tristique senectus et. Nullam vehicula ipsum a
                  arcu cursus. Aliquam purus sit amet luctus venenatis lectus
                  magna fringilla urna. Malesuada pellentesque elit eget gravida
                  cum sociis natoque. Faucibus ornare suspendisse sed nisi
                  lacus. Consectetur adipiscing elit duis tristique sollicitudin
                  nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
                  egestas sed tempus urna
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Dawah Start here */}
      <section className="dawah-acitvities mt-lg-5">
        <div className="container">
          <div className="dawah-start">
            <div className="row align-items-center">
              <div className="col-md-5">
                <div className="top-heading">
                  <h6 className="small-heading mb-0">
                    Manchester Islamic Centre
                  </h6>
                  <h2 className="mb-0">Dawah Activities</h2>
                  <p className="mb-0">
                    Diam ut venenatis tellus in metus vulputate eu scelerisque.
                    Commodo nulla facilisi nullam vehicula ipsum a arcu cursus
                    vitae. Sed risus ultricies
                  </p>
                </div>
              </div>
              <div className="col-md-4 ms-auto">
                <div className="link-here">
                  <Link className="btn ms-auto" to="/dawah-acitivities">
                    View All
                  </Link>
                </div>
              </div>
            </div>
            <div className="activites-list">
              <div className="row">
                {Members.map((data, index) => (
                  <div className="col-md-3 mt-3" key={index}>
                    <div className="single-activity">
                      <figure>
                        <img src={data.Img} className="w-100" alt="" />
                      </figure>
                      <div className="member-data">
                        <h4>{data.name}</h4>
                        <p>{data.text}</p>
                        <Link
                          className="colored-btn mt-3"
                          to="/activity-details"
                        >
                          Learn More
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Dawah End here */}
      {/* Dawah Start here */}
      <section className="dawah-acitvities mt-lg-5">
        <div className="container">
          <div className="dawah-start">
            <div className="row align-items-center">
              <div className="col-md-7">
                <div className="top-heading">
                  <h6 className="small-heading mb-0">
                    Manchester Islamic Centre
                  </h6>
                  <h2 className="mb-0">Community Help Activities</h2>
                  <p className="mb-0">
                    Diam ut venenatis tellus in metus vulputate eu scelerisque.
                    Commodo nulla facilisi nullam vehicula ipsum a arcu cursus
                    vitae. Sed risus ultricies
                  </p>
                </div>
              </div>
              <div className="col-md-4 ms-auto">
                <div className="link-here">
                  <Link className="btn ms-auto" to="/help-acitivities">
                    View All
                  </Link>
                </div>
              </div>
            </div>
            <div className="activites-list">
              <div className="row">
                {HelpAcivities.map((data, index) => (
                  <div className="col-md-3 mt-3" key={index}>
                    <div className="single-activity">
                      <figure>
                        <img src={data.Img} className="w-100" alt="" />
                      </figure>
                      <div className="member-data">
                        <h4>{data.name}</h4>
                        <p>{data.text}</p>
                        <Link
                          className="colored-btn mt-3"
                          to="/activity-details"
                        >
                          Learn More
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Dawah End here */}
      {/* Family Counceling End here */}
      <section className="custom-forms">
        <div className="container">
          <FormHeadings
            SmallHeading="Manchester Islamic Centre"
            MainHeading="Register For School & Group Visits"
            Text="Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas "
            containerSize="col-xxl-10 col-xl-10 col-lg-9 col-md-10 col-sm-12 mx-auto px-xl-2"
          />
          <div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
            <div className="custom-form-start">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="OrganizationName">
                        School /Organization Name *
                      </label>
                      <select
                        name="OrganizationName"
                        id="OrganizationName"
                        className="form-select form-control"
                      >
                        <option value="Select here">Select here</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="Address">Address *</label>
                      <input
                        type="text"
                        name="Address"
                        id="Address"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="ContactNumber">Contact Number</label>
                      <input
                        type="text"
                        name="ContactNumber"
                        id="ContactNumber"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="EmailAddress">Email Address</label>
                      <input
                        type="text"
                        name="EmailAddress"
                        id="EmailAddress"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="NumberVisitors">
                        Number Of Visitors{" "}
                      </label>
                      <input
                        type="text"
                        name="NumberVisitors"
                        id="NumberVisitors"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="VisitDateTime">Visit Date & Time</label>
                      <input
                        type="text"
                        name="VisitDateTime"
                        id="VisitDateTime"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mt-2">
                    <div className="form-group">
                      <button type="submit" className="btn ms-auto px-5">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ContactSec />
      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

export default CommunityEngagement;
