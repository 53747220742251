import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactSec from "../../components/ContactSec";
import FormHeadings from "../../components/FormHeadings";
import { Link } from "react-router-dom";
import { ClipIcon } from "../../constant";
import { useNavigate } from "react-router-dom";
const DeclarationWifeDivorce = () => {
	const navigate = useNavigate();
	const handleSubmit = (event) => {
		event.preventDefault();
		// perform form submission logic here
		navigate("/checkout"); // navigate to the success page
	};
	return (
		<>
			<Header />
			<section className="custom-forms">
				<div className="container">
					<FormHeadings
						SmallHeading="Manchester Islamic Centre"
						MainHeading="Wife Seeking Divorce From Her Husband"
						containerSize="col-xl-9 col-lg-9 col-md-10 col-sm-12 mx-auto px-xl-4"
						Text="Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas "
					/>

					<div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
						<div className="custom-form-start">
							<h6 className="form-inner-heading mt-1 mb-3">Evidence Upload</h6>
							<div className="text">
								<p>
									I authorise the MIC Sharia Council to investigate my case and
									then to consider my application to obtain and Islamic Divorce
									according to the rules and regulations of the Council
								</p>

								<p>
									•I agree to accept the decision made by the council. However,
									I am aware that I may withdraw my case before the Council’s
									decision.
								</p>

								<p>
									•I understand that once the Council has initiated the
									proceedings I will not be able to claim the refund of the fee
									paid.
								</p>

								<p>
									•I confirm that I have not applied to any other Sharia Council
									for my Islamic Divorce.
								</p>

								<p>
									•I also promise not to enter into another marriage contract
									before the decision of the Council and the Iddah period.
								</p>

								<p>
									•I confirm that I have read the procedure outline in this form
									explaining our procedure and I have read it carefully and
									agree to it.
								</p>

								<p>
									•I understand that the file for my case will be surely
									retained by the Sharia Council for a period of 6 years from
									the date of application, after which it will be safely
									destroyed.
								</p>

								<p>
									•I understand that my statement ONLY may be disclosed to my
									spouse if requested.
								</p>

								<p>
									I have read and understood all the sections before submitting
									this form. I testify In the name of Allah (SWT) that the
									information, which has been given , is true. By submitting my
									name below I are giving the MIC Sharia Council full consent to
									deal with the matter stated above.
								</p>
							</div>
							<form onSubmit={handleSubmit}>
								<div className="row">
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="PrintName">Print Name</label>
											<input
												type="text"
												name="PrintName"
												id="PrintName"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group mb-3">
											<label htmlFor="Date">Date</label>
											<input
												type="text"
												name="Date"
												id="Date"
												className="form-control"
												placeholder="DD/MM/YYYY "
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3 pt-1">
											<label htmlFor="">Evidence One</label>
											<div className="file-uploader-custom">
												<label htmlFor="UploadFile" className="fileuploader">
													<input
														type="file"
														name="UploadFile"
														hidden
														id="UploadFile"
													/>
													<span>
														Drag & Drop .PDF Files here or click to upload
													</span>
												</label>
												<div className="col-md-6 px-0">
													<div className="files-list">
														<div className="single-file">
															<div className="icon-text">
																<img src={ClipIcon} alt="" />
																<span>Attachment 1 .pdf</span>
															</div>
															<span className="removefile">Remove</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-12 mt-2">
										<div className="form-group d-flex gap-3">
											<Link
												to="/evidence-upload-civil-marriage-1"
												className="btn ms-auto px-5"
											>
												Back
											</Link>
											<button type="submit" className="btn px-5">
												Submit
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			<ContactSec />
			<Footer />
		</>
	);
};
export default DeclarationWifeDivorce;
