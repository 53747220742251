import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
	aboutmisimg,
	historyimg,
	micvideo,
	trusti1,
	trusti2,
	trusti3,
	trusti4,
	video1,
} from "../../constant";
import "../../assets/css/about.css";
import ContactSec from "../../components/ContactSec";

const About = () => {
	const managment = [
		{
			id: 1,
			img: trusti1,
			name: "Mr Fawzi Al Haffar",
			desgination: "Chairman",
		},
		{
			id: 2,
			img: trusti2,
			name: "Mr Fawzi Al Haffar",
			desgination: "Chairman",
		},
		{
			id: 3,
			img: trusti3,
			name: "Mr Fawzi Al Haffar",
			desgination: "Chairman",
		},
		{
			id: 4,
			img: trusti4,
			name: "Mr Fawzi Al Haffar",
			desgination: "Chairman",
		},
		{
			id: 5,
			img: trusti3,
			name: "Mr Fawzi Al Haffar",
			desgination: "Chairman",
		},
		{
			id: 6,
			img: trusti4,
			name: "Mr Fawzi Al Haffar",
			desgination: "Chairman",
		},
	];
	const Trustees = [
		{
			id: 1,
			img: trusti1,
			name: "Mr Fawzi Al Haffar",
			desgination: "Chairman",
		},
		{
			id: 2,
			img: trusti2,
			name: "Mr Fawzi Al Haffar",
			desgination: "Chairman",
		},
		{
			id: 3,
			img: trusti3,
			name: "Mr Fawzi Al Haffar",
			desgination: "Chairman",
		},
		{
			id: 4,
			img: trusti4,
			name: "Mr Fawzi Al Haffar",
			desgination: "Chairman",
		},
		{
			id: 5,
			img: trusti1,
			name: "Mr Fawzi Al Haffar",
			desgination: "Chairman",
		},
		{
			id: 6,
			img: trusti2,
			name: "Mr Fawzi Al Haffar",
			desgination: "Chairman",
		},
		{
			id: 7,
			img: trusti3,
			name: "Mr Fawzi Al Haffar",
			desgination: "Chairman",
		},
		{
			id: 8,
			img: trusti4,
			name: "Mr Fawzi Al Haffar",
			desgination: "Chairman",
		},
	];
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}
			{/* About starts here */}
			<section className="about-mission">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="about-mission-content-wrapper mb-4">
								<h6 className="small-heading">Manchester Islamic Centre</h6>
								<h2>About Mic & Didsbury Mosque</h2>
								<p>
									The Manchester Islamic Centre was formerly the Albert Park
									Methodist Church , which opened for worship in 1883, but in
									1962, the church closed down. In 1967, it was bought by some
									members of the Syrian Arab community to be used as a Mosque
									for the Manchester Muslim community. The property consists of
									two large buildings, one is used as a Mosque and library, and
									the other includes a prayer hall for women, social events
									hall, classrooms, a kitchen and an ablution facility.
								</p>
							</div>
							<div className="about-mission-content-wrapper">
								<h2>Our Mission</h2>
								<p>
									By providing the optimal Islamic environment for worship,
									education, counselling and social activities, we seek to
									enable British Muslims excel and contribute positively towards
									enriching the UK community
								</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-img-wrapper">
								<figure>
									<img src={aboutmisimg} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* About End here */}
			{/* Our polices starts here */}
			<section className="our-policies">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="policies-content-wrapper">
								<h6>Manchester Islamic Centre</h6>
								<h2>Our Policies</h2>
								<p>
									Habitant morbi tristique senectus et. Nullam vehicula ipsum a
									arcu cursus. Aliquam purus sit amet luctus venenatis lectus
									magna fringilla urna. Malesuada pellentesque elit eget gravida
									cum sociis natoque. Faucibus ornare suspendisse sed nisi
									lacus. Consectetur adipiscing elit duis tristique sollicitudin
									nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
									egestas sed tempus urna et pharetra pharetra massa. Enim ut
									tellus elementum sagittis vitae et leo duis ut. ntesque elit
									eget gravida cum sociis natoque. Faucibus ornare suspendisse
									sed nisi lacus. Consectetur adipiscing elit duis tristique
									sollicitudin nibh sit amet. Non curabitur gravida arcu ac
									tortor. Ac tur
								</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="policies-list-wrapper mt-lg-5 ms-xxl-5 ms-lg-3">
								<ul>
									<li>
										<i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
										<span>
											Habitant morbi tristique senectus et. Nullam vehicula
											ipsum a arcu cursus.{" "}
										</span>
									</li>
									<li>
										<i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
										<span>
											Habitant morbi tristique senectus et. Nullam vehicula
											ipsum a arcu cursus.{" "}
										</span>
									</li>
									<li>
										<i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
										<span>
											Habitant morbi tristique senectus et. Nullam vehicula
											ipsum a arcu cursus.{" "}
										</span>
									</li>
									<li>
										<i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
										<span>
											Habitant morbi tristique senectus et. Nullam vehicula
											ipsum a arcu cursus.{" "}
										</span>
									</li>
									<li>
										<i className="fa-solid fa-circle-check"></i>{" "}
										<span>
											Habitant morbi tristique senectus et. Nullam vehicula
											ipsum a arcu cursus.{" "}
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Our polices End here */}
			{/* History  starts here */}
			<section className="History-mission">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="about-img-wrapper">
								<figure>
									<img src={historyimg} className="img-fluid" alt="" />
								</figure>
								<div className="img-content-wrapper">
									<p>
										Didsbury Mosque. Painting © 2013 Peter Topping, Paintings
										from Pictures
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-mission-content-wrapper mb-4">
								<h6 className="small-heading">Manchester Islamic Centre</h6>
								<h2>History Mic & Didsbury Mosque</h2>
								<p>
									Habitant morbi tristique senectus et. Nullam vehicula ipsum a
									arcu cursus. Aliquam purus sit amet luctus venenatis lectus
									magna fringilla urna. Malesuada pellentesque elit eget gravida
									cum sociis natoque. Faucibus ornare suspendisse sed nisi
									lacus. Consectetur adipiscing elit duis tristique sollicitudin
									nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
									egestas sed tempus urna et pharetra pharetra massa. Enim ut
									tellus elementum sagittis vitae et leo duis ut.
								</p>
								<p>
									ntesque elit eget gravida cum sociis natoque. Faucibus ornare
									suspendisse sed nisi lacus. Consectetur adipiscing elit duis
									tristique sollicitudin nibh sit amet. Non curabitur gravida
									arcu ac tortor. Ac tur
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* History  End here */}
			{/* Mic video starts here */}
			<section className="mic-video-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="mic-content-wrapper">
								<h6 className="small-heading">Manchester Islamic Centre</h6>
								<h2>Mic & Didsbury Mosque Video Intro</h2>
								<p>
									Diam ut venenatis tellus in metus vulputate eu scelerisque.
									Commodo nulla facilisi nullam vehicula ipsum a arcu cursus
									vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae
									congue mauris rhoncus aenean vel. Et egestas
								</p>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
					<div className="row mt-5">
						<div className="col-lg-12">
							<div className="mic-img-wrapper">
								<figure>
									<video
										src={video1}
										autoPlay
										muted
										className="img-fluid w-100"
									></video>
									{/* <img src={micvideo} className="img-fluid" alt="" /> */}
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Mic video End here */}
			{/* Our managment starts here */}
			<section className="our-managment">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="managment-content-wrapper">
								<h6 className="small-heading">Manchester Islamic Centre</h6>
								<h2>Our Management</h2>
								<p>
									Diam ut venenatis tellus in metus vulputate eu scelerisque.
									Commodo nulla facilisi nullam vehicula ipsum a arcu cursus
									vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae
									congue mauris rhoncus aenean vel. Et egestas
								</p>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
					<div className="managment-row-wrapper">
						{managment.map((item, index) => {
							return (
								<>
									<div key={index} className="managemnet-card-wrapper">
										<div className="managment-img-wrapper">
											<figure>
												<img src={item?.img} className="img-fluid" alt="" />
											</figure>
										</div>
										<div className="managment-content-wrapper">
											<h4>{item?.name}</h4>
											<h5>{item?.desgination}</h5>
										</div>
									</div>
								</>
							);
						})}
					</div>
					<div className="text-center mt-5">
						<button className="btn mx-auto">Load More</button>
					</div>
				</div>
			</section>
			{/* Our managment End here */}
			{/* Our managment starts here */}
			<section className="our-managment">
				<div className="container">
					<div className="row">
						<div className="col-lg-3"></div>
						<div className="col-lg-6">
							<div className="managment-content-wrapper">
								<h6 className="small-heading">Manchester Islamic Centre</h6>
								<h2>Trustees</h2>
								<p>
									Diam ut venenatis tellus in metus vulputate eu scelerisque.
									Commodo nulla facilisi nullam vehicula ipsum a arcu cursus
									vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae
									congue mauris rhoncus aenean vel. Et egestas
								</p>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
					<div className="managment-row-wrapper">
						{Trustees.map((item, index) => {
							return (
								<>
									<div key={index} className="managemnet-card-wrapper">
										<div className="managment-img-wrapper">
											<figure>
												<img src={item?.img} className="img-fluid" alt="" />
											</figure>
										</div>
										<div className="managment-content-wrapper">
											<h4>{item?.name}</h4>
											<h5>{item?.desgination}</h5>
										</div>
									</div>
								</>
							);
						})}
					</div>
					<div className="text-center mt-5">
						<button className="btn mx-auto">Load More</button>
					</div>
				</div>
			</section>
			{/* Our managment End here */}
			<ContactSec />
			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default About;
