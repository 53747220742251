import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { documentimg, SchoolGImg1, SchoolGImg2 } from "../../constant";
import { Link } from "react-router-dom";
import "../../assets/css/about.css";
import ContactSec from "../../components/ContactSec";
import "../../assets/css/shariah-family-council.css";
import ApplyNow from "../../components/ApplyNow";

const QuranSchool = () => {
	const arr = [
		"Habitant morbi tristique senectus et. Nullam vehicula ipsum.",
		"Habitant morbi tristique senectus et. Nullam vehicula ipsum.",
		"Habitant morbi tristique senectus et. Nullam vehicula ipsum.",
		"Habitant morbi tristique senectus et. Nullam vehicula ipsum.",
		"Habitant morbi tristique senectus et. Nullam vehicula ipsum.",
	];
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}
			{/* Family starts here */}
			<section className="about-mission">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="about-mission-content-wrapper mb-4">
								<h6 className="small-heading">Manchester Islamic Centre</h6>
								<h2>Quran School Introduction</h2>
								<p>
									Habitant morbi tristique senectus et. Nullam vehicula ipsum a
									arcu cursus. Aliquam purus sit amet luctus venenatis lectus
									magna fringilla urna. Malesuada pellentesque elit eget gravida
									cum sociis natoque. Faucibus ornare suspendisse sed nisi
									lacus. Consectetur adipiscing elit duis tristique sollicitudin
									nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
									egestas sed tempus urna et pharetra pharetra massa. Enim ut
									tellus elementum sagittis vitae et leo duis ut. ntesque elit
									eget gravida cum sociis natoque. Faucibus ornare suspendisse
									sed nisi lacus. Consectetur adipiscing
								</p>
								<p>
									Habitant morbi tristique senectus et. Nullam vehicula ipsum a
									arcu cursus. Aliquam purus sit amet luctus venenatis lectus
									magna fringilla urna. Malesuada pellentesque elit eget gravida
									cum sociis natoque. Faucibus ornare suspendisse sed nisi
									lacus. Consectetur adipiscing elit duis tristique sollicitudin
									nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
									egestas sed tempus urna
								</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-img-wrapper">
								<figure>
									<img src={SchoolGImg1} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Family End here */}
			{/* Apply Now starts here */}
			<div className="container">
				<section className="our-policies">
					<div className="row align-items-center py-4">
						<div className="col-lg-1"></div>
						<div className="col-lg-5">
							<div className="policies-content-wrapper">
								<h6>Manchester Islamic Centre</h6>
								<h2>School History</h2>
								<p>
									Habitant morbi tristique senectus et. Nullam vehicula ipsum a
									arcu cursus. Aliquam purus sit amet luctus venenatis lectus
									magna fringilla urna. Malesuada pellentesque elit eget gravida
									cum sociis natoque. Faucibus ornare suspendisse sed nisi
									lacus.{" "}
								</p>
								<Link to="" className="btn">
									<span className="icon">
										<img src={documentimg} alt="" />
									</span>
									<span>Application Form</span>
								</Link>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="policies-list-wrapper">
								<ul>
									{arr.map((data, index) => {
										return (
											<>
												<li>
													<i
														className="fa fa-check-circle"
														aria-hidden="true"
													></i>{" "}
													<span>{data}</span>
												</li>
											</>
										);
									})}
								</ul>
							</div>
						</div>
					</div>
				</section>
			</div>
			{/* Apply Now End here */}
			{/* Family Counceling starts here */}
			<section className="about-mission">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="about-img-wrapper">
								<figure>
									<img src={SchoolGImg2} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-mission-content-wrapper mb-4">
								<h6 className="small-heading">Manchester Islamic Centre</h6>
								<h2>Curriculum</h2>
								<p>
									Habitant morbi tristique senectus et. Nullam vehicula ipsum a
									arcu cursus. Aliquam purus sit amet luctus venenatis lectus
									magna fringilla urna. Malesuada pellentesque elit eget gravida
									cum sociis natoque. Faucibus ornare suspendisse sed nisi
									lacus. Consectetur adipiscing elit duis tristique sollicitudin
									nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
									egestas sed tempus urna et pharetra pharetra massa. Enim ut
									tellus elementum sagittis vitae et leo duis ut. ntesque elit
									eget gravida cum sociis natoque. Faucibus ornare suspendisse
									sed nisi lacus. Consectetur adipiscing
								</p>
								<p>
									Habitant morbi tristique senectus et. Nullam vehicula ipsum a
									arcu cursus. Aliquam purus sit amet luctus venenatis lectus
									magna fringilla urna. Malesuada pellentesque elit eget gravida
									cum sociis natoque. Faucibus ornare suspendisse sed nisi
									lacus. Consectetur adipiscing elit duis tristique sollicitudin
									nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
									egestas sed tempus urna
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Family Counceling End here */}
			<ContactSec />
			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default QuranSchool;
