import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactSec from "../../components/ContactSec";
import FormHeadings from "../../components/FormHeadings";
import { Link } from "react-router-dom";
import { ClipIcon } from "../../constant";
import { useNavigate } from "react-router-dom";
const WillChildrenDetails = () => {
	const navigate = useNavigate();
	const handleSubmit = (event) => {
		event.preventDefault();
		// perform form submission logic here
		navigate("/will-parent-details"); // navigate to the success page
	};
	return (
		<>
			<Header />
			<section className="custom-forms">
				<div className="container">
					<FormHeadings
						SmallHeading="Manchester Islamic Centre"
						MainHeading="Islamic Will Form"
						Text="Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas "
					/>
					<div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
						<div className="custom-form-start">
							<h6 className="form-inner-heading mt-1 mb-3">
								Details OF Children
							</h6>
							<form onSubmit={handleSubmit}>
								<div className="row">
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="ChildrenFromTheMarriage">
												Are There Any Children From The Marriage?
											</label>
											<input
												type="text"
												name="ChildrenFromTheMarriage"
												id="ChildrenFromTheMarriage"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="ChildTwoNameGenderAndAge">
												Child One-Name, Gender And Age
											</label>
											<textarea
												name="ChildTwoNameGenderAndAge"
												id="ChildTwoNameGenderAndAge"
												className="form-control"
												placeholder="Your answer"
											></textarea>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="ChildTwoNameGenderAndAge">
												Child Two-Name, Gender And Age
											</label>
											<textarea
												name="ChildTwoNameGenderAndAge"
												id="ChildTwoNameGenderAndAge"
												className="form-control"
												placeholder="Your answer"
											></textarea>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="ChildThreeNameGenderAndAge">
												Child Three-Name, Gender And Age
											</label>
											<textarea
												name="ChildThreeNameGenderAndAge"
												id="ChildThreeNameGenderAndAge"
												className="form-control"
												placeholder="Your answer"
											></textarea>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="ChildFourNameGenderAndAge">
												Child Four-Name, Gender And Age
											</label>
											<textarea
												name="ChildFourNameGenderAndAge"
												id="ChildFourNameGenderAndAge"
												className="form-control"
												placeholder="Your answer"
											></textarea>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="ChildFiveNameGenderAndAge">
												Child Five-Name, Gender And Age
											</label>
											<textarea
												name="ChildFiveNameGenderAndAge"
												id="ChildFiveNameGenderAndAge"
												className="form-control"
												placeholder="Your answer"
											></textarea>
										</div>
									</div>
									<div className="col-md-12 mt-2">
										<div className="form-group d-flex gap-3">
											<Link
												to="/islamic-will-form"
												className="btn ms-auto px-5"
											>
												Back
											</Link>
											<button type="submit" className="btn px-5">
												Next
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			<ContactSec />
			<Footer />
		</>
	);
};
export default WillChildrenDetails;
