import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
  documentimg,
  SchoolGImg1,
  CompetitionImg2,
  LogoImg2,
  MemberImg1,
  MemberImg2,
  MemberImg3,
  MemberImg4,
  CompetitionImg4,
} from "../../constant";
import { Link } from "react-router-dom";
import "../../assets/css/about.css";
import ContactSec from "../../components/ContactSec";
import "../../assets/css/competition.css";
import ApplyNow from "../../components/ApplyNow";

const UpcomingCompetition = () => {
  let MemberName = "Mr Fawzi Al Haffar";
  let MemberPost = "Chairman";

  const Members = [
    {
      name: MemberName,
      post: MemberPost,
      Img: MemberImg1,
    },
    {
      name: MemberName,
      post: MemberPost,
      Img: MemberImg2,
    },
    {
      name: MemberName,
      post: MemberPost,
      Img: MemberImg3,
    },
    {
      name: MemberName,
      post: MemberPost,
      Img: MemberImg4,
    },
    {
      name: MemberName,
      post: MemberPost,
      Img: MemberImg1,
    },
    {
      name: MemberName,
      post: MemberPost,
      Img: MemberImg2,
    },
    {
      name: MemberName,
      post: MemberPost,
      Img: MemberImg3,
    },
    {
      name: MemberName,
      post: MemberPost,
      Img: MemberImg4,
    },
  ];
  const arr = [
    "Habitant morbi tristique senectus et. Nullam vehicula ipsum.",
    "Habitant morbi tristique senectus et. Nullam vehicula ipsum.",
    "Habitant morbi tristique senectus et. Nullam vehicula ipsum.",
    "Habitant morbi tristique senectus et. Nullam vehicula ipsum.",
    "Habitant morbi tristique senectus et. Nullam vehicula ipsum.",
  ];
  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}
      {/* Family starts here */}
      <section className="about-mission">
        <div className="container">
          <div className="row align-items-start">
            <div className="col-lg-6">
              <div className="about-mission-content-wrapper mb-4">
                <h6 className="small-heading">Manchester Islamic Centre</h6>
                <h2>Upcoming Competitions</h2>
                <p>
                  Habitant morbi tristique senectus et. Nullam vehicula ipsum a
                  arcu cursus. Aliquam purus sit amet luctus venenatis lectus
                  magna fringilla urna. Malesuada pellentesque elit eget gravida
                  cum sociis natoque. Faucibus ornare suspendisse sed nisi
                  lacus. Consectetur adipiscing elit duis tristique sollicitudin
                  nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
                  egestas sed tempus urna et pharetra pharetra massa. Enim ut
                  tellus elementum sagittis vitae et leo duis ut. ntesque elit
                  eget gravida cum sociis natoque. Faucibus ornare suspendisse
                  sed nisi lacus. Consectetur adipiscing
                </p>
                <p>
                  t duis tristique sollicitudin nibh sit amet. Non curabitur
                  gravida arcu ac tortor. Ac turpis egestas sed tempus urna et
                  pharetra pharetra massa. Enim ut tellus elem
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-img-wrapper">
                <figure>
                  <img src={CompetitionImg4} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Family End here */}
      {/* Second Greater Start Here */}
      <section className="green-section">
        <div className="container">
          <div className="green-start-here">
            <div className="logo">
              <figure>
                <img src={LogoImg2} alt="" />
              </figure>
            </div>
            <div className="para">
              <p>
                The Second Greater Manchester Madrasas’ Quran Memorisation
                Competition 2023
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Second Greater End Here */}
      <section className="competiion-table">
        <div className="container">
          <div className="competition-table-start">
            <table>
              <thead>
                <th>Levels</th>
                <th>Details</th>
              </thead>
              <tbody>
                <tr>
                  <td>Level 1</td>
                  <td>ONE juza Quran memorisation</td>
                </tr>
                <tr>
                  <td>Level 2</td>
                  <td>THREE juza Quran memorisation</td>
                </tr>
                <tr>
                  <td>Level 3</td>
                  <td> FIVE juza Quran memorisation</td>
                </tr>
                <tr>
                  <td>Level 4</td>
                  <td>FIFTEEN juza Quran memorisation</td>
                </tr>
                <tr>
                  <td>Level 5</td>
                  <td>WHOLE Quran memorisation</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="pihlo-bold">
            Note : There Will Be 3 Winners With 3 Prizes For Each Level.
          </p>
        </div>
      </section>
      {/* Apply Now starts here */}
      <section className="our-policies">
        <div className="container">
          <div className="row align-items-center py-4">
            <div className="col-lg-5">
              <div className="policies-content-wrapper">
                <h6>Manchester Islamic Centre</h6>
                <h2>School History</h2>
                <p>
                  Habitant morbi tristique senectus et. Nullam vehicula ipsum a
                  arcu cursus. Aliquam purus sit amet luctus venenatis lectus
                  magna fringilla urna. Malesuada pellentesque elit eget gravida
                  cum sociis natoque. Faucibus ornare suspendisse sed nisi
                  lacus.{" "}
                </p>
                <Link to="" className="btn">
                  <span className="icon">
                    <img src={documentimg} alt="" />
                  </span>
                  <span>Application Form</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="policies-list-wrapper">
                <ul>
                  {arr.map((data, index) => {
                    return (
                      <>
                        <li>
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          ></i>{" "}
                          <span>{data}</span>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Apply Now End here */}
      <section className="competiion-table-1 pt-5">
        <div className="container">
          <div className="col-lg-8 mx-auto px-xl-1">
            <div className="top-heading text-center">
              <h6 className="small-heading mb-0">Manchester Islamic Centre</h6>
              <h2 className="mb-0">Competition Prizes</h2>
            </div>
          </div>
          <div className="competition-table-1-start">
            <table>
              <thead>
                <th>Levels</th>
                <th>Challenge</th>
                <th>Age</th>
                <th>1st Prize</th>
                <th>2nd Prize</th>
                <th>3rd Prize</th>
              </thead>
              <tbody>
                <tr>
                  <td>Level 1</td>
                  <td>Memorising of ONE chapters of the Qur’an</td>
                  <td>6-12 Years</td>
                  <td>£200</td>
                  <td>£150</td>
                  <td>£100</td>
                </tr>
                <tr>
                  <td>Level 2</td>
                  <td>Memorising of THREE chapters of the Qur’an</td>
                  <td>6-12 Years</td>
                  <td>£400</td>
                  <td>£350</td>
                  <td>£300</td>
                </tr>
                <tr>
                  <td>Level 3</td>
                  <td>Memorising of FIVE chapters of the Qur’an </td>
                  <td>6-15 Years</td>
                  <td>£700</td>
                  <td>£600</td>
                  <td>£500</td>
                </tr>
                <tr>
                  <td>Level 4</td>
                  <td>Memorising of FIFTEEN chapters of the Qur’an</td>
                  <td>6-18 Years</td>
                  <td>£2000</td>
                  <td>£1500</td>
                  <td>£1000</td>
                </tr>
                <tr>
                  <td>Level 5</td>
                  <td>Memorising of the WHOLE Qur’an</td>
                  <td>6-21 Years</td>
                  <td>£5000</td>
                  <td>£4000</td>
                  <td>£3000</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="pihlo-bold">
            Note : Each Candidate Will Have A Certificate Of Participation, Plus
            A Shield Of Honour For His/Her Madrasa
          </p>
        </div>
      </section>
      <section className="team-members pb-3">
        <div className="container">
          <div className="tean-merbers-start">
            <div className="col-lg-8 mx-auto px-xl-1">
              <div className="top-heading text-center">
                <h6 className="small-heading mb-0">
                  Manchester Islamic Centre
                </h6>
                <h2 className="mb-0">Judgement Committee</h2>
                <p className="mb-0">
                  Diam ut venenatis tellus in metus vulputate eu scelerisque.
                  Commodo nulla facilisi nullam vehicula ipsum a arcu cursus
                  vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae
                  congue mauris rhoncus aenean vel. Et egestas{" "}
                </p>
              </div>
            </div>
            <div className="members-list">
              <div className="row">
                {Members.map((data, index) => (
                  <div className="col-md-3 mt-5" key={index}>
                    <div className="single-member">
                      <Link to="">
                        <figure>
                          <img src={data.Img} className="w-100" alt="" />
                        </figure>
                        <div className="member-data">
                          <h4>{data.name}</h4>
                          <h5 className="small-pnik-heading">{data.post}</h5>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
              <div className="text-center mt-5">
                <button className="btn mx-auto">Load More</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Family Counceling starts here */}
      <section className="about-mission">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-img-wrapper">
                <figure>
                  <img src={CompetitionImg2} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-mission-content-wrapper mb-4">
                <h6 className="small-heading">Manchester Islamic Centre</h6>
                <h2>How To Register</h2>
                <p>
                  Habitant morbi tristique senectus et. Nullam vehicula ipsum a
                  arcu cursus. Aliquam purus sit amet luctus venenatis lectus
                  magna fringilla urna. Malesuada pellentesque elit eget gravida
                  cum sociis natoque. Faucibus ornare suspendisse sed nisi
                  lacus. Consectetur adipiscing elit duis tristique sollicitudin
                  nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
                  egestas sed tempus urna et pharetra pharetra massa. Enim ut
                  tellus elementum sagittis vitae et leo duis ut. ntesque elit
                  eget gravida cum sociis natoque. Faucibus ornare suspendisse
                  sed nisi lacus. Consectetur adipiscing
                </p>
                <Link to="" className="btn">
                  <span className="icon">
                    <img src={documentimg} alt="" />
                  </span>
                  <span>Registration Link</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Family Counceling End here */}
      <ContactSec />
      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

export default UpcomingCompetition;
