import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactSec from "../../components/ContactSec";
import FormHeadings from "../../components/FormHeadings";
import { Link } from "react-router-dom";
import { ClipIcon } from "../../constant";
import { useNavigate } from "react-router-dom";
const ChildrenDetailsWifeDivorce = () => {
	const navigate = useNavigate();
	const handleSubmit = (event) => {
		event.preventDefault();
		// perform form submission logic here
		navigate("/evidence-upload-civil-marriage"); // navigate to the success page
	};
	return (
		<>
			<Header />
			<section className="custom-forms">
				<div className="container">
					<FormHeadings
						SmallHeading="Manchester Islamic Centre"
						MainHeading="Wife Seeking Divorce From Her Husband"
						containerSize="col-xl-9 col-lg-9 col-md-10 col-sm-12 mx-auto px-xl-4"
						Text="Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas "
					/>

					<div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
						<div className="custom-form-start">
							<h6 className="form-inner-heading mt-1 mb-3">
								Details of Children From this Marriage
							</h6>
							<form onSubmit={handleSubmit}>
								<div className="row">
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">
												Are there any children from this marriage? If Yes ,
												please give details below
											</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													Yes
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													No
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="Children1NameandAge">
												Children 1 Name and Age
											</label>
											<input
												type="text"
												name="Children1NameandAge"
												id="Children1NameandAge"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="Children2NameandAge">
												Children 2 Name and Age
											</label>
											<input
												type="text"
												name="Children2NameandAge"
												id="Children2NameandAge"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="Children3NameandAge">
												Children 3 Name and Age
											</label>
											<input
												type="text"
												name="Children3NameandAge"
												id="Children3NameandAge"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="Children4NameandAge">
												Children 4 Name and Age
											</label>
											<input
												type="text"
												name="Children4NameandAge"
												id="Children4NameandAge"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="Children5NameandAge">
												Children 5 Name and Age
											</label>
											<input
												type="text"
												name="Children5NameandAge"
												id="Children5NameandAge"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="Children6NameandAge">
												Children 6 Name and Age
											</label>
											<input
												type="text"
												name="Children6NameandAge"
												id="Children6NameandAge"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="Whodothechildrenlivewith">
												Who do the children live with?
											</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													Mother
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													Father
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													Other
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
											<input
												type="text"
												name="Whodothechildrenlivewith"
												id="Whodothechildrenlivewith"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="IfOtherchildrenlivewith">
												If Other, please state who the children live with.
											</label>
											<input
												type="text"
												name="IfOtherchildrenlivewith"
												id="IfOtherchildrenlivewith"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12 me-md-3">
										<div className="form-group mb-3">
											<label htmlFor="ChildArrangementmaderegards">
												Are there any Child Arrangement made in regards to to
												the children? If yes, please state the arrangements in
												place
											</label>
											<input
												type="text"
												name="ChildArrangementmaderegards"
												id="ChildArrangementmaderegards"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12 mt-2">
										<div className="form-group d-flex gap-3">
											<Link
												to="/wife-divorce-civil-marriage"
												className="btn ms-auto px-5"
											>
												Back
											</Link>
											<button type="submit" className="btn px-5">
												Next
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			<ContactSec />
			<Footer />
		</>
	);
};
export default ChildrenDetailsWifeDivorce;
