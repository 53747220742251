import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import {
    HajjImg1,
    HajjImg2,
    HajjImg3,
    HajjImg4,
    HajjImg5,
    HajjImg6,
} from "../../constant";
import { Link } from 'react-router-dom'
import ContactSec from "../../components/ContactSec";
const HajjGallery = () => {
    const GalleryImages = [
        {
            id: 1,
            image: HajjImg1,
        },
        {
            id: 2,
            image: HajjImg2,
        },
        {
            id: 3,
            image: HajjImg3,
        },
        {
            id: 4,
            image: HajjImg2,
        },

        {
            id: 5,
            image: HajjImg4,
        },
        {
            id: 6,
            image: HajjImg5,
        },
        {
            id: 7,
            image: HajjImg6,
        },
        {
            id: 8,
            image: HajjImg4,
        },
        {
            id: 9,
            image: HajjImg5,
        },
        {
            id: 10,
            image: HajjImg1,
        },
        {
            id: 11,
            image: HajjImg2,
        },
        {
            id: 12,
            image: HajjImg3,
        },
        {
            id: 13,
            image: HajjImg2,
        },

        {
            id: 14,
            image: HajjImg4,
        },
        {
            id: 15,
            image: HajjImg5,
        },
        {
            id: 16,
            image: HajjImg6,
        },
        {
            id: 17,
            image: HajjImg4,
        },
        {
            id: 18,
            image: HajjImg5,
        },
    ];
    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}
            {/* Gallery Start Here */}
            <section className="photo-gallery without-back">
                <div className="container">
                    <div className="col-xxl-11 mx-auto mt-3">
                        <div className="col-lg-6 mx-auto px-xl-4">
                            <div className="top-heading text-center">
                                <h6 className="small-heading mb-0">
                                    Manchester Islamic Centre
                                </h6>
                                <h2 className="mb-0">Hajj & Umrah Gallery</h2>
                                <p className="mb-0">
                                    Sed cras ornare arcu dui vivamus arcu felis bibendum ut.
                                    Tempus egestas sed sed risus pretium quam vulputate
                                    dignissim. Quam pellentesque nec nam aliquam
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="gallery-main mb-lg-5">
                        <div className="col-xxl-11 mx-auto">
                            <div className="gallery-start">

                                <div className="galler-images">
                                    <div className="row">
                                        {GalleryImages.map((data, index) => (
                                            <div className="col-md-4 p-2" key={index}>
                                                <figure>
                                                    <img src={data.image} className="img-fluid" alt="" />
                                                </figure>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Gallery End Here */}

            <ContactSec />
            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

export default HajjGallery;