import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactSec from "../../components/ContactSec";
import FormHeadings from "../../components/FormHeadings";
const FatwahForm = () => {
	return (
		<>
			<Header />
			<section className="custom-forms">
				<div className="container">
					<FormHeadings
						SmallHeading="Manchester Islamic Centre"
						MainHeading="Fatwah Form"
						Text="Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas "
					/>
					<div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
						<div className="custom-form-start">
							<form>
								<div className="row">
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="FullName">Full Name *</label>
											<input
												type="text"
												name="FullName"
												id="FullName"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="LastName">Last Name *</label>
											<input
												type="text"
												name="LastName"
												id="LastName"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="PhoneNumber">Phone Number *</label>
											<input
												type="text"
												name="PhoneNumber"
												id="PhoneNumber"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="Email">Email *</label>
											<input
												type="text"
												name="Email"
												id="Email"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="Ethnicity">Ethnicity</label>
											<input
												type="text"
												name="Ethnicity"
												id="Ethnicity"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">
												Preferred Language (Mark only One) *
											</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													Arabic
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													English
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													Other
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="StateYourQuestion">
												Please State Your Question *{" "}
											</label>
											<textarea
												name="StateYourQuestion"
												id="StateYourQuestion"
												className="form-control lg"
												placeholder="Your answer"
											></textarea>
										</div>
									</div>

									<div className="col-md-12 mt-2">
										<div className="form-group">
											<button type="submit" className="btn ms-auto px-5">
												Submit
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			<ContactSec />
			<Footer />
		</>
	);
};
export default FatwahForm;
