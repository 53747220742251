import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
	LectureDetails1,
	LectureDetails2,
	LectureDetails3,
} from "../../constant";
import "../../assets/css/about.css";
import ContactSec from "../../components/ContactSec";
import "../../assets/css/shariah-family-council.css";
import FaqSection from "../../components/FaqSection";

const StudyCircle = () => {
	const [showTab, SetshowTab] = useState(false);
	function handleLecture() {
		SetshowTab(true);
	}
	function handlePopularLecture() {
		SetshowTab(false);
	}
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}
			{/* Family starts here */}
			<section className="about-mission">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="about-mission-content-wrapper mb-4">
								<h6 className="small-heading">Manchester Islamic Centre</h6>
								<h2>Study Circle</h2>
								<p>
									Habitant morbi tristique senectus et. Nullam vehicula ipsum a
									arcu cursus. Aliquam purus sit amet luctus venenatis lectus
									magna fringilla urna. Malesuada pellentesque elit eget gravida
									cum sociis natoque. Faucibus ornare suspendisse sed nisi
									lacus. Consectetur adipiscing elit duis tristique sollicitudin
									nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
									egestas sed tempus urna et pharetra pharetra massa. Enim ut
									tellus elementum sagittis vitae et leo duis ut. ntesque elit
									eget gravida cum sociis natoque. Faucibus ornare suspendisse
									sed nisi lacus. Consectetur adipiscing
								</p>
								<p>
									t duis tristique sollicitudin nibh sit amet. Non curabitur
									gravida arcu ac tortor. Ac turpis egestas sed tempus urna et
									pharetra pharetra massa. Enim ut tellus elem
								</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-img-wrapper">
								<figure>
									<img src={LectureDetails1} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Family End here */}
			{/* Family Counceling starts here */}
			<section className="about-mission">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="about-img-wrapper">
								<figure>
									<img src={LectureDetails2} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-mission-content-wrapper mb-4">
								<h6 className="small-heading">Manchester Islamic Centre</h6>
								<h2>Men Study Circle</h2>
								<p>
									Habitant morbi tristique senectus et. Nullam vehicula ipsum a
									arcu cursus. Aliquam purus sit amet luctus venenatis lectus
									magna fringilla urna. Malesuada pellentesque elit eget gravida
									cum sociis natoque. Faucibus ornare suspendisse sed nisi
									lacus. Consectetur adipiscing elit duis tristique sollicitudin
									nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
									egestas sed tempus urna et pharetra pharetra massa. Enim ut
									tellus elementum sagittis vitae et leo duis ut. ntesque elit
									eget gravida cum sociis natoque. Faucibus ornare suspendisse
									sed nisi lacus. Consectetur adipiscing
								</p>
								<p>
									t duis tristique sollicitudin nibh sit amet. Non curabitur
									gravida arcu ac tortor. Ac turpis egestas sed tempus urna et
									pharetra pharetra massa. Enim ut tellus elem
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Family Counceling End here */}
			{/* Family starts here */}
			<section className="about-mission">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="about-mission-content-wrapper mb-4">
								<h6 className="small-heading">Manchester Islamic Centre</h6>
								<h2>Women Study Circle</h2>
								<p>
									Habitant morbi tristique senectus et. Nullam vehicula ipsum a
									arcu cursus. Aliquam purus sit amet luctus venenatis lectus
									magna fringilla urna. Malesuada pellentesque elit eget gravida
									cum sociis natoque. Faucibus ornare suspendisse sed nisi
									lacus. Consectetur adipiscing elit duis tristique sollicitudin
									nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
									egestas sed tempus urna et pharetra pharetra massa. Enim ut
									tellus elementum sagittis vitae et leo duis ut. ntesque elit
									eget gravida cum sociis natoque. Faucibus ornare suspendisse
									sed nisi lacus. Consectetur adipiscing
								</p>
								<p>
									t duis tristique sollicitudin nibh sit amet. Non curabitur
									gravida arcu ac tortor. Ac turpis egestas sed tempus urna et
									pharetra pharetra massa. Enim ut tellus elem
								</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-img-wrapper">
								<figure>
									<img src={LectureDetails2} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Family End here */}
			{/* Lectures starts here */}
			<section className="lectures-section">
				<div className="container">
					<div className="lectures-start-here">
						<div className="top-heading">
							<h3>Lectures</h3>
							<p>
								{" "}
								Diam ut venenatis tellus in metus vulputate eu scelerisque.
								Commodo nulla{" "}
							</p>
						</div>
						<div className="lecture-filter">
							<button
								onClick={handleLecture}
								className={showTab ? "norma-btn active" : "norma-btn"}
							>
								All Lectures
							</button>
							<button
								onClick={handlePopularLecture}
								className={showTab ? "norma-btn" : "norma-btn active"}
							>
								Popular Lectures
							</button>
						</div>

						{!showTab ? (
							<div className="lecture-lists ii">
								<div className="single-lecture">
									<div className="row align-items-center">
										<div className="col-xl-5 col-lg-6 col-md-12">
											<div className="text">
												<h4>
													<a href="/lecture-details">Lecture 1</a>
												</h4>
												<p>
													Lorem ipsum dolor sit amet, consectetur adipiscing
													elit, sed do eiusmod tempor incididunt ut labore et
													dolore magna aliqua. Ut enim.
												</p>
											</div>
										</div>
										<div className="col-xl-7 col-lg-6 col-md-12">
											<div className="hours-class">
												<Link to="/lecture-details">8 Hours 26 Minutes</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="single-lecture">
									<div className="row align-items-center">
										<div className="col-xl-5 col-lg-6 col-md-12">
											<div className="text">
												<h4>
													<a href="/lecture-details">Lecture 1</a>
												</h4>
												<p>
													Lorem ipsum dolor sit amet, consectetur adipiscing
													elit, sed do eiusmod tempor incididunt ut labore et
													dolore magna aliqua. Ut enim.
												</p>
											</div>
										</div>
										<div className="col-xl-7 col-lg-6 col-md-12">
											<div className="hours-class">
												<Link to="/lecture-details">8 Hours 26 Minutes</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="single-lecture">
									<div className="row align-items-center">
										<div className="col-xl-5 col-lg-6 col-md-12">
											<div className="text">
												<h4>
													<a href="/lecture-details">Lecture 1 </a>
												</h4>
												<p>
													Lorem ipsum dolor sit amet, consectetur adipiscing
													elit, sed do eiusmod tempor incididunt ut labore et
													dolore magna aliqua. Ut enim.
												</p>
											</div>
										</div>
										<div className="col-xl-7 col-lg-6 col-md-12">
											<div className="hours-class">
												<Link to="/lecture-details">8 Hours 26 Minutes</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="single-lecture">
									<div className="row align-items-center">
										<div className="col-xl-5 col-lg-6 col-md-12">
											<div className="text">
												<h4>
													<a href="/lecture-details">Lecture 1</a>
												</h4>
												<p>
													Lorem ipsum dolor sit amet, consectetur adipiscing
													elit, sed do eiusmod tempor incididunt ut labore et
													dolore magna aliqua. Ut enim.
												</p>
											</div>
										</div>
										<div className="col-xl-7 col-lg-6 col-md-12">
											<div className="hours-class">
												<Link to="/lecture-details">8 Hours 26 Minutes</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						) : (
							<div className="lecture-lists">
								<div className="single-lecture">
									<div className="row align-items-center">
										<div className="col-xl-5 col-lg-6 col-md-12">
											<div className="text">
												<h4>
													<a href="/lecture-details">Lecture 1</a>
												</h4>
												<p>
													Lorem ipsum dolor sit amet, consectetur adipiscing
													elit, sed do eiusmod tempor incididunt ut labore et
													dolore magna aliqua. Ut enim.
												</p>
											</div>
										</div>
										<div className="col-xl-7 col-lg-6 col-md-12">
											<div className="hours-class">
												<Link to="/lecture-details">8 Hours 26 Minutes</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="single-lecture">
									<div className="row align-items-center">
										<div className="col-xl-5 col-lg-6 col-md-12">
											<div className="text">
												<h4>
													{" "}
													<a href="/lecture-details">Lecture 1</a>
												</h4>
												<p>
													Lorem ipsum dolor sit amet, consectetur adipiscing
													elit, sed do eiusmod tempor incididunt ut labore et
													dolore magna aliqua. Ut enim.
												</p>
											</div>
										</div>
										<div className="col-xl-7 col-lg-6 col-md-12">
											<div className="hours-class">
												<Link to="/lecture-details">8 Hours 26 Minutes</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="single-lecture">
									<div className="row align-items-center">
										<div className="col-xl-5 col-lg-6 col-md-12">
											<div className="text">
												<h4>
													{" "}
													<a href="/lecture-details">Lecture 1</a>
												</h4>
												<p>
													Lorem ipsum dolor sit amet, consectetur adipiscing
													elit, sed do eiusmod tempor incididunt ut labore et
													dolore magna aliqua. Ut enim.
												</p>
											</div>
										</div>
										<div className="col-xl-7 col-lg-6 col-md-12">
											<div className="hours-class">
												<Link to="/lecture-details">8 Hours 26 Minutes</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}

						<div className="paginations-start">
							<ul className="pagination-list">
								<li>
									<Link className="normal active">01.</Link>
								</li>
								<li>
									<Link className="normal">02.</Link>
								</li>
								<li>
									<Link className="normal">03.</Link>
								</li>
								<li>
									<Link className="normal">Next</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
			{/* Lectures End here */}
			<ContactSec />
			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default StudyCircle;
