import React from "react";
import { Link } from "react-router-dom";
import { documentimg, family3 } from "../constant";

const ApplyNow = (props) => {
	return (
		<>
			<section className="apply-now">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-1"></div>
						<div className="col-lg-5">
							<div className="apply-now-content-wrapper">
								<h6>{props?.firstheading}</h6>
								<h2>{props?.secondheading}</h2>
								<p>{props?.paragraph}</p>
								<Link to={props.redirecturl} className="btn">
									<span className="icon">
										<img src={documentimg} alt="" />
									</span>
									<span>Application Form</span>
								</Link>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="family-starts-here">
								<figure>
									<img src={props?.img} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ApplyNow;
