import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
    documentimg,
    MarriageImg1,
    MarriageImg2,
    MarriageImg3,
} from "../../constant";
import { Link } from 'react-router-dom'
import "../../assets/css/about.css";
import ContactSec from "../../components/ContactSec";
import "../../assets/css/shariah-family-council.css";
import FaqSection from "../../components/FaqSection";
import ApplyNow from "../../components/ApplyNow";

const MarriageNetworking = () => {
    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}
            {/* Family starts here */}
            <section className="about-mission">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-mission-content-wrapper mb-4">
                                <h6 className="small-heading">Manchester Islamic Centre</h6>
                                <h2>Marriage Networking</h2>
                                <p>
                                    Habitant morbi tristique senectus et. Nullam vehicula ipsum a
                                    arcu cursus. Aliquam purus sit amet luctus venenatis lectus
                                    magna fringilla urna. Malesuada pellentesque elit eget gravida
                                    cum sociis natoque. Faucibus ornare suspendisse sed nisi
                                    lacus. Consectetur adipiscing elit duis tristique sollicitudin
                                    nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
                                    egestas sed tempus urna et pharetra pharetra massa. Enim ut
                                    tellus elementum sagittis vitae et leo duis ut. ntesque elit
                                    eget gravida cum sociis natoque. Faucibus ornare suspendisse
                                    sed nisi lacus. Consectetur adipiscing
                                </p>
                                <p>
                                    Habitant morbi tristique senectus et. Nullam vehicula ipsum a arcu cursus. Aliquam purus sit amet luctus venenatis lectus magna fringilla urna. Malesuada pellentesque elit eget gravida cum sociis natoque. Faucibus ornare suspendisse sed nisi lacus. Consectetur adipiscing elit duis tristique sollicitudin nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis egestas sed tempus urna
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-img-wrapper">
                                <figure>
                                    <img src={MarriageImg1} className="img-fluid" alt="" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Family End here */}
            {/* Apply Now starts here */}
            <ApplyNow
                firstheading="Manchester Islamic Centre"
                secondheading="Marriage Networking"
                paragraph="Habitant morbi tristique senectus et. Nullam vehicula ipsum a arcu cursus. Aliquam purus sit amet luctus venenatis lectus magna fringilla urna. Malesuada pellentesque"
                img={MarriageImg2}
                redirecturl="/matrimonial-profiles-form"
            />
            {/* Apply Now End here */}
            {/* Family Counceling starts here */}
            <section className="about-mission">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-img-wrapper">
                                <figure>
                                    <img src={MarriageImg3} className="img-fluid" alt="" />
                                </figure>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-mission-content-wrapper mb-4">
                                <h6 className="small-heading">Manchester Islamic Centre</h6>
                                <h2>Marriage Networking</h2>
                                <p>
                                    Habitant morbi tristique senectus et. Nullam vehicula ipsum a
                                    arcu cursus. Aliquam purus sit amet luctus venenatis lectus
                                    magna fringilla urna. Malesuada pellentesque elit eget gravida
                                    cum sociis natoque. Faucibus ornare suspendisse sed nisi
                                    lacus. Consectetur adipiscing elit duis tristique sollicitudin
                                    nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
                                    egestas sed tempus urna et pharetra pharetra massa. Enim ut
                                    tellus elementum sagittis vitae et leo duis ut. ntesque elit
                                    eget gravida cum sociis natoque. Faucibus ornare suspendisse
                                    sed nisi lacus. Consectetur adipiscing
                                </p>
                                <p>
                                    Habitant morbi tristique senectus et. Nullam vehicula ipsum a
                                    arcu cursus. Aliquam purus sit amet luctus venenatis lectus
                                    magna fringilla urna. Malesuada pellentesque elit eget gravida
                                    cum sociis natoque. Faucibus ornare suspendisse sed nisi
                                    lacus. Consectetur adipiscing elit duis tristique sollicitudin
                                    nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
                                    egestas sed tempus urna
                                </p>
                                {/* <Link to="/venue-booking" className="btn">
                                    <span className="icon">
                                        <img src={documentimg} alt="" />
                                    </span>
                                    <span>Application Form</span>
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Family Counceling End here */}
            {/* Faq starts here */}
            <FaqSection />
            {/* Faq End here */}
            <ContactSec />
            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

export default MarriageNetworking;