import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactSec from "../../components/ContactSec";
import FormHeadings from "../../components/FormHeadings";
import { Link } from "react-router-dom";
import { ClipIcon } from "../../constant";
import { useNavigate } from "react-router-dom";
const NikkahApplicationForm = () => {
	const navigate = useNavigate();
	const handleSubmit = (event) => {
		event.preventDefault();
		// perform form submission logic here
		navigate("/nikkah-witness-1-details"); // navigate to the success page
	};
	return (
		<>
			<Header />
			<section className="custom-forms">
				<div className="container">
					<FormHeadings
						SmallHeading="Manchester Islamic Centre"
						MainHeading="Nikah Application Form"
						Text="Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas "
					/>
					<div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
						<div className="custom-form-start">
							<h6 className="form-inner-heading mb-4">
								Nikkah Application Form
							</h6>
							<form onSubmit={handleSubmit}>
								<div className="row">
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="Nikahdate">
												Please provide the Nikah date *
											</label>
											<input
												type="text"
												name="Nikahdate"
												id="Nikahdate"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="Time">Time</label>
											<input
												type="text"
												name="Time"
												id="Time"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="AmountanddetailsofDowry">
												Amount and details of Dowry (Mahr) agreed upon *
											</label>
											<input
												type="text"
												name="AmountanddetailsofDowry"
												id="AmountanddetailsofDowry"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-6 me-md-4">
										<div className="form-group mb-3">
											<label htmlFor="marriageconditions">
												Any marriage conditions
											</label>
											<input
												type="text"
												name="marriageconditions"
												id="marriageconditions"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-6 me-md-4">
										<div className="form-group mb-3">
											<label htmlFor="venueforNikahsermon">
												Please select venue for Nikah sermon *
											</label>
											{/* <input
                        type="text"
                        name="venueforNikahsermon"
                        id="venueforNikahsermon"
                        className="form-control"
                        placeholder="Your answer"
                      /> */}
											<label htmlFor="venueforNikahsermon">
												<i> Mark only one oval</i>
											</label>
											<div className="form-check">
												<input
													className="form-check-input"
													type="radio"
													value=""
													id="flexRadioDefault1"
													name="flexRadioDefault"
													checked
												/>
												<label
													className="form-check-label"
													for="flexRadioDefault1"
												>
													Didsbury Mosque
												</label>
											</div>
											<div className="form-check">
												<input
													className="form-check-input"
													type="radio"
													value=""
													id="flexRadioDefault2"
													name="flexRadioDefault"
												/>
												<label
													className="form-check-label"
													for="flexRadioDefault2"
												>
													Other (Additional £100 will be charged)
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="fulladdressincludingpostcode">
												If Other, please give the full address including
												postcode for the venue.
											</label>
											<input
												type="text"
												name="fulladdressincludingpostcode"
												id="fulladdressincludingpostcode"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									{/* <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="fulladdressincludingpostcode1">If Other, please give the full address including postcode for the venue.</label>
                                            <input type="text" name='fulladdressincludingpostcode1' id='fulladdressincludingpostcode1' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div> */}
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">
												Have you had a Civil Registration of Marriage?
											</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													yes
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													No
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">
												if Yes, Please give the date of the Civil Marriage
											</label>
											<input
												type="text"
												name="NameRegisterOffice"
												id="NameRegisterOffice"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="NameRegisterOffice">
												Name of Register Office
											</label>
											<input
												type="text"
												name="NameRegisterOffice"
												id="NameRegisterOffice"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="Marriagereferencenumber">
												Civil Marriage reference number
											</label>
											<input
												type="text"
												name="Marriagereferencenumber"
												id="Marriagereferencenumber"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3 pt-3">
											<label htmlFor="">Please Upload Your Photo ID Here</label>
											<div className="file-uploader-custom">
												<label htmlFor="UploadFile" className="fileuploader">
													<input
														type="file"
														name="UploadFile"
														hidden
														id="UploadFile"
													/>
													<span>
														Drag & Drop .PDF Files here or click to upload
													</span>
												</label>
												<div className="col-md-6 px-0">
													<div className="files-list">
														<div className="single-file">
															<div className="icon-text">
																<img src={ClipIcon} alt="" />
																<span>Attachment 1 .pdf</span>
															</div>
															<span className="removefile">Remove</span>
														</div>
														<div className="single-file">
															<div className="icon-text">
																<img src={ClipIcon} alt="" />
																<span>Attachment 1 .pdf</span>
															</div>
															<span className="removefile">Remove</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-12 mt-2">
										<div className="form-group d-flex gap-3">
											<Link
												to="/nikkah-wali`-form"
												className="btn ms-auto px-5"
											>
												Back
											</Link>
											<button type="submit" className="btn px-5">
												Next
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			<ContactSec />
			<Footer />
		</>
	);
};
export default NikkahApplicationForm;
