import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactSec from "../../components/ContactSec";
import FormHeadings from "../../components/FormHeadings";
import { Link } from "react-router-dom";
import { ClipIcon } from "../../constant";
import { useNavigate } from "react-router-dom";
const WillParentDetails = () => {
	const navigate = useNavigate();
	const handleSubmit = (event) => {
		event.preventDefault();
		// perform form submission logic here
		navigate("/will-assets-details"); // navigate to the success page
	};
	return (
		<>
			<Header />
			<section className="custom-forms">
				<div className="container">
					<FormHeadings
						SmallHeading="Manchester Islamic Centre"
						MainHeading="Islamic Will Form"
						Text="Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas "
					/>
					<div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
						<div className="custom-form-start">
							<h6 className="form-inner-heading mt-1 mb-3">
								Details of Parents
							</h6>
							<form onSubmit={handleSubmit}>
								<div className="row">
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="FatherName">Father's Full Name</label>
											<input
												type="text"
												name="FatherName"
												id="FatherName"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="FatherDateOfBirth">
												Father's Date Of Birth{" "}
												<small>(Example 7 Oct 2022) </small>
											</label>
											<input
												type="text"
												name="FatherDateOfBirth"
												id="FatherDateOfBirth"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="IfYourFatherDeath">
												If Your Father Is Deceased, Please Indicate The Date Of
												Death
											</label>
											<input
												type="text"
												name="IfYourFatherDeath"
												id="IfYourFatherDeath"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="MotherFullName">Mother's Full Name</label>
											<input
												type="text"
												name="MotherFullName"
												id="MotherFullName"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="MotherDateOfBirth">
												Mother's Date Of Birth{" "}
												<small>(Example 7 Oct 2022) </small>
											</label>
											<input
												type="text"
												name="MotherDateOfBirth"
												id="MotherDateOfBirth"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="IndicateDateOfDeath">
												If Your Mother Is Deceased, Please Indicate The Date Of
												Death <small>(Example 7 Oct 2022) </small>
											</label>
											<input
												type="text"
												name="IndicateDateOfDeath"
												id="IndicateDateOfDeath"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12 mt-2">
										<div className="form-group d-flex gap-3">
											<Link
												to="/will-children-details"
												className="btn ms-auto px-5"
											>
												Back
											</Link>
											<button type="submit" className="btn px-5">
												Next
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			<ContactSec />
			<Footer />
		</>
	);
};
export default WillParentDetails;
