import React from "react";
import { Link } from "react-router-dom";
import {
  BannerIcon,
  BannerSlider1,
  PrayerIcon,
  PrayerMain,
  ProgramIcon,
  Banner2Img,
  Banner3Img,
  Banner4Img,
  Banner5Img,
  Banner6Img,
  Banner7Img,
  Banner8Img,
  Banner9Img,
  Banner10Img,
  Banner11Img,
} from "../../constant";

const bannerContent = [
  {
    id: 1,
    img: BannerSlider1,
    subTitle: "Manchester Islamic Centre",
    title: "Didsbury Mosque",
    text: "Donec ac odio tempor orci dapibus ultrices in iaculis nunc. Metus vulputate eu scelerisque felis imperdiet proin. Consequat ac felis donec et. Tincidunt nunc pulvinar sapien et ligula ullam",
  },
  {
    id: 2,
    img: Banner2Img,
    subTitle: "Manchester Islamic Centre",
    title: "How To Become Muslim",
    text: "Donec ac odio tempor orci dapibus ultrices in iaculis nunc. Metus vulputate eu scelerisque felis imperdiet proin. Consequat ac felis donec et. Tincidunt nunc pulvinar sapien et ligula ullam",
  },
  {
    id: 3,
    img: Banner3Img,
    subTitle: "Manchester Islamic Centre",
    title: "Didsbury Youth & Youth Conference",
    text: "Donec ac odio tempor orci dapibus ultrices in iaculis nunc. Metus vulputate eu scelerisque felis imperdiet proin. Consequat ac felis donec et. Tincidunt nunc pulvinar sapien et ligula ullam",
  },
  {
    id: 4,
    img: Banner4Img,
    subTitle: "Manchester Islamic Centre",
    title: "Shariah Family Council",
    text: "Donec ac odio tempor orci dapibus ultrices in iaculis nunc. Metus vulputate eu scelerisque felis imperdiet proin. Consequat ac felis donec et. Tincidunt nunc pulvinar sapien et ligula ullam",
  },
  {
    id: 5,
    img: Banner5Img,
    subTitle: "Manchester Islamic Centre",
    title: "Marriage & Marriage Networking",
    text: "Donec ac odio tempor orci dapibus ultrices in iaculis nunc. Metus vulputate eu scelerisque felis imperdiet proin. Consequat ac felis donec et. Tincidunt nunc pulvinar sapien et ligula ullam",
  },
  {
    id: 6,
    img: Banner6Img,
    subTitle: "Manchester Islamic Centre",
    title: "Manchester Islamic Centre Family Strong Covenant Annual Conference",
    text: "Donec ac odio tempor orci dapibus ultrices in iaculis nunc. Metus vulputate eu scelerisque felis imperdiet proin. Consequat ac felis donec et. Tincidunt nunc pulvinar sapien et ligula ullam",
  },
  {
    id: 7,
    img: Banner7Img,
    subTitle: "Manchester Islamic Centre",
    title: "Quran Competition",
    text: "Donec ac odio tempor orci dapibus ultrices in iaculis nunc. Metus vulputate eu scelerisque felis imperdiet proin. Consequat ac felis donec et. Tincidunt nunc pulvinar sapien et ligula ullam",
  },
  {
    id: 8,
    img: Banner8Img,
    subTitle: "Manchester Islamic Centre",
    title: "Quran School",
    text: "Donec ac odio tempor orci dapibus ultrices in iaculis nunc. Metus vulputate eu scelerisque felis imperdiet proin. Consequat ac felis donec et. Tincidunt nunc pulvinar sapien et ligula ullam",
  },
  {
    id: 9,
    img: Banner9Img,
    subTitle: "Manchester Islamic Centre",
    title: "Community Engagement",
    text: "Donec ac odio tempor orci dapibus ultrices in iaculis nunc. Metus vulputate eu scelerisque felis imperdiet proin. Consequat ac felis donec et. Tincidunt nunc pulvinar sapien et ligula ullam",
  },
  {
    id: 10,
    img: Banner10Img,
    subTitle: "Manchester Islamic Centre",
    title: "Janaza (Funeral) & Condolence:",
    text: "Donec ac odio tempor orci dapibus ultrices in iaculis nunc. Metus vulputate eu scelerisque felis imperdiet proin. Consequat ac felis donec et. Tincidunt nunc pulvinar sapien et ligula ullam",
  },
  {
    id: 11,
    img: Banner11Img,
    subTitle: "Manchester Islamic Centre",
    title: "MIC Shop & Facility Booking",
    text: "Donec ac odio tempor orci dapibus ultrices in iaculis nunc. Metus vulputate eu scelerisque felis imperdiet proin. Consequat ac felis donec et. Tincidunt nunc pulvinar sapien et ligula ullam",
  },
];

const Programmes = [
  {
    id: 1,
    text: "Interested In Studying Pharmacy webinar",
    label: "Free",
    route: "/",
  },
  {
    id: 2,
    text: "Interested In Studying Pharmacy webinar",
    label: "Free",
    route: "/",
  },
  {
    id: 3,
    text: "Interested In Studying Pharmacy webinar",
    label: "Free",
    route: "/",
  },
  {
    id: 4,
    text: "Interested In Studying Pharmacy webinar",
    label: "Free",
    route: "/",
  },
  {
    id: 5,
    text: "Interested In Studying Pharmacy webinar",
    label: "Free",
    route: "/",
  },
  {
    id: 6,
    text: "Interested In Studying Pharmacy webinar",
    label: "Free",
    route: "/",
  },
  {
    id: 7,
    text: "Interested In Studying Pharmacy webinar",
    label: "Free",
    route: "/",
  },
];

const prayersTimings = [
  {
    id: 1,
    salah: "Fajr",
    iqamah: "6:00 AM",
    azaan: "5:00 AM",
    name: "١لفجر",
  },
  {
    id: 2,
    salah: "Duhar",
    iqamah: "1:30 AM",
    azaan: "1:30 PM",
    name: "١ظهر",
  },
  {
    id: 3,
    salah: "Asr",
    iqamah: "5:30 AM",
    azaan: "5:30 PM",
    name: "١عصر",
  },
  {
    id: 4,
    salah: "Maghrib",
    iqamah: "8:22 AM",
    azaan: "8:22 PM",
    name: "١لمغرب",
  },
  {
    id: 5,
    salah: "Isha",
    iqamah: "9:37 AM",
    azaan: "9:37 PM",
    name: "العشاء",
  },
];

const Banner = () => {
  return (
    <>
      <section className="banner_sec">
        <div className="banner-sliders">
          <div className="banner-slides">
            <div
              id="carouselExampleCaptions"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                {/* {bannerContent.map((banner) => (
                  <div className={`carousel-item active`}>
                    <div className="slider-image">
                      <img
                        src={banner.img}
                        className="d-block w-100"
                        alt="..."
                      />
                    </div>
                    <div className="carousel-caption d-md-block white">
                      <div className="container">
                        <div className="content-wrapper">
                          <h4 className="subtitle">{banner.subTitle}</h4>
                          <h1 className="title">{banner.title}</h1>
                          <p>{banner.text}</p>
                          <div className="icon">
                            <figure>
                              <img
                                src={BannerIcon}
                                alt="banner-icon"
                                className="img-fluid"
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))} */}
                <div className="carousel-item active">
                  <div className="slider-image">
                    <img
                      src={BannerSlider1}
                      className="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div className="carousel-caption d-md-block white">
                    <div className="container">
                      <div className="content-wrapper">
                        <h4 className="subtitle">Manchester Islamic Centre</h4>
                        <h1 className="title">Didsbury Mosque</h1>
                        <p>
                          Donec ac odio tempor orci dapibus ultrices in iaculis
                          nunc. Metus vulputate eu scelerisque felis imperdiet
                          proin. Consequat ac felis donec et. Tincidunt nunc
                          pulvinar sapien et ligula ullam
                        </p>
                        <div className="icon">
                          <figure>
                            <img
                              src={BannerIcon}
                              alt="banner-icon"
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bottom-copyright">
                    <p>
                      Didsbury Mosque. Painting © 2013 Peter Topping, Paintings
                      from Pictures
                    </p>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="slider-image">
                    <img src={Banner2Img} className="d-block w-100" alt="..." />
                  </div>
                  <div className="carousel-caption d-md-block">
                    <div className="container">
                      <div className="content-wrapper">
                        <h4 className="subtitle">Manchester Islamic Centre</h4>
                        <h1 className="title">How To Become Muslim</h1>
                        <p>
                          Donec ac odio tempor orci dapibus ultrices in iaculis
                          nunc. Metus vulputate eu scelerisque felis imperdiet
                          proin. Consequat ac felis donec et. Tincidunt nunc
                          pulvinar sapien et ligula ullam
                        </p>
                        <div className="icon">
                          <figure>
                            <img
                              src={BannerIcon}
                              alt="banner-icon"
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="slider-image">
                    <img src={Banner3Img} className="d-block w-100" alt="..." />
                  </div>
                  <div className="carousel-caption d-md-block white">
                    <div className="container">
                      <div className="content-wrapper">
                        <h4 className="subtitle">Manchester Islamic Centre</h4>
                        <h1 className="title">
                          Didsbury Youth & <br /> Youth Conference
                        </h1>
                        <p>
                          Donec ac odio tempor orci dapibus ultrices in iaculis
                          nunc. Metus vulputate eu scelerisque felis imperdiet
                          proin. Consequat ac felis donec et. Tincidunt nunc
                          pulvinar sapien et ligula ullam
                        </p>
                        <div className="icon">
                          <figure>
                            <img
                              src={BannerIcon}
                              alt="banner-icon"
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="slider-image">
                    <img src={Banner4Img} className="d-block w-100" alt="..." />
                  </div>
                  <div className="carousel-caption  d-md-block white">
                    <div className="container">
                      <div className="content-wrapper">
                        <h4 className="subtitle">Manchester Islamic Centre</h4>
                        <h1 className="title">
                          Shariah Family <br />
                          Council
                        </h1>
                        <p>
                          Donec ac odio tempor orci dapibus ultrices in iaculis
                          nunc. Metus vulputate eu scelerisque felis imperdiet
                          proin. Consequat ac felis donec et. Tincidunt nunc
                          pulvinar
                        </p>
                        <div className="icon">
                          <figure>
                            <img
                              src={BannerIcon}
                              alt="banner-icon"
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="slider-image">
                    <img src={Banner5Img} className="d-block w-100" alt="..." />
                  </div>
                  <div className="carousel-caption d-md-block white">
                    <div className="container">
                      <div className="content-wrapper">
                        <h4 className="subtitle">Manchester Islamic Centre</h4>
                        <h1 className="title">
                          Marriage & Marriage <br /> Networking
                        </h1>
                        <p>
                          Donec ac odio tempor orci dapibus ultrices in iaculis
                          nunc. Metus vulputate eu scelerisque felis imperdiet
                          proin. Consequat ac felis donec et. Tincidunt nunc
                          pulvinar sapien et ligula ullam
                        </p>
                        <div className="icon">
                          <figure>
                            <img
                              src={BannerIcon}
                              alt="banner-icon"
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="slider-image">
                    <img src={Banner6Img} className="d-block w-100" alt="..." />
                  </div>
                  <div className="carousel-caption  d-md-block white">
                    <div className="container">
                      <div className="content-wrapper">
                        <h4 className="subtitle">Manchester Islamic Centre</h4>
                        <h1 className="title">
                          Family Strong <br />
                          Covenant Annual <br />
                          Conference
                        </h1>
                        <div className="icon">
                          <figure>
                            <img
                              src={BannerIcon}
                              alt="banner-icon"
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="slider-image">
                    <img src={Banner7Img} className="d-block w-100" alt="..." />
                  </div>
                  <div className="carousel-caption  d-md-block white">
                    <div className="container">
                      <div className="content-wrapper">
                        <h4 className="subtitle">Manchester Islamic Centre</h4>
                        <h1 className="title">Quran Competition</h1>
                        <p>
                          Donec ac odio tempor orci dapibus ultrices in iaculis
                          nunc. Metus vulputate eu scelerisque felis imperdiet
                          proin. Consequat ac felis donec et. Tincidunt nunc
                          pulvinar sapien et ligula ullam <br />
                          corper malesuada proin libero. Feugiat in ante metus
                          di
                        </p>
                        <div className="icon">
                          <figure>
                            <img
                              src={BannerIcon}
                              alt="banner-icon"
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="slider-image">
                    <img src={Banner8Img} className="d-block w-100" alt="..." />
                  </div>
                  <div className="carousel-caption  d-md-block white">
                    <div className="container">
                      <div className="content-wrapper">
                        <h4 className="subtitle">Manchester Islamic Centre</h4>
                        <h1 className="title">Quran School</h1>
                        <p>
                          Donec ac odio tempor orci dapibus ultrices in iaculis
                          nunc. Metus vulputate eu scelerisque felis imperdiet
                          proin. Consequat ac felis donec et. Tincidunt nunc
                          pulvinar sapien et ligula ullam
                        </p>
                        <div className="icon">
                          <figure>
                            <img
                              src={BannerIcon}
                              alt="banner-icon"
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="slider-image">
                    <img src={Banner9Img} className="d-block w-100" alt="..." />
                  </div>
                  <div className="carousel-caption d-md-block white">
                    <div className="container">
                      <div className="content-wrapper">
                        <h4 className="subtitle">Manchester Islamic Centre</h4>
                        <h1 className="title">
                          Community <br /> Engagement
                        </h1>
                        <p>
                          Donec ac odio tempor orci dapibus ultrices in iaculis
                          nunc. Metus vulputate eu scelerisque felis imperdiet
                          proin. Consequat ac felis donec et. Tincidunt nunc
                          pulvinar sapien et ligula ullam
                        </p>
                        <div className="icon">
                          <figure>
                            <img
                              src={BannerIcon}
                              alt="banner-icon"
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="slider-image">
                    <img
                      src={Banner10Img}
                      className="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div className="carousel-caption d-md-block white">
                    <div className="container">
                      <div className="content-wrapper">
                        <h4 className="subtitle">Manchester Islamic Centre</h4>
                        <h1 className="title">
                          Janaza (Funeral) <br /> & Condolence:
                        </h1>
                        <p>
                          Donec ac odio tempor orci dapibus ultrices in iaculis
                          nunc. Metus vulputate eu scelerisque felis imperdiet
                          proin. Consequat ac felis donec et. Tincidunt nunc
                          pulvinar sapien et ligula ullam
                        </p>
                        <div className="icon">
                          <figure>
                            <img
                              src={BannerIcon}
                              alt="banner-icon"
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="slider-image">
                    <img
                      src={Banner11Img}
                      className="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div className="carousel-caption  d-md-block white">
                    <div className="container">
                      <div className="content-wrapper">
                        <h4 className="subtitle">Manchester Islamic Centre</h4>
                        <h1 className="title">
                          MIC Shop & <br />
                          Facility Booking
                        </h1>
                        <p>
                          Donec ac odio tempor orci dapibus ultrices in iaculis
                          nunc. Metus vulputate eu scelerisque felis imperdiet
                          proin. Consequat ac felis donec et. Tincidunt nunc
                          pulvinar sapien et ligula ullam
                        </p>
                        <div className="icon">
                          <figure>
                            <img
                              src={BannerIcon}
                              alt="banner-icon"
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
        <div className="prayer-timing col-xxl-7 col-xl-6">
          <div className="topbar">
            <h4>Prayers Timings</h4>
            <figure>
              <img src={PrayerIcon} alt="icon" className="img-fluid" />
            </figure>
          </div>
          <div className="table-progeress-prayer">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="namaz-name"></th>
                    <th className="iqamah">Iqamah</th>
                    <th className="azaan">Azaan</th>
                    <th className="arbi-name"></th>
                  </tr>
                </thead>
                <tbody>
                  {prayersTimings.map((prayers) => (
                    <tr key={prayers.id}>
                      <td>{prayers.salah}</td>
                      <td>{prayers.iqamah}</td>
                      <td>{prayers.azaan}</td>
                      <td>{prayers.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="progress mx-auto" data-value="80">
              <figure>
                <img src={PrayerMain} className="img-fluid" alt="" />
              </figure>
            </div>
          </div>
        </div>
        <div className="banner-programs col-md-3">
          <div className="program-topbar">
            <h5>Programmes</h5>
            <img src={ProgramIcon} alt="" />
          </div>
          <div className="all-programs">
            <div className="programs-list">
              {Programmes.map((program) => (
                <div className="single-program" key={program.id}>
                  <p>{program.text}</p>
                  <Link to={program.route} className="btn py-1 px-4">
                    {program.label}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {/* Banner Sec Start Here */}
      {/* <section className="banner_sec">
				<div className="banner-sliders">
					<div className="banner-slides">
						<div className="slider-image">
							<figure>
								<img
									src={BannerSlider1}
									alt="banner-image"
									className="img-fluid"
								/>
							</figure>
						</div>
						<div className="container">
							<div className="content-wrapper">
								<h4 className="subtitle">Manchester Islamic Centre</h4>
								<h1 className="title">Didsbury Mosque</h1>
								<p>
									Donec ac odio tempor orci dapibus ultrices in iaculis nunc.
									Metus vulputate eu scelerisque felis imperdiet proin.
									Consequat ac felis donec et. Tincidunt nunc pulvinar sapien et
									ligula ullam
								</p>
								<div className="icon">
									<figure>
										<img
											src={BannerIcon}
											alt="banner-icon"
											className="img-fluid"
										/>
									</figure>
								</div>
							</div>
						</div>
						<div className="bottom-copyright">
							<p>
								Didsbury Mosque. Painting © 2013 Peter Topping, Paintings from
								Pictures
							</p>
						</div>
					</div>
				</div>
				<div className="prayer-timing col-xxl-7 col-xl-6">
					<div className="topbar">
						<h4>Prayers Timings</h4>
						<figure>
							<img src={PrayerIcon} alt="icon" className="img-fluid" />
						</figure>
					</div>
					<div className="table-progeress-prayer">
						<div className="table-responsive">
							<table className="table">
								<thead>
									<tr>
										<th className="namaz-name"></th>
										<th className="iqamah">Iqamah</th>
										<th className="azaan">Azaan</th>
										<th className="arbi-name"></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="namaz-name">Fajr</td>
										<td className="namaz-name">6:00 AM</td>
										<td className="namaz-name">5:00 AM</td>
										<td className="namaz-name">١لفجر</td>
									</tr>
									<tr>
										<td className="namaz-name">Duhar</td>
										<td className="namaz-name">1:30 AM</td>
										<td className="namaz-name">1:30 PM</td>
										<td className="namaz-name">١ظهر</td>
									</tr>
									<tr>
										<td className="namaz-name">Asr</td>
										<td className="namaz-name">5:30 AM</td>
										<td className="namaz-name">5:30 PM</td>
										<td className="namaz-name">١عصر</td>
									</tr>
									<tr>
										<td className="namaz-name">Maghrib</td>
										<td className="namaz-name">8:22 AM</td>
										<td className="namaz-name">8:22 PM</td>
										<td className="namaz-name">١لمغرب</td>
									</tr>
									<tr>
										<td className="namaz-name">Isha</td>
										<td className="namaz-name">9:37 AM</td>
										<td className="namaz-name">9:37 PM</td>
										<td className="namaz-name">العشاء</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="progress mx-auto" data-value="80">
							<figure>
								<img src={PrayerMain} className="img-fluid" alt="" />
							</figure>
						</div>
					</div>
				</div>
				<div className="banner-programs col-md-3">
					<div className="program-topbar">
						<h5>Programmes</h5>
						<img src={ProgramIcon} alt="" />
					</div>
					<div className="all-programs">
						<div className="programs-list">
							<div className="single-program">
								<p>Interested In Studying Pharmacy webinar</p>
								<Link to="/" className="btn py-1 px-4">Free</Link>
							</div>
							<div className="single-program">
								<p>Interested In Studying Pharmacy webinar</p>
								<Link to="/" className="btn py-1 px-4">Free</Link>
							</div>
							<div className="single-program">
								<p>Interested In Studying Pharmacy webinar</p>
								<Link to="/" className="btn py-1 px-4">Free</Link>
							</div>
							<div className="single-program">
								<p>Interested In Studying Pharmacy webinar</p>
								<Link to="/" className="btn py-1 px-4">Free</Link>
							</div>
							<div className="single-program">
								<p>Interested In Studying Pharmacy webinar</p>
								<Link to="/" className="btn py-1 px-4">Free</Link>
							</div>
							<div className="single-program">
								<p>Interested In Studying Pharmacy webinar</p>
								<Link to="/" className="btn py-1 px-4">Free</Link>
							</div>
							<div className="single-program">
								<p>Interested In Studying Pharmacy webinar</p>
								<Link to="/" className="btn py-1 px-4">Free</Link>
							</div>
							<div className="single-program">
								<p>Interested In Studying Pharmacy webinar</p>
								<Link to="/" className="btn py-1 px-4">Free</Link>
							</div>
						</div>
					</div>
				</div>
			</section> */}
      {/* Banner Sec End Here */}
    </>
  );
};

export default Banner;
