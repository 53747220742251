import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { ProductImg4, ProductImg6, ProductImg5 } from "../../constant";
import "../../assets/css/ecommerce.css";

const Thankyou = () => {
  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}
      <section className="ecommerce-products pt-5">
        <div className="container">
          <div className="col-xl-11 mx-auto">
            <div className="cart-start">
              <div className="top-headings mb-4 pb-4">
                <div className="col-lg-7 col-md-8 mx-auto text-center">
                  <div className="mic-content-wrapper">
                    <h6 className="small-heading">Manchester Islamic Centre</h6>
                    <h2>Thank You For Shopping</h2>
                    <p className="mb-0">Your order is confirmed</p>
                    <span className="d-block custom-font">
                      Order number #13242154365878
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="thankyou-section">
              <div className="col-xxl-6 col-md-7 mx-auto">
                <div className="order-summary p-2 pb-5">
                  <h5 className="mb-4 pt-4 border-top">Shipment Address</h5>
                  <div className="order-data customuzed">
                    <div className="data">
                      <span className="question">Country:</span>
                      <span className="answer">United States</span>
                    </div>
                    <div className="data">
                      <span className="question">Address:</span>
                      <span className="answer">
                        10515 Fox Ave Fairdale, Kentucky(KY), 40118
                      </span>
                    </div>
                    <div className="data">
                      <span className="question">Phone: </span>
                      <span className="answer">+44-123-456-789</span>
                    </div>
                    <div className=" data">
                      <span className="question">State:</span>
                      <span className="answer">Texas</span>
                    </div>
                  </div>
                  <h5 className="border-bottom pb-2 mt-4 pt-2">
                    Payment Details
                  </h5>
                  <div className="order-data">
                    <div className="data">
                      <span className="question">Total :</span>
                      <span className="answer">£122.00</span>
                    </div>
                    <div className="data">
                      <span className="question">Taxes Applied :</span>
                      <span className="answer">£42.00</span>
                    </div>
                    <div className="data">
                      <span className="question">Donation : </span>
                      <span className="answer">£3.00</span>
                    </div>
                    <div className="data-total data mt-4">
                      <span className="question">Order Total :</span>
                      <span className="answer">£1025.00</span>
                    </div>
                  </div>
                </div>
                <div className="check-out mt-3">
                  <Link
                    to="/products"
                    className="btn d-block w-100 text-center"
                  >
                    Continue Shopping
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

export default Thankyou;
