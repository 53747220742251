import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "../pages/About/About";
import Aitekaf from "../pages/Aitekaf/Aitekaf";
import BusinessMeditation from "../pages/BusinessMeditation/BusinessMeditation";
import ContactUs from "../pages/ContactUs/ContactUs";
import FamilyCounceling from "../pages/FamilyCounceling/FamilyCounceling";
import FamilyMeditation from "../pages/FamilyMeditation/FamilyMeditation";
import Home from "../pages/Home/Home";
import Inheritance from "../pages/Inheritance/Inheritance";
import IslamicWill from "../pages/IslamicWill/IslamicWill";
import Khula from "../pages/Khula/Khula";
import LectureDetails from "../pages/LectureDetails/LectureDetails";
import ShariahFamilyCouncil from "../pages/ShariahFamilyCouncil/ShariahFamilyCouncil";
import Talaq from "../pages/Talaq/Talaq";
import Zakat from "../pages/Zakat/Zakat";
import StudyCircle from "../pages/Aitekaf/StudyCircle";
import Nikkah from "../pages/Nikkah/Nikkah";
import Youth from "../pages/Youth/Youth";
import EidActitvity from "../pages/Activities/EidActitvity";
import EidActitvityPopular from "../pages/Activities/EidActitvityPopular";
import GirlsActitvity from "../pages/Activities/GirlsActitvity";
import KidsActitvity from "../pages/Activities/KidsActitvity";
import VenueBooking from "../pages/Venue/VenueBooking";
import FamilyTrainingCourse from "../pages/FamilyTrainingCourse/FamilyTrainingCourse";
import TeamMembers from "../pages/TeamMembers/TeamMembers";
import UpcomingConfrence from "../pages/Confrence/UpcomingConfrence";
import ConferenceRegister from "../pages/Confrence/ConferenceRegister";
import Janaza from "../pages/Janaza/Janaza";
import NewsDetails from "../pages/News/NewsDetails";
import News from "../pages/News/News";
import DawahActivities from "../pages/Community/DawahActivities";
import HelpActivities from "../pages/Community/HelpActivities";
import CommunityEngagement from "../pages/Community/CommunityEngagement";
import ActivityDetails from "../pages/Community/ActivityDetails";
import HajjGallery from "../pages/HajjPages/HajjGallery";
import QuranGallery from "../pages/QuranPages/QuranGallery";
import MarriageNetworking from "../pages/Nikkah/MarriageNetworking";
import QuranSchool from "../pages/QuranPages/QuranSchool";
import FaceClass from "../pages/Classes/FaceClass";
import OnlineClasses from "../pages/Classes/OnlineClasses";
import SchoolActivity from "../pages/Classes/SchoolActivity";
import Prayers from "../pages/Prayers/Prayers";
import Products from "../pages/Ecommerce/Products";
import ProductDetails from "../pages/Ecommerce/ProductDetails";
import Cart from "../pages/Ecommerce/Cart";
import Checkout from "../pages/Ecommerce/Checkout";
import Thankyou from "../assets/css/Thankyou";
import UpcomingCompetition from "../pages/QuranPages/UpcomingCompetition";
import PreviousCompetition from "../pages/QuranPages/PreviousCompetition";
import CompetitionGallery from "../pages/QuranPages/CompetitionGallery";
// Form Exported Here {Note Forms of all Pages have same name as the page and Form is inside the same page folder}
import BusinessMeditationForm from "../pages/BusinessMeditation/BusinessMeditationForm";
import FamilyMeditationForm from "../pages/FamilyMeditation/FamilyMeditationForm";
import FatwahForm from "../pages/Fatwah/FatwahForm";
import VolunteerForm from "../pages/Volunteer/VolunteerForm";
import FamilyCounsellingForm from "../pages/FamilyCounceling/FamilyCounsellingForm";
import NikkahGroomForm from "../pages/NikkahForm/NikkahGroomDetails";
import NikkahBrideForm from "../pages/NikkahForm/NikkahBrideDetails";
import NikkahWaliDetails from "../pages/NikkahForm/NikkahWaliDetails";
import NikkahApplicationForm from "../pages/NikkahForm/NikkahApplicationForm";
import NikkahWitness1Details from "../pages/NikkahForm/NikkahWitness1Details";
import NikkahWitness2Details from "../pages/NikkahForm/NikkahWitness2Details";
import NikkahAdditionalInfo from "../pages/NikkahForm/NikkahAdditionalInfo";
import IslamicWillForm from "../pages/IslamicWill/IslamicWillForm";
import WillChildrenDetails from "../pages/IslamicWill/WillChildrenDetails";
import WillParentDetails from "../pages/IslamicWill/WillParentDetails";
import WillAssetDetails from "../pages/IslamicWill/WillAssetDetails";
import TalaqHusbandDetails from "../pages/TalaqApplication/TalaqHusbandDetails";
import TalaqWifeDetails from "../pages/TalaqApplication/TalaqWifeDetails";
import TalaqNikkahDetails from "../pages/TalaqApplication/TalaqNikkahDetails";
import TalaqChildrenDetails from "../pages/TalaqApplication/TalaqChildrenDetails";
import TalaqWitness1Details from "../pages/TalaqApplication/TalaqWitness1Details";
import TalaqWitness2Details from "../pages/TalaqApplication/TalaqWitness2Details";
import AppilcantDetailsWifeDivorce from "../pages/WifeDivorce/AppilcantDetailsWifeDivorce";
import HusbandDetailsWifeDivorce from "../pages/WifeDivorce/HusbandDetailsWifeDivorce";
import StatementDetailsWifeDivorce from "../pages/WifeDivorce/StatementDetailsWifeDivorce";
import NikahDetailsWifeDivorce from "../pages/WifeDivorce/NikahDetailsWifeDivorce";
import CivilMarriageWifeDivorce from "../pages/WifeDivorce/CivilMarriageWifeDivorce";
import ChildrenDetailsWifeDivorce from "../pages/WifeDivorce/ChildrenDetailsWifeDivorce";
import EvidenceUploadWifeDivorce from "../pages/WifeDivorce/EvidenceUploadWifeDivorce";
import EvidenceUpload1WifeDivorce from "../pages/WifeDivorce/EvidenceUpload1WifeDivorce";
import DeclarationWifeDivorce from "../pages/WifeDivorce/DeclarationWifeDivorce";
import ApplicantDetailsNikkahProof from "../pages/NikkahProof/ApplicantDetailsNikkahProof";
import WifeDetailsNikkahProof from "../pages/NikkahProof/WifeDetailsNikkahProof";
import SermonDetailsNikkahProof from "../pages/NikkahProof/SermonDetailsNikkahProof";
import Witness1DetailsNikkahProof from "../pages/NikkahProof/Witness1DetailsNikkahProof";
import Witness2DetailsNikkahProof from "../pages/NikkahProof/Witness2DetailsNikkahProof";
import AdditionalInfoNikkahProof from "../pages/NikkahProof/AdditionalInfoNikkahProof";
import InheritanceForm from "../pages/Inheritance/InheritanceForm";
import DeceasedInheritanceForm from "../pages/Inheritance/DeceasedInheritanceForm";
import EnquirForm from "../pages/GeneralEnquiry/EnquirForm";

import ProofOfNikkah from "../pages/ProofOfNikkah/ProofOfNikkah";
import FamilyFatwahRequest from "../pages/FamilyFatwahRequest/FamilyFatwahRequest";
import GeneralEnquiry from "../pages/GeneralEnquiry/GeneralEnquiry";
import HajjUmrah from "../pages/HajjPages/HajjUmrah";
import HajjTrip from "../pages/HajjPages/HajjTrip";
import UmrahTrip from "../pages/HajjPages/UmrahTrip";
import YoungActitvity from "../pages/Activities/YoungActitvity";
import PreviousConference from "../pages/PreviousConference/PreviousConference";
import MatrimonialProfilesForm from "../pages/MatrimonialProfilesForm/MatrimonialProfilesForm";
import Terms from "../pages/policies/Terms";
import Privacy from "../pages/policies/Privacy";
import CourseDetails from "../pages/CourseDetails/CourseDetails";

const PublicRoutes = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/zakat" element={<Zakat />} />
          <Route
            path="/shariah-family-council"
            element={<ShariahFamilyCouncil />}
          />
          <Route path="/aitekaf" element={<Aitekaf />} />
          <Route path="/nikkah" element={<Nikkah />} />
          <Route path="/marriage-networking" element={<MarriageNetworking />} />
          <Route path="/family-counceling" element={<FamilyCounceling />} />
          <Route path="/talaq" element={<Talaq />} />
          <Route path="/khula" element={<Khula />} />
          <Route path="/family-meditation" element={<FamilyMeditation />} />
          <Route path="/business-meditation" element={<BusinessMeditation />} />
          <Route path="/inheritance" element={<Inheritance />} />
          <Route path="/islamic-will" element={<IslamicWill />} />
          <Route path="/lecture-details" element={<LectureDetails />} />
          <Route path="/course-details" element={<CourseDetails />} />
          <Route path="/proof-of-nikkah" element={<ProofOfNikkah />} />
          <Route
            path="/family-fatwah-request"
            element={<FamilyFatwahRequest />}
          />
          <Route path="/general-enquiry" element={<GeneralEnquiry />} />
          <Route path="/enquiry-form" element={<EnquirForm />} />
          <Route path="/hajj-umrah" element={<HajjUmrah />} />
          <Route path="/hajj-trip" element={<HajjTrip />} />
          <Route path="/hajj-gallery" element={<HajjGallery />} />
          <Route path="/umrah-trip" element={<UmrahTrip />} />
          <Route path="/study-circles" element={<StudyCircle />} />
          <Route path="/venue-booking" element={<VenueBooking />} />
          <Route
            path="/family-training-course"
            element={<FamilyTrainingCourse />}
          />
          <Route path="/team-members" element={<TeamMembers />} />
          <Route path="/upcoming-conference" element={<UpcomingConfrence />} />
          <Route path="/conference-register" element={<ConferenceRegister />} />
          <Route path="/janaza" element={<Janaza />} />
          <Route path="/news" element={<News />} />
          <Route path="/news-details" element={<NewsDetails />} />
          <Route path="/dawah-acitivities" element={<DawahActivities />} />
          <Route path="/help-acitivities" element={<HelpActivities />} />
          <Route
            path="/community-engagement"
            element={<CommunityEngagement />}
          />
          <Route path="/activity-details" element={<ActivityDetails />} />
          <Route path="/quran-gallery" element={<QuranGallery />} />
          <Route path="/quran-school" element={<QuranSchool />} />
          <Route path="/face-to-face-class" element={<FaceClass />} />
          <Route path="/online-classes" element={<OnlineClasses />} />
          <Route path="/school-activity" element={<SchoolActivity />} />
          <Route path="/prayers" element={<Prayers />} />
          <Route path="/products" element={<Products />} />
          <Route path="/product-details" element={<ProductDetails />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route
            path="/upcoming-competition"
            element={<UpcomingCompetition />}
          />
          <Route
            path="/previous-competition"
            element={<PreviousCompetition />}
          />
          <Route path="/competition-gallery" element={<CompetitionGallery />} />
          <Route path="/previous-conference" element={<PreviousConference />} />
          {/* Forms Routes */}
          <Route
            path="/business-meditation-form"
            element={<BusinessMeditationForm />}
          />
          <Route
            path="/family-meditation-Form"
            element={<FamilyMeditationForm />}
          />
          <Route path="/fatwah-form" element={<FatwahForm />} />
          <Route path="/volunteer-form" element={<VolunteerForm />} />
          <Route
            path="/family-counseling-form"
            element={<FamilyCounsellingForm />}
          />
          <Route path="/nikkah-groom-form" element={<NikkahGroomForm />} />
          <Route path="/nikkah-bride-form" element={<NikkahBrideForm />} />
          <Route path="/nikkah-wali-form" element={<NikkahWaliDetails />} />
          <Route
            path="/nikkah-application-form"
            element={<NikkahApplicationForm />}
          />
          <Route
            path="/nikkah-witness-1-details"
            element={<NikkahWitness1Details />}
          />
          <Route
            path="/nikkah-witness-2-details"
            element={<NikkahWitness2Details />}
          />
          <Route
            path="/nikkah-additional-info"
            element={<NikkahAdditionalInfo />}
          />
          <Route path="/islamic-will-form" element={<IslamicWillForm />} />
          <Route
            path="/will-children-details"
            element={<WillChildrenDetails />}
          />
          <Route path="/will-parent-details" element={<WillParentDetails />} />
          <Route path="/will-assets-details" element={<WillAssetDetails />} />
          <Route
            path="/talaq-husband-details"
            element={<TalaqHusbandDetails />}
          />
          <Route path="/talaq-wife-details" element={<TalaqWifeDetails />} />
          <Route
            path="/talaq-nikkah-details"
            element={<TalaqNikkahDetails />}
          />
          <Route
            path="/talaq-children-details"
            element={<TalaqChildrenDetails />}
          />
          <Route
            path="/talaq-witness1-details"
            element={<TalaqWitness1Details />}
          />
          <Route
            path="/talaq-witness2-details"
            element={<TalaqWitness2Details />}
          />
          <Route
            path="/wife-divorce-applicant-details"
            element={<AppilcantDetailsWifeDivorce />}
          />
          <Route
            path="/wife-divorce-husband-details"
            element={<HusbandDetailsWifeDivorce />}
          />
          <Route
            path="/wife-divorce-statement"
            element={<StatementDetailsWifeDivorce />}
          />
          <Route
            path="/wife-divorce-nikah-details"
            element={<NikahDetailsWifeDivorce />}
          />
          <Route
            path="/wife-divorce-civil-marriage"
            element={<CivilMarriageWifeDivorce />}
          />
          <Route
            path="/children-details-civil-marriage"
            element={<ChildrenDetailsWifeDivorce />}
          />
          <Route
            path="/evidence-upload-civil-marriage"
            element={<EvidenceUploadWifeDivorce />}
          />
          <Route
            path="/evidence-upload-civil-marriage-1"
            element={<EvidenceUpload1WifeDivorce />}
          />
          <Route
            path="/declaration-civil-marriage"
            element={<DeclarationWifeDivorce />}
          />
          <Route
            path="/nikkah-proof-applicant-details"
            element={<ApplicantDetailsNikkahProof />}
          />
          <Route
            path="/nikkah-proof-wife-details"
            element={<WifeDetailsNikkahProof />}
          />
          <Route
            path="/nikkah-proof-sermon-details"
            element={<SermonDetailsNikkahProof />}
          />
          <Route
            path="/nikkah-proof-witness-details-1"
            element={<Witness1DetailsNikkahProof />}
          />
          <Route
            path="/nikkah-proof-witness-details-2"
            element={<Witness2DetailsNikkahProof />}
          />
          <Route
            path="/nikkah-proof-additional-info"
            element={<AdditionalInfoNikkahProof />}
          />
          <Route path="/inheritance-form" element={<InheritanceForm />} />
          <Route
            path="/matrimonial-profiles-form"
            element={<MatrimonialProfilesForm />}
          />
          <Route
            path="/inhertance-form-deceased"
            element={<DeceasedInheritanceForm />}
          />
          <Route path="/youth" element={<Youth />} />
          <Route path="/eid-actitvity" element={<EidActitvity />} />
          <Route
            path="/eid-actitvity-popular"
            element={<EidActitvityPopular />}
          />
          <Route path="/girls-actitvity" element={<GirlsActitvity />} />
          <Route path="/young-actitvity" element={<YoungActitvity />} />
          <Route path="/kids-actitvity" element={<KidsActitvity />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default PublicRoutes;
