import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactSec from "../../components/ContactSec";
import FormHeadings from "../../components/FormHeadings";
const BusinessMeditationForm = () => {
	return (
		<>
			<Header />
			<section className="custom-forms">
				<div className="container">
					<FormHeadings
						SmallHeading="Manchester Islamic Centre"
						MainHeading="Business Meditation"
						Text=""
					/>
					<div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
						<div className="custom-form-start">
							<form>
								<div className="row">
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="FullName">
												Full Name (who is enquiring) *
											</label>
											<input
												type="text"
												name="FullName"
												id="FullName"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="PhoneNumber">Phone Number *</label>
											<input
												type="text"
												name="PhoneNumber"
												id="PhoneNumber"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="Email">Email *</label>
											<input
												type="text"
												name="Email"
												id="Email"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="BusinessName">Business Name *</label>
											<input
												type="text"
												name="BusinessName"
												id="BusinessName"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="BusinessAddress">
												Business Address (Including Postcode) *
											</label>
											<input
												type="text"
												name="BusinessAddress"
												id="BusinessAddress"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="NameOfIndustry">
												Name Of The Industry *
											</label>
											<input
												type="text"
												name="NameOfIndustry"
												id="NameOfIndustry"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="NumberOfPartners">
												Number Of Partners *
											</label>
											<input
												type="text"
												name="NumberOfPartners"
												id="NumberOfPartners"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="AnnualTurnover">
												Annual Turnover (£)
											</label>
											<input
												type="text"
												name="AnnualTurnover"
												id="AnnualTurnover"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="NatureOfTheDispute">
												Nature Of The Dispute (In as much detail as possible) *
											</label>
											<textarea
												name="NatureOfTheDispute"
												id="NatureOfTheDispute"
												className="form-control lg"
												placeholder="Your answer"
											></textarea>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">
												Preferred Language (Mark only One) *
											</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													One
													<input type="checkbox" checked="checked" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													Two
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													Three
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													Four
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-12 mt-2">
										<div className="form-group">
											{/* <button type="submit" className='btn ms-auto px-5'>Submit</button> */}
											<Link to="/checkout" className="btn ms-auto px-5">
												Submit
											</Link>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			<ContactSec />
			<Footer />
		</>
	);
};
export default BusinessMeditationForm;
