import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactSec from "../../components/ContactSec";
import FormHeadings from "../../components/FormHeadings";
const FamilyMeditationForm = () => {
	return (
		<>
			<Header />
			<section className="custom-forms">
				<div className="container">
					<FormHeadings
						SmallHeading="Manchester Islamic Centre"
						MainHeading="Family Meditation"
						Text=""
					/>
					<div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
						<div className="custom-form-start">
							<p className="mt-2 mb-3">
								This form has been designed for anyone who requires process for
								resolvingdisagreements in which an impartial third party (the
								mediator) helps people in dispute tond a mutually acceptable
								resolution. Please ll in all the sections of this form to enable
								us to process your application asquickly and eciently as
								possible.The fee for this service depends on how complicated the
								matter between the parties. The fee which can be paid via bank
								transfer, cash or debit/credit card.
							</p>
							<form>
								<div className="row">
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="FullName">
												Full Name (who is enquiring) *
											</label>
											<input
												type="text"
												name="FullName"
												id="FullName"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="PhoneNumber">Phone Number *</label>
											<input
												type="text"
												name="PhoneNumber"
												id="PhoneNumber"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="Address">Address*</label>
											<textarea
												name="Address"
												id="Address"
												className="form-control"
												placeholder="Your answer"
											></textarea>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="Email">Email*</label>
											<input
												type="text"
												name="Email"
												id="Email"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="SecondPartyName">
												Second Party's Name *
											</label>
											<input
												type="text"
												name="SecondPartyName"
												id="SecondPartyName"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="SecondPartyPhoneNumber">
												Second Party's Phone Number *
											</label>
											<input
												type="text"
												name="SecondPartyPhoneNumber"
												id="SecondPartyPhoneNumber"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="SecondPartyAddress">
												Second Party's Address *
											</label>
											<textarea
												name="SecondPartyAddress"
												id="SecondPartyAddress"
												className="form-control lg"
												placeholder="Your answer"
											></textarea>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="SecondPartyEmailAddress">
												Second Party's Email Address *
											</label>
											<input
												type="text"
												name="SecondPartyEmailAddress"
												id="SecondPartyEmailAddress"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="RelationshipWithTheSecondParty">
												Relationship With The Second Party *
											</label>
											<input
												type="text"
												name="RelationshipWithTheSecondParty"
												id="RelationshipWithTheSecondParty"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="NatureOfDispute">
												Nature Of Dispute (Put in as much detail as possible)*
											</label>
											<textarea
												name="NatureOfDispute"
												id="NatureOfTheDispute"
												className="form-control lg"
												placeholder="Your answer"
											></textarea>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">
												Preferred Language (Tick all that apply) *
											</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													Arabic
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													English
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-12 mt-2">
										<div className="form-group">
											{/* <button type="submit" className='btn ms-auto px-5'>Submit</button> */}
											<Link to="/checkout" className="btn ms-auto px-5">
												Submit
											</Link>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			<ContactSec />
			<Footer />
		</>
	);
};
export default FamilyMeditationForm;
