
import React from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import ContactSec from '../../components/ContactSec';
import FormHeadings from '../../components/FormHeadings';
import { Link } from 'react-router-dom'
import { VenueImg } from '../../constant';
import { useNavigate } from 'react-router-dom';
import FaqSection from '../../components/FaqSection';
const VenueBooking = () => {
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        // perform form submission logic here
        navigate('/checkout'); // navigate to the success page
    }
    return (
        <>
            <Header />
            <section className="custom-forms venue-booking-forn">
                <div className="container">
                    <FormHeadings SmallHeading="Manchester Islamic Centre" MainHeading="Venue Booking Request" Text=" Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas " />
                    <div className="row">
                        <div className="col-md-7">
                            <div className="custom-form-start mt-0">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group mb-3">
                                                <label htmlFor="FirstName">First Name *</label>
                                                <input type="text" name='FirstName' id='FirstName' className="form-control" placeholder='Your answer' />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group mb-3">
                                                <label htmlFor="LastName">Last Name *</label>
                                                <input type="text" name='LastName' id='LastName' className="form-control" placeholder='Your answer' />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group mb-3">
                                                <label htmlFor="PhoneNumber">Phone Number *</label>
                                                <input type="text" name='PhoneNumber' id='PhoneNumber' className="form-control" placeholder='Your answer' />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group mb-3">
                                                <label htmlFor="EmailAddress">Email Address *</label>
                                                <input type="text" name='EmailAddress' id='EmailAddress' className="form-control" placeholder='Your answer' />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group mb-3">
                                                <label htmlFor="Venue">Venue</label>
                                                <input type="text" name='Venue' id='Venue' className="form-control" placeholder='Your answer' />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-3 me-lg-2">
                                                <label htmlFor="Date">Date</label>
                                                <input type="text" name='Date' id='Date' className="form-control " placeholder='Your answer' />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-3 ms-lg-2">
                                                <label htmlFor="Time">Time</label>
                                                <input type="text" name='Time' id='Time' className="form-control " placeholder='Your answer' />
                                            </div>
                                        </div>

                                        <div className="col-md-12 mt-2">
                                            <div className="form-group d-flex gap-3">
                                                <button type='submit' className='btn px-5 ms-auto'>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <figure className="venue-img">
                                <img src={VenueImg} className='w-100' alt="" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section >
            {/* Faq starts here */}
            <FaqSection />
            {/* Faq End here */}
            <ContactSec />
            <Footer />
        </>
    )
}
export default VenueBooking;