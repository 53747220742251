import React from 'react'

const FormHeadings = (props) => {
    return (
        <>
            <div className={props.containerSize}>
                <div className="top-heading text-center form-headingin">
                    <h6 className="small-heading mb-0">{props.SmallHeading}</h6>
                    <h3 className="mb-0 large-heading">{props.MainHeading}</h3>
                    <p className="mb-0">{props.Text}</p>
                </div>
            </div>
        </>
    )
}
FormHeadings.defaultProps = {
    containerSize: "col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto px-xl-4"
}
export default FormHeadings;