import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { ProductImg1 } from "../../constant";
import "../../assets/css/ecommerce.css";

const Products = () => {
	const prod = [
		{
			id: 1,
		},
		{
			id: 1,
		},
		{
			id: 1,
		},
		{
			id: 1,
		},
		{
			id: 1,
		},
		{
			id: 1,
		},
		{
			id: 1,
		},
		{
			id: 1,
		},
		{
			id: 1,
		},
	];
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}
			<section className="ecommerce-products">
				<div className="container">
					<div className="products-start">
						<div className="top-headings mt-5 mb-4 pb-4">
							<div className="col-lg-7 col-md-8 mx-auto text-center">
								<div className="mic-content-wrapper">
									<h6 className="small-heading">Manchester Islamic Centre</h6>
									<h2>Online Shop</h2>
									<p>
										{" "}
										Diam ut venenatis tellus in metus vulputate eu scelerisque.
										Commodo nulla
									</p>
								</div>
							</div>
						</div>
						<div className="products-data">
							<div className="row">
								<div className="col-md-3">
									<div className="products-side-bar">
										<div className="categories">
											<h5 className="heading">Categories</h5>
											<ul className="categories-list">
												<li className="single-category">
													<Link to="">Category name # 1</Link>
												</li>
												<li className="single-category">
													<Link to="">Category name # 2</Link>
												</li>
												<li className="single-category">
													<Link to="">Category name # 3</Link>
												</li>
												<li className="single-category">
													<Link to="">Category name # 4</Link>
												</li>
												<li className="single-category">
													<Link to="">Category name # 5</Link>
												</li>
												<li className="single-category">
													<Link to="">Category name # 6</Link>
												</li>
											</ul>
										</div>
										<div className="ads">
											<div className="text">
												<h6>Manchester Islamic Centre</h6>
												<h3>Year End Sale</h3>
											</div>
											<div className="link">
												<Link to="" className="btn">
													Shop Now
												</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-9">
									<div className="products-main">
										<div className="products-filters">
											<div className="search">
												<input
													type="text"
													name=""
													id=""
													className="form-control"
													placeholder="Search here"
												/>
											</div>
											<div className="filter">
												<div className="form-group">
													<label htmlFor="SortBy">Sort By</label>
													<select
														name="SortBy"
														id="SortBy"
														className="form-select form-control"
													>
														<option value="Select Here">Select Here</option>
													</select>
												</div>
											</div>
										</div>
										<div className="products-list pt-4">
											<div className="row">
												{prod.map((data, index) => (
													<div className="col-md-4 pb-3" key={index}>
														<div className="single-product">
															<figure>
																<img
																	src={ProductImg1}
																	alt=""
																	className="img-fluid"
																/>
															</figure>
															<div className="text">
																<div className="name-rating">
																	<h5>Product name</h5>
																	<span>
																		<i className="fa fa-star"></i> 4.5
																	</span>
																</div>
																<p>Diam ut venenatis tellus in metus </p>
																<div className="price">
																	<span>£600 </span>
																	<s>£850</s>
																</div>
																<Link
																	to="/product-details"
																	className="cart-btn"
																>
																	Add to cart
																</Link>
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
										<div className="paginations-start col-xl-8 ms-1 mb-4 mt-5">
											<ul className="pagination-list">
												<li>
													<Link className="normal active">01.</Link>
												</li>
												<li>
													<Link className="normal">02.</Link>
												</li>
												<li>
													<Link className="normal">03.</Link>
												</li>
												<li>
													<Link className="normal">Next</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default Products;
