import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { ProductImg3 } from "../../constant";
import "../../assets/css/ecommerce.css";

const Cart = () => {
  const [Review, SetReview] = useState("d-none");
  const [Description, SetDescription] = useState("");
  function ChangeData() {
    if (Description == "") {
      SetDescription("d-none");
      SetReview("");
    } else {
      SetDescription("");
      SetReview("d-none");
    }
  }
  const prod = [
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
    {
      id: 1,
    },
  ];
  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}
      <section className="ecommerce-products">
        <div className="container">
          <div className="col-xl-11 mx-auto">
            <div className="cart-start">
              <div className="top-headings mt-5 mb-4 pb-4">
                <div className="col-lg-7 col-md-8 mx-auto text-center">
                  <div className="mic-content-wrapper">
                    <h6 className="small-heading">Manchester Islamic Centre</h6>
                    <h2>Online Shop</h2>
                    <p>
                      {" "}
                      Diam ut venenatis tellus in metus vulputate eu
                      scelerisque. Commodo nulla
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="cart-area">
              <div className="table-cart">
                <table>
                  <thead>
                    <tr>
                      <th className="first-name">Product</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="product-data">
                          <span className="cross">X</span>
                          <div className="main">
                            <figure>
                              <img src={ProductImg3} alt="" />
                            </figure>
                            <div className="desc">
                              <h6>Product Name </h6>
                              <span>Habitant morbi tristique</span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>£600 </td>
                      <td>
                        <div className="quantity-counter mx-auto">
                          <button>-</button>
                          <span>3</span>
                          <button>+</button>
                        </div>
                      </td>
                      <td>£600 </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="product-data">
                          <span className="cross">X</span>
                          <div className="main">
                            <figure>
                              <img src={ProductImg3} alt="" />
                            </figure>
                            <div className="desc">
                              <h6>Product Name </h6>
                              <span>Habitant morbi tristique</span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>£600 </td>
                      <td>
                        <div className="quantity-counter mx-auto">
                          <button>-</button>
                          <span>3</span>
                          <button>+</button>
                        </div>
                      </td>
                      <td>£600 </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="product-data">
                          <span className="cross">X</span>
                          <div className="main">
                            <figure>
                              <img src={ProductImg3} alt="" />
                            </figure>
                            <div className="desc">
                              <h6>Service Name </h6>
                              <span>Habitant morbi tristique</span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>£600 </td>
                      <td>
                        {/* <div className="quantity-counter mx-auto">
                          <button>-</button>
                          <span>3</span>
                          <button>+</button>
                        </div> */}
                      </td>
                      <td>£600 </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-end mt-5">
                <Link className="btn px-5">Update Cart</Link>
              </div>
              <div className="donation-car">
                <div className="row align-items-center">
                  <div className="col-md-5">
                    <div className="donation-area">
                      <div className="text">
                        <h6 className="small-heading">13.1.11</h6>
                        <h2>Add Donation</h2>
                        <p>www.mohid.net 844-MY-MOHID</p>
                      </div>
                      <div className="donation-amounts">
                        <div className="single-payment">
                          <span>£3</span>
                        </div>
                        <div className="single-payment">
                          <span>£3</span>
                        </div>
                        <div className="single-payment">
                          <span>£3</span>
                        </div>
                        <div className="single-payment">
                          <span>£3</span>
                        </div>
                        <div className="single-payment">
                          <span>£3</span>
                        </div>
                        <div className="single-payment">
                          <span>£3</span>
                        </div>
                        <div className="single-payment input-wi">
                          <input
                            type="text"
                            name=""
                            id=""
                            placeholder="Enter Here"
                          />
                        </div>
                      </div>
                      <Link className="btn px-5 bg-last" to="">
                        Confirm
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-5 ps-lg-5 ms-auto">
                    <div className="order-summary">
                      <h5>Order Summary</h5>
                      <div className="order-data">
                        <div className="data">
                          <span className="question">Total :</span>
                          <span className="answer">£122.00</span>
                        </div>
                        <div className="data">
                          <span className="question">Taxes Applied :</span>
                          <span className="answer">£42.00</span>
                        </div>
                        <div className="data">
                          <span className="question">Donation : </span>
                          <span className="answer">£3.00</span>
                        </div>
                        <div className="data-total data">
                          <span className="question">Order Total :</span>
                          <span className="answer">£1025.00</span>
                        </div>
                        <div className="check-out">
                          <Link
                            to="/checkout"
                            className="btn d-block w-100 text-center"
                          >
                            Checkout
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

export default Cart;
