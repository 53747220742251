import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { aboutmisimg, Aitekafimg, documentimg } from "../../constant";
import "../../assets/css/about.css";
import ContactSec from "../../components/ContactSec";

const Aitekaf = () => {
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}
			{/* About starts here */}
			<section className="about-mission">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="about-mission-content-wrapper mb-4">
								<h6 className="small-heading">Manchester Islamic Centre</h6>
								<h2>About Mic & Didsbury Mosque</h2>
								<p>
									The Manchester Islamic Centre was formerly the Albert Park
									Methodist Church , which opened for worship in 1883, but in
									1962, the church closed down. In 1967, it was bought by some
									members of the Syrian Arab community to be used as a Mosque
									for the Manchester Muslim community. The property consists of
									two large buildings, one is used as a Mosque and library, and
									the other includes a prayer hall for women, social events
									hall, classrooms, a kitchen and an ablution facility.
								</p>
								<a href="#" className="btn">
									<span className="icon">
										<img src={documentimg} alt="" />
									</span>
									<span>Application Form</span>
								</a>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-img-wrapper">
								<figure>
									<img src={Aitekafimg} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* About End here */}
			<ContactSec />
			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default Aitekaf;
