import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { NewsPaperIcon, NewsImg1, NewsImg2 } from "../../constant";
import { Link } from 'react-router-dom'
import ContactSec from "../../components/ContactSec";
const NewsDetails = () => {
    let NewsHeading = "How to Become a muslim";
    let NewsBody =
        "Sed cras ornare arcu dui vivamus arcu felis bibendum ut. Tempus egestas sed sed risus pretium quam vulputate dignissim. Quam pellentesque nec nam aliquam. Tristique senectus et netus et malesuada. Sit amet nisl purus in mollis nunc sed id semper.";
    let NewsDate = "18 MAY 2022";

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}
            {/* News Releases Start Here */}
            <section className="news-relese details-page">
                <div className="container">
                    <div className="col-xxl-11 mx-auto">
                        <div className="news-relese-start">
                            <div className="col-lg-6 mx-auto px-xl-4">
                                <div className="top-heading text-center">
                                    <h6 className="small-heading mb-0">
                                        Manchester Islamic Centre
                                    </h6>
                                    <h2 className="mb-0">
                                        News Releases <img src={NewsPaperIcon} alt="" />
                                    </h2>
                                    <p className="mb-0">
                                        Sed cras ornare arcu dui vivamus arcu felis bibendum ut.
                                        Tempus egestas sed sed risus pretium quam vulputate
                                        dignissim. Quam pellentesque nec nam aliquam
                                    </p>
                                </div>
                            </div>
                            <div className="news-details news-list">
                                <div className="row align-items-end">
                                    <div className="col-md-7">
                                        <div className="single-news">
                                            <h5>How to Become a muslim</h5>
                                            <span className="date">18 MAY 2022</span>
                                            <div className="texts">
                                                <p>Lacus laoreet non curabitur gravida arcu ac. Id velit ut tortor pretium. Urna duis convallis convallis tellus id interdum velit laoreet. Odio facilisis mauris sit amet massa. Est lorem ipsum dolor sit amet consectetur adipiscing. In hendrerit gravida rutrum quisque non tellus orci. Justo donec enim diam vulputate ut. Nisl suscipit adipiscing bibendum est. Sed vulputate odio ut enim blandit volutpat maecenas volutpat. Orci ac auctor augue mauris augue neque. A arcu cursus vitae congue.</p>
                                                <p>Amet purus gravida quis blandit turpis. Gravida quis blandit turpis cursus in hac habitasse platea dictumst. Sit amet consectetur adipiscing elit duis. Laoreet id donec ultrices tincidunt arcu. Convallis a cras semper auctor neque vitae tempus. Hac habitasse platea dictumst quisque sagittis purus. Auctor eu augue ut lectus. Volutpat odio facilisis mauris sit. Nulla facilisi nullam vehicula ipsum a arcu cursus. Enim nulla aliquet porttitor lacus luctus accumsan tortor. Viverra aliquet eget sit amet tellus cras adipiscing.Volutpat consequat mauris nunc congue. Imperdiet massa tincidunt nunc pulvinar. Cras semper auctor neque vitae tempus quam pellentesque. Velit ut tortor pretium viverra suspendisse potenti.</p>
                                                <p className="mt-4">Lacus laoreet non curabitur gravida arcu ac. Id velit ut tortor pretium. Urna duis convallis convallis tellus id interdum velit laoreet. Odio facilisis mauris sit amet massa. Est lorem ipsum dolor sit amet consectetur adipiscing. In hendrerit gravida rutrum quisque non tellus orci. Justo donec enim diam vulputate ut. Nisl suscipit adipiscing bibendum est. Sed vulputate odio ut enim blandit volutpat maecenas volutpat. Orci ac auctor augue mauris augue neque. A arcu cursus vitae congue.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <figure>
                                            <img src={NewsImg1} className="img-fluid w-100" alt="" />
                                        </figure>
                                    </div>
                                </div>
                                <div className="row alegn-items-center mt-5">
                                    <div className="col-md-5 mt-3">
                                        <figure>
                                            <img src={NewsImg2} className="img-fluid w-100" alt="" />
                                        </figure>
                                    </div>
                                    <div className="col-md-7 mt-3">
                                        <div className="text">
                                            <p>Lacus laoreet non curabitur gravida arcu ac. Id velit ut tortor pretium. Urna duis convallis convallis tellus id interdum velit lao reet. Odio facilisis mauris sit amet massa. Est lorem ipsum dolor sit amet consectetur adipiscing. In hendrerit gravida rutrum quisque non tellus orci. Justo donec enim diam vulputate ut. Nisl suscipit adipiscing bibendum est. Sed vulputate odio ut enim blandit volutpat maecenas volutpat. Orci ac auctor augue mauris augue neque. A arcu cursus vitae congue.</p>
                                            <p>Amet purus gravida quis blandit turpis. Gravida quis blandit turpis cursus in hac habitasse platea dictumst. Sit amet cons ectetur adipiscing elit duis. Laoreet id donec ultrices tincidunt arcu. Convallis a cras semper auctor neque vitae tempus. Hac habitasse platea dictumst quisque sagittis purus. Auctor eu augue ut lectus. Volutpat odio facilisis mauris sit. Nulla facilisi nullam vehicula ipsum a arcu cursus. Enim nulla aliquet porttitor lacus luctus accumsan tortor. Viverra aliquet eget sit amet tellus cras adipiscing.Volutpat consequat mauris nunc congue. Imperdiet massa tincidunt nunc pulvinar. Cras semper auctor neque vitae tempus quam pellentesque. Velit ut tortor pretium viverra suspendisse potenti. Diam phasellus vestibulum lorem</p>
                                            <p className="mt-5">Lacus laoreet non curabitur gravida arcu ac. Id velit ut tortor pretium. Urna duis convallis convallis tellus id interdum velit lao reet. Odio facilisis mauris sit amet massa. Est lorem ipsum dolor sit amet consectetur adipiscing. In hendrerit gravida rutrum quisque non tellus orci. Justo donec enim diam vulputate ut. Nisl suscipit adipiscing bibendum</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* News Releases End Here */}
            <ContactSec />
            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

export default NewsDetails;