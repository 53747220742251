import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { HelpImg1, HelpImg2, HelpImg3, HelpImg4 } from "../../constant";
import ContactSec from "../../components/ContactSec";
import "../../assets/css/activities.css";
import FaqSection from "../../components/FaqSection";
import ApplyNow from "../../components/ApplyNow";

const HelpActivities = () => {
	let Activityname = "Activity # 1";
	let ActivityText =
		"Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi ";

	const Members = [
		{
			name: Activityname,
			text: ActivityText,
			Img: HelpImg1,
		},
		{
			name: Activityname,
			text: ActivityText,
			Img: HelpImg2,
		},
		{
			name: Activityname,
			text: ActivityText,
			Img: HelpImg3,
		},
		{
			name: Activityname,
			text: ActivityText,
			Img: HelpImg4,
		},
		{
			name: Activityname,
			text: ActivityText,
			Img: HelpImg1,
		},
		{
			name: Activityname,
			text: ActivityText,
			Img: HelpImg2,
		},
		{
			name: Activityname,
			text: ActivityText,
			Img: HelpImg3,
		},
		{
			name: Activityname,
			text: ActivityText,
			Img: HelpImg4,
		},
		{
			name: Activityname,
			text: ActivityText,
			Img: HelpImg1,
		},
		{
			name: Activityname,
			text: ActivityText,
			Img: HelpImg3,
		},
		{
			name: Activityname,
			text: ActivityText,
			Img: HelpImg3,
		},
		{
			name: Activityname,
			text: ActivityText,
			Img: HelpImg4,
		},
		{
			name: Activityname,
			text: ActivityText,
			Img: HelpImg1,
		},
		{
			name: Activityname,
			text: ActivityText,
			Img: HelpImg3,
		},
		{
			name: Activityname,
			text: ActivityText,
			Img: HelpImg3,
		},
		{
			name: Activityname,
			text: ActivityText,
			Img: HelpImg4,
		},
	];
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}
			<section className="team-members">
				<div className="container">
					<div className="tean-merbers-start">
						<div className="col-lg-7 mx-auto px-xl-4">
							<div className="top-heading text-center">
								<h6 className="small-heading mb-0">
									Manchester Islamic Centre
								</h6>
								<h2 className="mb-0">Community Help Activities</h2>
								<p className="mb-0">
									Sed cras ornare arcu dui vivamus arcu felis bibendum ut.
									Tempus egestas sed sed risus pretium quam vulputate dignissim.
									Quam pellentesque nec nam aliquam
								</p>
							</div>
						</div>
						<div className="activites-list">
							<div className="row">
								{Members.map((data, index) => (
									<div className="col-md-3 mt-5" key={index}>
										<div className="single-activity">
											<figure>
												<img src={data.Img} className="w-100" alt="" />
											</figure>
											<div className="member-data">
												<h4>{data.name}</h4>
												<p>{data.text}</p>
												<Link
													className="colored-btn mt-3"
													to="/activity-details"
												>
													Learn More
												</Link>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
						<div className="paginations-start mt-5">
							<ul className="pagination-list">
								<li>
									<Link className="normal active">01.</Link>
								</li>
								<li>
									<Link className="normal">02.</Link>
								</li>
								<li>
									<Link className="normal">03.</Link>
								</li>
								<li>
									<Link className="normal">Next</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
			<ContactSec />
			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default HelpActivities;
