import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { NewsPaperIcon } from "../../constant";
import { Link } from "react-router-dom";
import ContactSec from "../../components/ContactSec";
const News = () => {
  let NewsHeading = "How to Become a muslim";
  let NewsBody =
    "Sed cras ornare arcu dui vivamus arcu felis bibendum ut. Tempus egestas sed sed risus pretium quam vulputate dignissim. Quam pellentesque nec nam aliquam. Tristique senectus et netus et malesuada. Sit amet nisl purus in mollis nunc sed id semper.";
  let NewsDate = "18 MAY 2022";
  const NewsData = [
    {
      NewsHeading: NewsHeading,
      NewsBody: NewsBody,
      NewsDate: NewsDate,
    },
    {
      NewsHeading: NewsHeading,
      NewsBody: NewsBody,
      NewsDate: NewsDate,
    },
    {
      NewsHeading: NewsHeading,
      NewsBody: NewsBody,
      NewsDate: NewsDate,
    },
    {
      NewsHeading: NewsHeading,
      NewsBody: NewsBody,
      NewsDate: NewsDate,
    },
    {
      NewsHeading: NewsHeading,
      NewsBody: NewsBody,
      NewsDate: NewsDate,
    },
  ];
  return (
    <>
      {/* Header Start Here */}
      <Header />
      {/* Header End Here */}
      {/* News Releases Start Here */}
      <section className="news-relese">
        <div className="container position-relative">
          <div className="col-xxl-11 mx-auto">
            <div className="news-relese-start">
              <div className="col-lg-6 mx-auto px-xl-4">
                <div className="top-heading text-center">
                  <h6 className="small-heading mb-0">
                    Manchester Islamic Centre
                  </h6>
                  <h2 className="mb-0">
                    News Releases <img src={NewsPaperIcon} alt="" />
                  </h2>
                  <p className="mb-0">
                    Sed cras ornare arcu dui vivamus arcu felis bibendum ut.
                    Tempus egestas sed sed risus pretium quam vulputate
                    dignissim. Quam pellentesque nec nam aliquam
                  </p>
                </div>
              </div>
              <div className="news-list">
                <div className="filter-the-new">
                  <div className="form-group">
                    <label htmlFor="SortBy">Sort By</label>
                    <select
                      name="SortBy"
                      id="SortBy"
                      className="form-select form-control"
                    >
                      <option value="New">New</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  {NewsData.map((data, index) => (
                    <div className="col-xl-8  mx-auto mt-4 pt-2" key={index}>
                      <div className="single-news px-3 pb-4">
                        <h3 className="line-clamp-1">{data.NewsHeading}</h3>
                        <span className="date">{data.NewsDate}</span>
                        <p className="pe-lg-4 line-clamp-4">{data.NewsBody} </p>
                        <Link className="colored-btn" to="/news-details">
                          Read More..
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="paginations-start col-xl-8 mx-auto mt-5">
                <ul className="pagination-list">
                  <li>
                    <Link className="normal active">01.</Link>
                  </li>
                  <li>
                    <Link className="normal">02.</Link>
                  </li>
                  <li>
                    <Link className="normal">03.</Link>
                  </li>
                  <li>
                    <Link className="normal">Next</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* News Releases End Here */}
      <ContactSec />
      {/* Footer Start Here */}
      <Footer />
      {/* Footer End Here */}
    </>
  );
};

export default News;
