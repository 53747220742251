import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FaqSection = () => {
	return (
		<>
			{/* Faq starts here */}
			<section className="faq-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="faq-heading-wrapper">
								<h6 className="small-heading">Manchester Islamic Centre</h6>
								<h2>Frequently Asked Questions</h2>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="faq-wrapper mt-4">
								<Accordion eventKey="0">
									<Accordion.Item eventKey="0">
										<Accordion.Header>
											Vitae congue eu consequat ac felis donec. Platea dictumst
											quisque sagittis purus sit amet
										</Accordion.Header>
										<Accordion.Body>
											itae congue eu consequat ac felis donec. Platea dictumst
											quisque sagittis purus sit amet. Adipiscing tristique
											risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer
											vitae justo eget magna. Tempus iacmst quisque sagittis
											purus sit amet. Adipiscing tristique risus nec feugiat in
											fermentum. Ut fauci.Elementum sagittis vitae et leo duis
											ut. Euismod quis viverra ni bh cras pulvinar mattis nunc
											sed. Mattis vulputate enim nulla aliquet porttitor lacus.
											Vitae nunc sed velit dignissim soda les ut eu. Purus non
											enim praesent elementum facilisis leo. Elementum facilisis
											leo vel fringilla est. Elementum sagittis vitae et leo
											duis ut diam. Auctor neque vitae tempus quam pellentesque
											nec nam. Arcu risus quis varius
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="1">
										<Accordion.Header>
											Vitae congue eu consequat ac felis donec. Platea dictumst
											quisque sagittis purus sit amet
										</Accordion.Header>
										<Accordion.Body>
											itae congue eu consequat ac felis donec. Platea dictumst
											quisque sagittis purus sit amet. Adipiscing tristique
											risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer
											vitae justo eget magna. Tempus iacmst quisque sagittis
											purus sit amet. Adipiscing tristique risus nec feugiat in
											fermentum. Ut fauci.Elementum sagittis vitae et leo duis
											ut. Euismod quis viverra ni bh cras pulvinar mattis nunc
											sed. Mattis vulputate enim nulla aliquet porttitor lacus.
											Vitae nunc sed velit dignissim soda les ut eu. Purus non
											enim praesent elementum facilisis leo. Elementum facilisis
											leo vel fringilla est. Elementum sagittis vitae et leo
											duis ut diam. Auctor neque vitae tempus quam pellentesque
											nec nam. Arcu risus quis varius
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="2">
										<Accordion.Header>
											Vitae congue eu consequat ac felis donec. Platea dictumst
											quisque sagittis purus sit amet
										</Accordion.Header>
										<Accordion.Body>
											itae congue eu consequat ac felis donec. Platea dictumst
											quisque sagittis purus sit amet. Adipiscing tristique
											risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer
											vitae justo eget magna. Tempus iacmst quisque sagittis
											purus sit amet. Adipiscing tristique risus nec feugiat in
											fermentum. Ut fauci.Elementum sagittis vitae et leo duis
											ut. Euismod quis viverra ni bh cras pulvinar mattis nunc
											sed. Mattis vulputate enim nulla aliquet porttitor lacus.
											Vitae nunc sed velit dignissim soda les ut eu. Purus non
											enim praesent elementum facilisis leo. Elementum facilisis
											leo vel fringilla est. Elementum sagittis vitae et leo
											duis ut diam. Auctor neque vitae tempus quam pellentesque
											nec nam. Arcu risus quis varius
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item defaultActiveKey="3">
										<Accordion.Header>
											Vitae congue eu consequat ac felis donec. Platea dictumst
											quisque sagittis purus sit amet
										</Accordion.Header>
										<Accordion.Body>
											itae congue eu consequat ac felis donec. Platea dictumst
											quisque sagittis purus sit amet. Adipiscing tristique
											risus nec feugiat in fermentum. Ut fauci.Ut eu sem integer
											vitae justo eget magna. Tempus iacmst quisque sagittis
											purus sit amet. Adipiscing tristique risus nec feugiat in
											fermentum. Ut fauci.Elementum sagittis vitae et leo duis
											ut. Euismod quis viverra ni bh cras pulvinar mattis nunc
											sed. Mattis vulputate enim nulla aliquet porttitor lacus.
											Vitae nunc sed velit dignissim soda les ut eu. Purus non
											enim praesent elementum facilisis leo. Elementum facilisis
											leo vel fringilla est. Elementum sagittis vitae et leo
											duis ut diam. Auctor neque vitae tempus quam pellentesque
											nec nam. Arcu risus quis varius
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
				</div>
			</section>
			{/* Faq End here */}
		</>
	);
};

export default FaqSection;
