import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactSec from "../../components/ContactSec";
import {
	aboutmisimg,
	historyimg,
	micvideo,
	trusti1,
	trusti2,
	trusti3,
	trusti4,
	zakat1img,
	zakat2img,
} from "../../constant";
import "../../assets/css/about.css";
import { Link } from "react-router-dom";

const Zakat = () => {
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}
			{/* About starts here */}
			<section className="about-mission">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="about-mission-content-wrapper mb-4">
								<h6 className="small-heading">Manchester Islamic Centre</h6>
								<h2>Zakat</h2>
								<p>
									Habitant morbi tristique senectus et. Nullam vehicula ipsum a
									arcu cursus. Aliquam purus sit amet luctus venenatis lectus
									magna fringilla urna. Malesuada pellentesque elit eget gravida
									cum sociis natoque. Faucibus ornare suspendisse sed nisi
									lacus. Consectetur adipiscing elit duis tristique sollicitudin
									nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
									egestas sed tempus urna et pharetra pharetra massa. Enim ut
									tellus elementum sagittis vitae et leo duis ut. ntesque elit
									eget gravida cum sociis natoque. Faucibus ornare suspendisse
									sed nisi lacus. Consectetur adipiscing
								</p>
								<p>
									t duis tristique sollicitudin nibh sit amet. Non curabitur
									gravida arcu ac tortor. Ac turpis egestas sed tempus urna et
									pharetra pharetra massa. Enim ut tellus elem
								</p>
								<Link className="btn" to="">
									Pay Now
								</Link>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-img-wrapper">
								<figure>
									<img src={zakat1img} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* About End here */}
			{/* Our polices starts here */}
			<section className="our-policies">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="policies-content-wrapper">
								<h6>Manchester Islamic Centre</h6>
								<h2>Zakat</h2>
								<p>
									Habitant morbi tristique senectus et. Nullam vehicula ipsum a
									arcu cursus. Aliquam purus sit amet luctus venenatis lectus
									magna fringilla urna. Malesuada pellentesque elit eget gravida
									cum sociis natoque. Faucibus ornare suspendisse sed nisi
									lacus. Consectetur adipiscing elit duis tristique
								</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="policies-list-wrapper">
								<ul>
									<li>
										<i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
										<span>
											Habitant morbi tristique senectus et. Nullam vehicula
											ipsum a arcu cursus.{" "}
										</span>
									</li>
									<li>
										<i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
										<span>
											Habitant morbi tristique senectus et. Nullam vehicula
											ipsum a arcu cursus.{" "}
										</span>
									</li>
									<li>
										<i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
										<span>
											Habitant morbi tristique senectus et. Nullam vehicula
											ipsum a arcu cursus.{" "}
										</span>
									</li>
									<li>
										<i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
										<span>
											Habitant morbi tristique senectus et. Nullam vehicula
											ipsum a arcu cursus.{" "}
										</span>
									</li>
									<li>
										<i className="fa-solid fa-circle-check"></i>{" "}
										<span>
											Habitant morbi tristique senectus et. Nullam vehicula
											ipsum a arcu cursus.{" "}
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Our polices End here */}
			{/* History  starts here */}
			<section className="History-mission">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="about-img-wrapper">
								<figure>
									<img src={zakat2img} className="img-fluid" alt="" />
								</figure>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-mission-content-wrapper mb-4">
								<h6 className="small-heading">Manchester Islamic Centre</h6>
								<h2>Zakat</h2>
								<p>
									Habitant morbi tristique senectus et. Nullam vehicula ipsum a
									arcu cursus. Aliquam purus sit amet luctus venenatis lectus
									magna fringilla urna. Malesuada pellentesque elit eget gravida
									cum sociis natoque. Faucibus ornare suspendisse sed nisi
									lacus. Consectetur adipiscing elit duis tristique sollicitudin
									nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
									egestas sed tempus urna et pharetra pharetra massa. Enim ut
									tellus elementum sagittis vitae et leo duis ut. ntesque elit
									eget gravida cum sociis natoque. Faucibus ornare suspendisse
									sed nisi lacus. Consectetur adipiscing
								</p>
								<p>
									t duis tristique sollicitudin nibh sit amet. Non curabitur
									gravida arcu ac tortor. Ac turpis egestas sed tempus urna et
									pharetra pharetra massa. Enim ut tellus elem
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* History  End here */}
			<ContactSec />
			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default Zakat;
