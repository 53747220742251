import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactSec from "../../components/ContactSec";
import FormHeadings from "../../components/FormHeadings";
import { Link } from "react-router-dom";
import { ClipIcon } from "../../constant";
import { useNavigate } from "react-router-dom";
const NikkahBrideForm = () => {
	const navigate = useNavigate();
	const handleSubmit = (event) => {
		event.preventDefault();
		// perform form submission logic here
		navigate("/nikkah-wali-form"); // navigate to the success page
	};
	return (
		<>
			<Header />
			<section className="custom-forms">
				<div className="container">
					<FormHeadings
						SmallHeading="Manchester Islamic Centre"
						MainHeading="Nikah Application Form"
						Text="Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas "
					/>
					<div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
						<div className="custom-form-start">
							<h6 className="form-inner-heading mb-4">
								Bride Personal Details
							</h6>
							<form onSubmit={handleSubmit}>
								<div className="row">
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="FullName">Full Name *</label>
											<input
												type="text"
												name="FullName"
												id="FullName"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="MiddleName">Middle Name</label>
											<input
												type="text"
												name="MiddleName"
												id="MiddleName"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="Surname">Surname *</label>
											<input
												type="text"
												name="Surname"
												id="Surname"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="FullNameInArabic">
												Full Name In Arabic (Only If You Require Arabic
												Certificate)
											</label>
											<input
												type="text"
												name="FullNameInArabic"
												id="FullNameInArabic"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group mb-3">
											<label htmlFor="DateOfBirth">Date Of Birth *</label>
											<input
												type="text"
												name="DateOfBirth"
												id="DateOfBirth"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group mb-3">
											<label htmlFor="PlaceBirth">Place Of Birth *</label>
											<input
												type="text"
												name="PlaceBirth"
												id="PlaceBirth"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-6 me-md-4">
										<div className="form-group mb-3">
											<label htmlFor="Religion">Religion *</label>
											<select
												name="Religion"
												id="Religion"
												className="form-select form-control"
											>
												<option value="Your answer">Your answer</option>
											</select>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="Address1">Address 1 *</label>
											<input
												type="text"
												name="Address1"
												id="Address1"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="Address2">Address 2 *</label>
											<input
												type="text"
												name="Address2"
												id="Address2"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group mb-3">
											<label htmlFor="City">City *</label>
											<select
												name="City"
												id="City"
												className="form-select form-control"
											>
												<option value="Your answer">Your answer</option>
											</select>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group mb-3">
											<label htmlFor="Postcode">Postcode *</label>
											<input
												type="text"
												name="Postcode"
												id="Postcode"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group mb-3">
											<label htmlFor="TelephoneNumber">
												Telephone Number *
											</label>
											<input
												type="text"
												name="TelephoneNumber"
												id="TelephoneNumber"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group mb-3">
											<label htmlFor="EmailAddress">Email Address </label>
											<input
												type="text"
												name="EmailAddress"
												id="EmailAddress"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group mb-3">
											<label htmlFor="Nationality">Nationality *</label>
											<select
												name="Nationality"
												id="Nationality"
												className="form-select form-control"
											>
												<option value="Your answer">Your answer</option>
											</select>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">ID Type *</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													Passport
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													Driving License
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													Residence Card
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													Other
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group mb-3">
											<label htmlFor="IDNumber">ID Number *</label>
											<input
												name="IDNumber"
												id="IDNumber"
												className="form-control lg"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3 pt-3">
											<label htmlFor="">Please Upload Your Photo ID Here</label>
											<div className="file-uploader-custom">
												<label htmlFor="UploadFile" className="fileuploader">
													<input
														type="file"
														name="UploadFile"
														hidden
														id="UploadFile"
													/>
													<span>
														Drag & Drop .PDF Files here or click to upload
													</span>
												</label>
												<div className="col-md-6 px-0">
													<div className="files-list">
														<div className="single-file">
															<div className="icon-text">
																<img src={ClipIcon} alt="" />
																<span>Attachment 1 .pdf</span>
															</div>
															<span className="removefile">Remove</span>
														</div>
														<div className="single-file">
															<div className="icon-text">
																<img src={ClipIcon} alt="" />
																<span>Attachment 1 .pdf</span>
															</div>
															<span className="removefile">Remove</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">Proof Of Address *</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													Utility Bill
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													Bank Statement
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													Council Tax Statement
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													Electoral Roll Statement
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													Other
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group mb-3">
											<label htmlFor="IDNumber1">ID Number *</label>
											<input
												name="IDNumber1"
												id="IDNumber1"
												className="form-control lg"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">
												Has the bride been married before?*
											</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													Yes
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													No
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3 pt-3">
											<label htmlFor="">
												If Yes , please provide Islamic Divorce documents
											</label>
											<div className="file-uploader-custom">
												<label htmlFor="UploadFile1" className="fileuploader">
													<input
														type="file"
														name="UploadFile1"
														hidden
														id="UploadFile1"
													/>
													<span>
														Drag & Drop .PDF Files here or click to upload
													</span>
												</label>
												<div className="col-md-6 px-0">
													<div className="files-list">
														<div className="single-file">
															<div className="icon-text">
																<img src={ClipIcon} alt="" />
																<span>Attachment 1 .pdf</span>
															</div>
															<span className="removefile">Remove</span>
														</div>
														<div className="single-file">
															<div className="icon-text">
																<img src={ClipIcon} alt="" />
																<span>Attachment 1 .pdf</span>
															</div>
															<span className="removefile">Remove</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3 pt-3">
											<label htmlFor="">
												Please upload proof of address here
											</label>
											<div className="file-uploader-custom">
												<label htmlFor="UploadFile" className="fileuploader">
													<input
														type="file"
														name="UploadFile"
														hidden
														id="UploadFile"
													/>
													<span>
														Drag & Drop .PDF Files here or click to upload
													</span>
												</label>
												<div className="col-md-6 px-0">
													<div className="files-list">
														<div className="single-file">
															<div className="icon-text">
																<img src={ClipIcon} alt="" />
																<span>Attachment 1 .pdf</span>
															</div>
															<span className="removefile">Remove</span>
														</div>
														<div className="single-file">
															<div className="icon-text">
																<img src={ClipIcon} alt="" />
																<span>Attachment 1 .pdf</span>
															</div>
															<span className="removefile">Remove</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-12 mt-2">
										<div className="form-group d-flex gap-3">
											<Link
												to="/nikkah-groom-form"
												className="btn ms-auto px-5"
											>
												Back
											</Link>
											<button type="submit" className="btn px-5">
												Next
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			<ContactSec />
			<Footer />
		</>
	);
};
export default NikkahBrideForm;
