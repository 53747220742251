import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from 'react-router-dom';
import FormHeadings from "../../components/FormHeadings";
import {
    documentimg,
    JanazaImg1,
    JanazaImg2,
    JanazaImg3,
    JanazaImg4,
} from "../../constant";
import { Link } from 'react-router-dom'
import "../../assets/css/about.css";
import ContactSec from "../../components/ContactSec";
import "../../assets/css/shariah-family-council.css";

const Janaza = () => {
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        // perform form submission logic here
        navigate('/checkout'); // navigate to the success page
    }
    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}
            {/* Family starts here */}
            <section className="about-mission">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-mission-content-wrapper mb-4">
                                <h6 className="small-heading">Manchester Islamic Centre</h6>
                                <h2>Janaza & Condolence</h2>
                                <p>
                                    Habitant morbi tristique senectus et. Nullam vehicula ipsum a
                                    arcu cursus. Aliquam purus sit amet luctus venenatis lectus
                                    magna fringilla urna. Malesuada pellentesque elit eget gravida
                                    cum sociis natoque. Faucibus ornare suspendisse sed nisi
                                    lacus. Consectetur adipiscing elit duis tristique sollicitudin
                                    nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
                                    egestas sed tempus urna et pharetra pharetra massa. Enim ut
                                    tellus elementum sagittis vitae et leo duis ut. ntesque elit
                                    eget gravida cum sociis natoque. Faucibus ornare suspendisse
                                    sed nisi lacus. Consectetur adipiscing
                                </p>
                                <p>
                                    Habitant morbi tristique senectus et. Nullam vehicula ipsum a arcu cursus. Aliquam purus sit amet luctus venenatis lectus magna fringilla urna. Malesuada pellentesque elit eget gravida cum sociis
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-img-wrapper">
                                <figure>
                                    <img src={JanazaImg1} className="img-fluid" alt="" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Family End here */}
            {/* Apply Now starts here */}
            <section className="apply-now  with-position">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-1"></div>
                        <div className="col-lg-5">
                            <div className="apply-now-content-wrapper">
                                <h6>Manchester Islamic Centre</h6>
                                <h2>Janaza Prayer</h2>
                                <p>Habitant morbi tristique senectus et. Nullam vehicula ipsum a arcu cursus. Aliquam purus sit amet luctus venenatis lectus magna fringilla urna. Malesuada pellentesque elit eget gravida cum sociis natoque. Faucibus ornare suspendisse sed nisi lacus. Consectetur adipiscing elit duis tristique sollicitudin nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis egestas sed tempus urna et pharetra pharetra massa. Enim ut tellus elementum sagittis vitae et leo duis ut. ntesque elit eget gravida cum sociis natoque. Faucibus ornare suspendisse sed nisi lacus. Consectetur adipiscing elit duis tristique sollicitudin nibh sit amet. Non curabitur gravida arcu ac tortor. Ac tur</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="family-starts-here">
                                <figure>
                                    <img src={JanazaImg2} className="img-fluid" alt="" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Apply Now End here */}
            {/* Family Counceling starts here */}
            <section className="about-mission">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-img-wrapper">
                                <figure>
                                    <img src={JanazaImg3} className="img-fluid" alt="" />
                                </figure>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-mission-content-wrapper mb-4">
                                <h6 className="small-heading">Manchester Islamic Centre</h6>
                                <h2>Condolence Room</h2>
                                <p>
                                    Habitant morbi tristique senectus et. Nullam vehicula ipsum a
                                    arcu cursus. Aliquam purus sit amet luctus venenatis lectus
                                    magna fringilla urna. Malesuada pellentesque elit eget gravida
                                    cum sociis natoque. Faucibus ornare suspendisse sed nisi
                                    lacus. Consectetur adipiscing elit duis tristique sollicitudin
                                    nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
                                    egestas sed tempus urna et pharetra pharetra massa. Enim ut
                                    tellus elementum sagittis vitae et leo duis ut. ntesque elit
                                    eget gravida cum sociis natoque. Faucibus ornare suspendisse
                                    sed nisi lacus. Consectetur adipiscing
                                </p>
                                <p>
                                    Habitant morbi tristique senectus et. Nullam vehicula ipsum a arcu cursus. Aliquam purus sit amet luctus venenatis lectus magna fringilla urna. Malesuada pellentesque elit eget gravida cum sociis
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-mission">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-mission-content-wrapper mb-4">
                                <h6 className="small-heading">Manchester Islamic Centre</h6>
                                <h2>Ghusul</h2>
                                <p>
                                    Habitant morbi tristique senectus et. Nullam vehicula ipsum a
                                    arcu cursus. Aliquam purus sit amet luctus venenatis lectus
                                    magna fringilla urna. Malesuada pellentesque elit eget gravida
                                    cum sociis natoque. Faucibus ornare suspendisse sed nisi
                                    lacus. Consectetur adipiscing elit duis tristique sollicitudin
                                    nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis
                                    egestas sed tempus urna et pharetra pharetra massa. Enim ut
                                    tellus elementum sagittis vitae et leo duis ut. ntesque elit
                                    eget gravida cum sociis natoque. Faucibus ornare suspendisse
                                    sed nisi lacus. Consectetur adipiscing
                                </p>
                                <p>
                                    Habitant morbi tristique senectus et. Nullam vehicula ipsum a arcu cursus. Aliquam purus sit amet luctus venenatis lectus magna fringilla urna. Malesuada pellentesque elit eget gravida cum sociis
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-img-wrapper">
                                <figure>
                                    <img src={JanazaImg4} className="img-fluid" alt="" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Family Counceling End here */}
            <section className="custom-forms">
                <div className="container">
                    <FormHeadings SmallHeading="Manchester Islamic Centre" MainHeading="Ghusul / Prayer / Condolence Application Form" Text="Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas " containerSize="col-xxl-10 col-xl-10 col-lg-9 col-md-10 col-sm-12 mx-auto px-xl-2" />
                    <div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
                        <div className="custom-form-start">
                            <form onSubmit={handleSubmit} >
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="applicationfor">What is the application for?</label>
                                            <select name='applicationfor' id='applicationfor' className="form-select form-control" >
                                                <option value="Select here">Select here</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="nameofdeceased">Full name of the deceased *</label>
                                            <input type="text" name='nameofdeceased' id='nameofdeceased' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Applicantname">Applicant's full name</label>
                                            <input type="text" name='Applicantname' id='Applicantname' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="ContactNumber">Contact Number *</label>
                                            <input type="text" name='ContactNumber' id='ContactNumber' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Email">Email *</label>
                                            <input type="text" name='Email' id='Email' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="AddressLine1">Address Line 1</label>
                                            <input type="text" name='AddressLine1' id='AddressLine1' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="AddressLine2">Address Line 2</label>
                                            <input type="text" name='AddressLine2' id='AddressLine2' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="City">City</label>
                                            <select type="text" name='City' id='City' className="form-select form-control">
                                                <option value="Your answer">Your answer</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="State">State</label>
                                            <select type="text" name='State' id='State' className="form-select form-control">
                                                <option value="Your answer">Your answer</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Zipcode">Zipcode</label>
                                            <input  type="text" name='Zipcode' id='Zipcode' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Country">Country</label>
                                            <select type="text" name='Country' id='Country' className="form-select form-control">
                                                <option value="Your answer">Your answer</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Selectdate">Select date</label>
                                            <input type="text" name='Selectdate' id='Selectdate' className="form-control date-icon" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Selecttime">Select time</label>
                                            <input type="text" name='Selecttime' id='Selecttime' className="form-control date-icon" placeholder='Your answer' />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Yourmessage">Your message</label>
                                            <textarea name='Yourmessage' id='Yourmessage' className="form-control lg" placeholder='Your answer' ></textarea>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mt-2">
                                        <div className="form-group">
                                            <button type="submit" className='btn ms-auto px-5'>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <ContactSec />
            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

export default Janaza;