import React from "react";
import { Link } from "react-router-dom";
import {
  FooterLogo,
  IconFb,
  IconInsta,
  IconTw,
  IconYout,
} from "../../constant";



const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="footer_start responsive_container_margin">
            <div className="row">
              <div className="col-md-4 ">
                <div className="footer_text">
                  <figure className="logo">
                    <Link className="d-block" to="/">
                      <img src={FooterLogo} className="img-fluid" alt="" />
                    </Link>
                  </figure>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                    sollii sn erat vel n erat n erat vel rhoncus
                  </p>
                  <ul className="social_links">
                    <li>
                      <Link to="">
                        <img src={IconFb} alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <img src={IconTw} alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <img src={IconYout} alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <img src={IconInsta} alt="" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-5 mt-lg-4">
                <div className="row">
                  <div className="col-md-5 pt-5 pt-lg-0 pt-md-0">
                    <div className="footer_links">
                      <h6 className="heading">Quick Links</h6>
                      <ul>
                        <li>
                          <Link to="/about">About Us</Link>
                        </li>
                        <li>
                          <Link to="/youth">Didsbury Youth</Link>
                        </li>
                        <li>
                          <Link to="/prayers">Prayers</Link>
                        </li>
                        <li>
                          <Link to="/nikkah">Nikkah</Link>
                        </li>
                        <li>
                          <Link to="/islamic-will">Islamic Will </Link>
                        </li>
                        <li>
                          <Link to="/terms">Terms & Conditions</Link>
                        </li>
                        <li>
                          <Link to="/privacy">Privacy Policy </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-7 pe-5 pt-5 pt-lg-0 pt-md-0">
                    <div className="contact_info pe-lg-4 me-lg-3">
                      <h6 className="heading">Contact Info</h6>
                      <ul>
                        <li className="address">
                          <i className="fa-solid fa-location-dot"></i>
                          <span>JI. Sample Road No.815, Kuta, UK 80361</span>
                        </li>
                        <li className="tel_us">
                          <i className="fa-solid fa-phone"></i>
                          <Link to="tel: (+12) 345 678 9">(+12) 345 678 9</Link>
                        </li>
                        <li className="email_us">
                          <i className="fa-solid fa-envelope"></i>
                          <Link to="mailto: support@domain.com">
                            support@domain.com
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mt-lg-4">
                <div className="news_letter">
                  <h6>Newsletter</h6>
                  <p>
                    Sed cras ornare arcu dui vivamus arcu felis bibendum ut.
                    Tempus egestas sed sed risus pretium quam vulputate
                  </p>
                  <div className="news_email">
                    <input
                      type="email"
                      name=""
                      id=""
                      placeholder="Your Email Address"
                    />
                    <button className="email_btn">
                      {" "}
                      <i className="fa-solid fa-envelope"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mt-5" />
            <div className="copy_right my-2 mb-0">
              <p className="text-center pop_light sm_text m-0">
                © Copyright 2023. All Right Reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
