import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../constant";
import Mobilenav from "../MobileNav";

const topMenus = [
  {
    id: 1,
    text: "Community Engagement",
    route: "/community-engagement",
  },
  {
    id: 2,
    text: "Contact Us",
    route: "/contact-us",
  },
  {
    id: 3,
    text: "Press Release",
    route: "/news",
  },
  {
    id: 4,
    text: "Online Shop",
    route: "/products",
  },
];

const Header = () => {
  const [toggle, setToggle] = useState(false);

  const openNav = () => {
    setToggle(true);
  };
  const closeNav = () => {
    setToggle(false);
  };
  return (
    <>
      {/* Header Start Here */}
      <header className="headerTop">
        <div className="container">
          <div className="row align-items-center">
            {/* Logo Box  */}
            <div className="col-lg-2">
              <div className="logo-box">
                <Link to="/">
                  <figure>
                    <img src={Logo} alt="logo-image" />
                  </figure>
                </Link>
              </div>
            </div>
            {/* Logo Box  */}

            {/* Main Menus  */}
            <div className="col-lg-10">
              {/* .navMenus  */}
              <div className="desktopNavWrapp">
                <div className="navbar-menus">
                  <ul className="top-menus">
                    {topMenus.map((menu) => (
                      <li className="menu-items" key={menu.id}>
                        <Link to={menu.route}>{menu.text}</Link>
                      </li>
                    ))}
                    <li className="menu-items cart-items">
                      <Link to="/cart">
                        <svg
                          id="shopping-cart"
                          xmlns="http://www.w3.org/2000/svg"
                          width="28.08"
                          height="28.065"
                          viewBox="0 0 28.08 28.065"
                        >
                          <circle
                            id="Ellipse_15"
                            data-name="Ellipse 15"
                            cx="2.339"
                            cy="2.339"
                            r="2.339"
                            transform="translate(5.847 23.387)"
                            fill="#fff"
                          />
                          <circle
                            id="Ellipse_16"
                            data-name="Ellipse 16"
                            cx="2.339"
                            cy="2.339"
                            r="2.339"
                            transform="translate(17.54 23.387)"
                            fill="#fff"
                          />
                          <path
                            id="Path_18633"
                            data-name="Path 18633"
                            d="M26.877,7.037a4.3,4.3,0,0,0-3.064-1.19H6.612L6.3,3.436A3.892,3.892,0,0,0,2.44,0H1.754A1.754,1.754,0,0,0,0,1.754H0A1.754,1.754,0,0,0,1.754,3.508H2.44a.387.387,0,0,1,.381.351L4.576,17.609a3.89,3.89,0,0,0,3.864,3.439H20.276a6.431,6.431,0,0,0,6.2-4.727l1.457-5.3a4.08,4.08,0,0,0-1.054-3.986Zm-3.782,8.355a2.933,2.933,0,0,1-2.819,2.148H8.439a.385.385,0,0,1-.38-.351l-1-7.835H23.988a.585.585,0,0,1,.568.739Z"
                            fill="#fff"
                          />
                        </svg>
                        <span className="count">2</span>
                      </Link>
                    </li>
                  </ul>
                  <ul className="bottom-menus">
                    <li className="menu-items">
                      <Link to="/about">About Us</Link>
                    </li>
                    <li className="dropdown menu-items">
                      <button
                        className="drop-header-btn"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Masjid
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <Link to="/prayers" className="dropdown-item">
                            Prayers
                          </Link>
                        </li>
                        <li>
                          <Link to="/study-circles" className="dropdown-item">
                            Study Circles
                          </Link>
                        </li>
                        <li>
                          <Link to="/aitekaf" className="dropdown-item">
                            Aitekaf
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown menu-items ">
                      <button
                        className="drop-header-btn"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Shariah & Family
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <Link
                            to="/shariah-family-council"
                            className="dropdown-item shahr-e-family"
                          >
                            Shariah Family Council
                          </Link>
                        </li>
                        <li>
                          <button
                            className="drop-header-btn"
                            id="dropdownMenuButton2"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Shariah Services
                          </button>
                          <ul
                            className="dropdown-submenu"
                            aria-labelledby="dropdownMenuButton2"
                          >
                            <li>
                              <Link
                                to="/family-counceling"
                                className="dropdown-item"
                              >
                                Family Counselling
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/family-meditation"
                                className="dropdown-item"
                              >
                                Family Mediation
                              </Link>
                            </li>
                            <li>
                              <Link to="/talaq" className="dropdown-item">
                                Talaq (Divorce)
                              </Link>
                            </li>
                            <li>
                              <Link to="/khula" className="dropdown-item">
                                Khula and Faskh
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/proof-of-nikkah"
                                className="dropdown-item"
                              >
                                Proof of Nikah
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/family-fatwah-request"
                                className="dropdown-item"
                              >
                                Family Fatwa Request
                              </Link>
                            </li>
                            <li>
                              <Link to="/inheritance" className="dropdown-item">
                                Inheritance
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/islamic-will"
                                className="dropdown-item"
                              >
                                Islamic Will
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/general-enquiry"
                                className="dropdown-item"
                              >
                                General Enquiry
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/business-meditation"
                                className="dropdown-item"
                              >
                                Business mediation
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/family-training-course"
                                className="dropdown-item"
                              >
                                Family Support Training courses
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <button
                            className="drop-header-btn"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Family Conference
                          </button>
                          <ul
                            className="dropdown-submenu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <Link
                                to="/upcoming-conference"
                                className="dropdown-item"
                              >
                                Upcoming Conference
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/previous-conference"
                                className="dropdown-item"
                              >
                                Previous Conference
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown menu-items ">
                      <button
                        className="drop-header-btn"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Marriage
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <Link to="/nikkah" className="dropdown-item">
                            Nikkah (Islamic Marriage)
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/marriage-networking"
                            className="dropdown-item"
                          >
                            Marriage Networking
                          </Link>
                        </li>
                        <li>
                          <Link to="/venue-booking" className="dropdown-item">
                            Venue Booking
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown menu-items">
                      <button
                        className="drop-header-btn"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Quran Competition
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <Link
                            to="/upcoming-competition"
                            className="dropdown-item"
                          >
                            Upcoming Competition
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/previous-competition"
                            className="dropdown-item"
                          >
                            Previous Competition
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/competition-gallery"
                            className="dropdown-item"
                          >
                            Competition Gallery
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown menu-items">
                      <button
                        className="drop-header-btn"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Quran School
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <Link to="/quran-school" className="dropdown-item">
                            Introduction
                          </Link>
                        </li>
                        <li>
                          <Link to="/team-members" className="dropdown-item">
                            Staff
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/face-to-face-class"
                            className="dropdown-item"
                          >
                            Classes
                          </Link>
                        </li>
                        <li>
                          <a
                            href="https://registrations.ibeuk.org/mic"
                            target="_blank"
                            className="dropdown-item"
                          >
                            Registration
                          </a>
                        </li>
                        <li>
                          <Link to="/quran-gallery" className="dropdown-item">
                            Gallery
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown menu-items ">
                      <button
                        className="drop-header-btn"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Haj & Umrah
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <Link to="/hajj-umrah" className="dropdown-item">
                            Introduction
                          </Link>
                        </li>
                        <li>
                          <Link to="/hajj-trip" className="dropdown-item">
                            Haj Trip
                          </Link>
                        </li>
                        <li>
                          <Link to="/umrah-trip" className="dropdown-item">
                            Umrah Trips
                          </Link>
                        </li>
                        <li>
                          <Link to="/hajj-gallery" className="dropdown-item">
                            Gallery
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-items">
                      <Link to="/janaza">Janaza & Condolence</Link>
                    </li>
                    <li className="menu-items">
                      <Link to="/youth">Didsbury Youth</Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* .navMenus  */}

              {/* Mobile nav  */}
              <div className="hamburgers" onClick={openNav}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div
                className={`mobileNavWrapp ${toggle ? "mobileNavActive" : ""}`}
              >
                <Mobilenav close={closeNav} />
              </div>
              {/* Mobile nav  */}
            </div>
            {/* Main Menus  */}
          </div>
        </div>
      </header>
      {/* Header End Here */}

      {/* Annoucement Start Here */}
      <div className="announcement-box">
        <div className="container">
          <div className="text-wrapper">
            <h4>Announcements</h4>
            <marquee behavior="" direction="">
              <p>
                Saturday 30th july will be the first day of hijra new year
                -1444. May Allah make it a year of goodness and blessings
              </p>
            </marquee>
          </div>
        </div>
      </div>
      {/* Annoucement End Here */}
    </>
  );
};
export default Header;
