import React from "react";
import { Link } from 'react-router-dom'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
    documentimg,
    YoungActivityImg,
    KidsActivityImg,
    GirlActivityImg,
    YouthImg,
    EidImg,
} from "../../constant";
import "../../assets/css/about.css";
import ContactSec from "../../components/ContactSec";
import "../../assets/css/shariah-family-council.css";
import ApplyNow from "../../components/ApplyNow";

const Youth = () => {
    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}
            {/* Family starts here */}
            <section className="about-mission">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-mission-content-wrapper mb-4">
                                <h6 className="small-heading">Manchester Islamic Centre</h6>
                                <h2>Didsbury Youth</h2>
                                <p>
                                    Habitant morbi tristique senectus et. Nullam vehicula ipsum a arcu cursus. Aliquam purus sit amet luctus venenatis lectus magna fringilla urna. Malesuada pellentesque elit eget gravida cum sociis natoque. Faucibus ornare suspendisse sed nisi lacus. Consectetur adipiscing elit duis tristique sollicitudin nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis egestas sed tempus urna et pharetra pharetra massa. Enim ut tellus elementum sagittis vitae et leo duis ut. ntesque elit eget gravida cum sociis natoque. Faucibus ornare suspendisse sed nisi lacus. Consectetur adipiscing
                                </p>
                                <p>
                                    Habitant morbi tristique senectus et. Nullam vehicula ipsum a arcu cursus. Aliquam purus sit amet luctus venenatis lectus magna fringilla urna. Malesuada pellentesque elit eget gravida cum sociis natoque. Faucibus ornare suspendisse sed nisi lacus. Consectetur adipiscing elit duis tristique sollicitudin nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis egestas sed tempus urna
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-img-wrapper">
                                <figure>
                                    <img src={YouthImg} className="img-fluid" alt="" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Family End here */}
            {/* Apply Now starts here */}
            <ApplyNow
                firstheading="Manchester Islamic Centre"
                secondheading="Eid Activities"
                paragraph="Habitant morbi tristique senectus et. Nullam vehicula ipsum a arcu cursus. Aliquam purus sit amet luctus venenatis lectus magna fringilla urna. Malesuada pellentesque elit eget gravida cum sociis natoque. Faucibus ornare suspendisse sed nisi lacus. Consectetur adipiscing elit duis tristique sollicitudin nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis egestas sed tempus urna"
                img={EidImg}
                redirecturl="/eid-actitvity"
            />
            {/* Apply Now End here */}
            {/* Family Counceling starts here */}
            <section className="about-mission">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-img-wrapper">
                                <figure>
                                    <img src={KidsActivityImg} className="img-fluid" alt="" />
                                </figure>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-mission-content-wrapper mb-4">
                                <h6 className="small-heading">Manchester Islamic Centre</h6>
                                <h2>Kids Activities</h2>
                                <p>
                                    Habitant morbi tristique senectus et. Nullam vehicula ipsum a arcu cursus. Aliquam purus sit amet luctus venenatis lectus magna fringilla urna. Malesuada pellentesque elit eget gravida cum sociis natoque. Faucibus ornare suspendisse sed nisi lacus. Consectetur adipiscing elit duis tristique sollicitudin nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis egestas sed tempus urna et pharetra pharetra massa. Enim ut tellus elementum sagittis vitae et leo duis ut. ntesque elit eget gravida cum sociis natoque. Faucibus ornare suspendisse sed nisi lacus. Consectetur adipiscing
                                </p>
                                <p>
                                    Habitant morbi tristique senectus et. Nullam vehicula ipsum a arcu cursus. Aliquam purus sit amet luctus venenatis lectus magna fringilla urna. Malesuada pellentesque elit eget gravida cum sociis
                                </p>
                                <Link to="/kids-actitvity" className="btn">Learn More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-mission">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-mission-content-wrapper mb-4">
                                <h6 className="small-heading">Manchester Islamic Centre</h6>
                                <h2>Girls Activity</h2>
                                <p>
                                    Habitant morbi tristique senectus et. Nullam vehicula ipsum a arcu cursus. Aliquam purus sit amet luctus venenatis lectus magna fringilla urna. Malesuada pellentesque elit eget gravida cum sociis natoque. Faucibus ornare suspendisse sed nisi lacus. Consectetur adipiscing elit duis tristique sollicitudin nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis egestas sed tempus urna et pharetra pharetra massa. Enim ut tellus elementum sagittis vitae et leo duis ut. ntesque elit eget gravida cum sociis natoque. Faucibus ornare suspendisse sed nisi lacus. Consectetur adipiscing
                                </p>
                                <p>Habitant morbi tristique senectus et. Nullam vehicula ipsum a arcu cursus. Aliquam purus sit amet luctus venenatis lectus magna fringilla urna. Malesuada pellentesque elit eget gravida cum sociis</p>
                                <Link to="/girls-actitvity" className="btn">Learn More</Link>

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-img-wrapper">
                                <figure>
                                    <img src={GirlActivityImg} className="img-fluid" alt="" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Family Counceling End here */}
            {/* Apply Now starts here */}
            <ApplyNow
                firstheading="Manchester Islamic Centre"
                secondheading="16+ Activities"
                paragraph="nt morbi tristique senectus et. Nullam vehicula ipsum a arcu cursus. Aliquam purus sit amet luctus venenatis lectus magna fringilla urna. Malesuada pellentesque elit eget gravida cum sociis natoque. Faucibus ornare suspendisse sed nisi lacus. Consectetur adipiscing elit duis tristique sollicitudin nibh sit amet. Non curabitur gravida arcu ac tortor. Ac turpis egestas sed tempus urna et pharetra pharetra massa. Enim ut"
                img={YoungActivityImg}
                redirecturl="/young-actitvity"
            />
            {/* Apply Now End here */}
            <ContactSec />
            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

export default Youth;