import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { documentimg } from "../../constant";
import { Link } from "react-router-dom";
import "../../assets/css/class.css";
import ContactSec from "../../components/ContactSec";
const SchoolActivity = () => {
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}
			{/* Class Section Start Here */}
			<section className="class-section">
				<div className="container">
					<div className="classes-start-here">
						<div className="col-xl-4 col-lg-6 mx-auto px-xxl-4 col-xl-3">
							<div className="top-heading text-center">
								<h6 className="small-heading mb-0">
									Manchester Islamic Centre
								</h6>
								<h2 className="mb-0">Classes</h2>
								<p className="mb-0">
									Sed cras ornare arcu dui vivamus arcu felis bibendum ut.
									Tempus egestas sed sed risus pretium quam{" "}
								</p>
							</div>
						</div>
						<div className="tabs-list">
							<Link to="/face-to-face-class" className="single-tab">
								Face to Face
							</Link>
							<Link to="/online-classes" className="single-tab">
								Online Course
							</Link>
							<Link to="/school-activity" className="single-tab active">
								School Activities
							</Link>
						</div>
						<div className="class-table">
							<table>
								<thead>
									<tr>
										<th>Course Name</th>
										<th>Starting Date</th>
										<th>Venue</th>
										<th>Time</th>
										<th>Participants</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<h5 className="mb-0">Class 1</h5>
											<p className="mb-0">Lorem ipsum dolor sit amet, </p>
										</td>
										<td className="text-center">22-December-2022</td>
										<td className="text-center">Islamic Center</td>
										<td className="text-center">6:00 AM</td>
										<td>4k</td>
									</tr>
									<tr>
										<td>
											<h5 className="mb-0">Class 2</h5>
											<p className="mb-0">Lorem ipsum dolor sit amet, </p>
										</td>
										<td className="text-center">22-December-2022</td>
										<td className="text-center">Islamic Center</td>
										<td className="text-center">6:00 AM</td>
										<td>4k</td>
									</tr>
									<tr>
										<td>
											<h5 className="mb-0">Class 3</h5>
											<p className="mb-0">Lorem ipsum dolor sit amet, </p>
										</td>
										<td className="text-center">22-December-2022</td>
										<td className="text-center">Islamic Center</td>
										<td className="text-center">6:00 AM</td>
										<td>4k</td>
									</tr>
									<tr>
										<td>
											<h5 className="mb-0">Class 4</h5>
											<p className="mb-0">Lorem ipsum dolor sit amet, </p>
										</td>
										<td className="text-center">22-December-2022</td>
										<td className="text-center">Islamic Center</td>
										<td className="text-center">6:00 AM</td>
										<td>4k</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="paginations-start mt-3">
							<ul className="pagination-list">
								<li>
									<Link className="normal active">01.</Link>
								</li>
								<li>
									<Link className="normal">02.</Link>
								</li>
								<li>
									<Link className="normal">03.</Link>
								</li>
								<li>
									<Link className="normal">Next</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
			{/* Class Section End Here */}
			<ContactSec />
			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default SchoolActivity;
