import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { documentimg } from "../../constant";
import { Link } from "react-router-dom";
import "../../assets/css/class.css";
import ContactSec from "../../components/ContactSec";
const PreviousConference = () => {
	return (
		<>
			{/* Header Start Here */}
			<Header />
			{/* Header End Here */}
			{/* Class Section Start Here */}
			<section className="class-section">
				<div className="container">
					<div className="classes-start-here">
						<div className="col-xl-6 col-lg-6 mx-auto px-xxl-4 col-xl-3">
							<div className="top-heading text-center">
								<h6 className="small-heading mb-0">
									Manchester Islamic Centre
								</h6>
								<h2 className="mb-0">Previous Conference</h2>
								<p className="mb-0">
									Sed cras ornare arcu dui vivamus arcu felis bibendum ut.
									Tempus egestas sed sed risus pretium quam{" "}
								</p>
							</div>
						</div>
						<div className="class-table">
							<table>
								<thead>
									<tr>
										<th>Conference Name</th>
										<th style={{ width: "30%" }}>Speakers</th>
										<th>Venue</th>
										<th style={{ width: "29%" }}></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<h5 className="mb-0 text-dark">Conference 1</h5>
											<p className="mb-0 text-dark">
												Lorem ipsum dolor sit amet,{" "}
											</p>
										</td>
										<td className="text-center">
											<div className="speaker-wrapper">
												<ul>
													<li>Mr Fawzi Al Haffar</li>
													<li>Mr Fawzi Al Haffar</li>
													<li>Mr Fawzi Al Haffar</li>
													<li>Mr Fawzi Al Haffar</li>
												</ul>
											</div>
										</td>
										<td className="text-center">Islamic Center</td>
										<td>
											<div className="btn-wrapper">
												<a href="#" className="btn">
													Video Link{" "}
												</a>
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<h5 className="mb-0 text-dark">Conference 2</h5>
											<p className="mb-0 text-dark">
												Lorem ipsum dolor sit amet,{" "}
											</p>
										</td>
										<td className="text-center">
											<div className="speaker-wrapper">
												<ul>
													<li>Mr Fawzi Al Haffar</li>
													<li>Mr Fawzi Al Haffar</li>
													<li>Mr Fawzi Al Haffar</li>
													<li>Mr Fawzi Al Haffar</li>
												</ul>
											</div>
										</td>
										<td className="text-center">Islamic Center</td>
										<td>
											<div className="btn-wrapper">
												<a href="#" className="btn">
													Video Link{" "}
												</a>
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<h5 className="mb-0 text-dark">Conference 3</h5>
											<p className="mb-0 text-dark">
												Lorem ipsum dolor sit amet,{" "}
											</p>
										</td>
										<td className="text-center">
											<div className="speaker-wrapper">
												<ul>
													<li>Mr Fawzi Al Haffar</li>
													<li>Mr Fawzi Al Haffar</li>
													<li>Mr Fawzi Al Haffar</li>
													<li>Mr Fawzi Al Haffar</li>
												</ul>
											</div>
										</td>
										<td className="text-center">Islamic Center</td>
										<td>
											<div className="btn-wrapper">
												<a href="#" className="btn">
													Video Link{" "}
												</a>
											</div>
										</td>
									</tr>
									<tr>
										<td>
											<h5 className="mb-0 text-dark">Conference 4</h5>
											<p className="mb-0 text-dark">
												Lorem ipsum dolor sit amet,{" "}
											</p>
										</td>
										<td className="text-center">
											<div className="speaker-wrapper">
												<ul>
													<li>Mr Fawzi Al Haffar</li>
													<li>Mr Fawzi Al Haffar</li>
													<li>Mr Fawzi Al Haffar</li>
													<li>Mr Fawzi Al Haffar</li>
												</ul>
											</div>
										</td>
										<td className="text-center">Islamic Center</td>
										<td>
											<div className="btn-wrapper">
												<a href="#" className="btn">
													Video Link{" "}
												</a>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="paginations-start mt-3">
							<ul className="pagination-list">
								<li>
									<Link className="normal active">01.</Link>
								</li>
								<li>
									<Link className="normal">02.</Link>
								</li>
								<li>
									<Link className="normal">03.</Link>
								</li>
								<li>
									<Link className="normal">Next</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
			{/* Class Section End Here */}
			<ContactSec />
			{/* Footer Start Here */}
			<Footer />
			{/* Footer End Here */}
		</>
	);
};

export default PreviousConference;
