
import React from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import ContactSec from '../../components/ContactSec';
import FormHeadings from '../../components/FormHeadings';
import { Link } from 'react-router-dom'
import { ClipIcon } from '../../constant';
import { useNavigate } from 'react-router-dom';
const DeceasedInheritanceForm = () => {
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        // perform form submission logic here
        navigate('/checkout'); // navigate to the success page
    }
    return (
        <>
            <Header />
            <section className="custom-forms">
                <div className="container">
                    <FormHeadings SmallHeading="Manchester Islamic Centre" MainHeading="Islamic Inheritance Form" containerSize="col-xl-9 col-lg-9 col-md-10 col-sm-12 mx-auto px-xl-4" Text="Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas " />
                    <div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
                        <div className="custom-form-start">
                        <h6 className='form-inner-heading mt-1 mb-3'>Deceased Personal Details</h6>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="FirstName">First Name *</label>
                                            <input type="text" name='FirstName' id='FirstName' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Surname">Surname *</label>
                                            <input type="text" name='Surname' id='Surname' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3 pt-3">
                                            <label htmlFor="">Death Certificate Upload *</label>
                                            <div className="file-uploader-custom">
                                                <label htmlFor="DeathCertificate" className='fileuploader'>
                                                    <input type="file" name="DeathCertificate" hidden id="DeathCertificate" />
                                                    <span>Drag & Drop .PDF Files here or click to upload</span>
                                                </label>
                                                <div className="col-md-6 px-0">
                                                    <div className="files-list">
                                                        <div className="single-file">
                                                            <div className="icon-text">
                                                                <img src={ClipIcon} alt="" />
                                                                <span>Attachment 1 .pdf</span>
                                                            </div>
                                                            <span className='removefile'>Remove</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="writtenDeceased">Is There A Written Will From The Deceased *</label>
                                            <div className="custom-check-box-default">
                                                <label className="label-checkbox">Yes
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">No
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Other
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <input type="text" name='writtenDeceased' id='writtenDeceased' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="DebtsUnpaid">Are There Any Debts Unpaid ? *</label>
                                            <div className="custom-check-box-default">
                                                <label className="label-checkbox">Yes
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">No
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Other
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <input type="text" name='DebtsUnpaid' id='DebtsUnpaid' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="PleaseStateDetails">If yes, Please State The Details</label>
                                            <textarea name='PleaseStateDetails' id='PleaseStateDetails' className="form-control lg" placeholder='Your answer' ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="">What has been left as inheritance *</label>
                                            <div className="custom-check-box-default">
                                                <label className="label-checkbox">Money
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Gold
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Property
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Land
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Others
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="">Was the deceased married before this marriage? (mark only one) *</label>
                                            <div className="custom-check-box-default">
                                                <label className="label-checkbox">Yes
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">No
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="childrenfrommarriage">If yes, were there any children from this marriage ? *</label>
                                            <div className="custom-check-box-default">
                                                <label className="label-checkbox">Yes
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">No
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Others
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <input type="text" name='childrenfrommarriage' id='childrenfrommarriage' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="NameDOBeachchild">If yes, Please List the Name and DOB for each child</label>
                                            <textarea name='NameDOBeachchild' id='NameDOBeachchild' className="form-control lg" placeholder='Your answer' ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="motherdeceasedstillpresent">Is the mother of the deceased still present? *</label>
                                            <div className="custom-check-box-default">
                                                <label className="label-checkbox">Yes
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">No
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Others
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <input type="text" name='motherdeceasedstillpresent' id='motherdeceasedstillpresent' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="fatherdeceasedstillpresent">Is the father of the deceased still present? *</label>
                                            <div className="custom-check-box-default">
                                                <label className="label-checkbox">Yes
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">No
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Others
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <input type="text" name='fatherdeceasedstillpresent' id='fatherdeceasedstillpresent' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="grandmotherdeceasedstillpresent">Is the grandmother of the deceased still present? *</label>
                                            <div className="custom-check-box-default">
                                                <label className="label-checkbox">Yes
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">No
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Others
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <input type="text" name='grandmotherdeceasedstillpresent' id='grandmotherdeceasedstillpresent' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="grandfatherdeceasedstillpresent">Is the grandfather of the deceased still present? *</label>
                                            <div className="custom-check-box-default">
                                                <label className="label-checkbox">Yes
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">No
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Others
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <input type="text" name='grandfatherdeceasedstillpresent' id='grandfatherdeceasedstillpresent' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Additionalrelevantinfo">Additional Information relevant to your application *</label>
                                            <textarea name='Additionalrelevantinfo' id='Additionalrelevantinfo' className="form-control lg" placeholder='Your answer' ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <div className="form-group d-flex gap-3">
                                            <button type='submit' className='btn ms-auto px-5'>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section >
            <ContactSec />
            <Footer />
        </>
    )
}
export default DeceasedInheritanceForm;