import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
    ProductImg4,
    ProductImg6,
    ProductImg5,
} from "../../constant";
import "../../assets/css/ecommerce.css";

const Checkout = () => {


    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}
            <section className="ecommerce-products pt-5">
                <div className="container">
                    <div className="col-xl-11 mx-auto">
                        <div className="cart-start">
                            <div className="top-headings mt-5 mb-4 pb-4">
                                <div className="col-lg-7 col-md-8 mx-auto text-center">
                                    <div class="mic-content-wrapper">
                                        <h6 class="small-heading">Manchester Islamic Centre</h6>
                                        <h2>Checkout</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="check-out-start">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="forms-check">
                                        <h5>Billing Details</h5>
                                        <div className="custom-form-start">
                                            <form  >
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="FirstName">First Name</label>
                                                            <input type="text" name='FirstName' id='FirstName' className="form-control" placeholder='Your answer' />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="PhoneNumber">Last Name</label>
                                                            <input type="text" name='PhoneNumber' id='PhoneNumber' className="form-control" placeholder='Your answer' />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="Email">Phone</label>
                                                            <input type="text" name='Email' id='Email' className="form-control" placeholder='Your answer' />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="SecondPartyName">Email</label>
                                                            <input type="text" name='SecondPartyName' id='SecondPartyName' className="form-control" placeholder='Your answer' />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="SecondPartyPhoneNumber">Address</label>
                                                            <input type="text" name='SecondPartyPhoneNumber' id='SecondPartyPhoneNumber' className="form-control" placeholder='Your answer' />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="SecondPartyAddress">Suite, Apartment, etc </label>
                                                            <input type="text" name='SecondPartyAddress' id='SecondPartyAddress' className="form-control lg" placeholder='Your answer' />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="SecondPar">City</label>
                                                            <select name='SecondPar' id='SecondPar' className="form-select form-control" >
                                                                <option value="Your answer">Your answer</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="Relations">Country/Region</label>
                                                            <select type="text" name='Relations' id='Relations' className="form-select form-control" >
                                                                <option value="Your answer">Your answer</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="NatureOfDispute">State</label>
                                                            <select name='NatureOfDispute' id='NatureOfDispute' className="form-select form-control" >
                                                                <option value="Your answer">Your answer</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="ZipCode">Zip Code</label>
                                                            <input type="text" name='ZipCode' id='ZipCode' className="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <h5 className="mt-5 ">Shipping Details</h5>
                                        <div className="col-md-12">
                                            <div className="form-group mb-3">
                                                <div className="custom-check-box-default checkout-check">
                                                    <label class="label-checkbox">Shipping address is same as my billing address
                                                        <input type="checkbox" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="custom-form-start">
                                            <form  >
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="FirstName">First Name</label>
                                                            <input type="text" name='FirstName' id='FirstName' className="form-control" placeholder='Your answer' />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="PhoneNumber">Last Name</label>
                                                            <input type="text" name='PhoneNumber' id='PhoneNumber' className="form-control" placeholder='Your answer' />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="Email">Phone</label>
                                                            <input type="text" name='Email' id='Email' className="form-control" placeholder='Your answer' />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="SecondPartyName">Email</label>
                                                            <input type="text" name='SecondPartyName' id='SecondPartyName' className="form-control" placeholder='Your answer' />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="SecondPartyPhoneNumber">Address</label>
                                                            <input type="text" name='SecondPartyPhoneNumber' id='SecondPartyPhoneNumber' className="form-control" placeholder='Your answer' />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="SecondPartyAddress">Suite, Apartment, etc </label>
                                                            <input type="text" name='SecondPartyAddress' id='SecondPartyAddress' className="form-control lg" placeholder='Your answer' />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="SecondPar">City</label>
                                                            <select name='SecondPar' id='SecondPar' className="form-select form-control" >
                                                                <option value="Your answer">Your answer</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="Relations">Country/Region</label>
                                                            <select type="text" name='Relations' id='Relations' className="form-select form-control" >
                                                                <option value="Your answer">Your answer</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="NatureOfDispute">State</label>
                                                            <select name='NatureOfDispute' id='NatureOfDispute' className="form-select form-control" >
                                                                <option value="Your answer">Your answer</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group mb-3">
                                                            <label htmlFor="ZipCode">Zip Code</label>
                                                            <input type="text" name='ZipCode' id='ZipCode' className="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <h5 className="mt-4">Payment Details</h5>
                                        <div className="col-md-12">
                                            <input type="text" name='ZipCode' id='ZipCode' className="form-control" placeholder='mm/yy' />
                                        </div>
                                        <button className="btn d-block w-100 text-center mt-3">Add Card</button>
                                    </div>
                                </div>
                                <div className="col-md-6 ps-lg-4">
                                    <div className="product-data-check">
                                        <div className="order-summary pt-0">
                                            <h5 className="with-back">Service Details</h5>
                                            <div className="service-data">
                                                <div className="single-service">
                                                    <figure className="service-detail-img">
                                                        <img src={ProductImg4} alt="" />
                                                        {/* <span className="close">X</span> */}
                                                    </figure>
                                                    <div className="product-data">
                                                        <div className="details">
                                                            <h5>Service Name 1</h5>
                                                            <p>Habitant morbi tristique</p>
                                                        </div>
                                                        <span className="answer">£600 </span>
                                                    </div>
                                                </div>
                                                <div className="single-service">
                                                    <figure className="service-detail-img">
                                                        <img src={ProductImg5} alt="" />
                                                        {/* <span className="close">X</span> */}
                                                    </figure>
                                                    <div className="product-data">
                                                        <div className="details">
                                                            <h5>Service Name 2</h5>
                                                            <p>Habitant morbi tristique</p>
                                                        </div>
                                                        <span className="answer">£600 </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 className="with-back">Product Details</h5>
                                            <div className="service-data products">
                                                <div className="single-service">
                                                    <figure className="service-detail-img">
                                                        <img src={ProductImg6} alt="" />
                                                    </figure>
                                                    <div className="product-data">
                                                        <div className="details">
                                                            <h5>Product Name </h5>
                                                            <p>Habitant morbi tristique</p>
                                                        </div>
                                                        <span className="answer">£600 </span>
                                                    </div>
                                                </div>
                                                <div className="single-service">
                                                    <figure className="service-detail-img">
                                                        <img src={ProductImg6} alt="" />
                                                    </figure>
                                                    <div className="product-data">
                                                        <div className="details">
                                                            <h5>Product Name </h5>
                                                            <p>Habitant morbi tristique</p>
                                                        </div>
                                                        <span className="answer">£600 </span>
                                                    </div>
                                                </div>
                                                <div className="single-service">
                                                    <figure className="service-detail-img">
                                                        <img src={ProductImg6} alt="" />
                                                    </figure>
                                                    <div className="product-data">
                                                        <div className="details">
                                                            <h5>Product Name </h5>
                                                            <p>Habitant morbi tristique</p>
                                                        </div>
                                                        <span className="answer">£600 </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <h6 className="small-heading">Payment Details</h6>
                                            <div className="order-data">
                                                <div className="data">
                                                    <span className="question">Total :</span>
                                                    <span className="answer">£122.00</span>
                                                </div>
                                                <div className="data">
                                                    <span className="question">Shipping Charges :</span>
                                                    <span className="answer">£162.00</span>
                                                </div>
                                                <div className="data">
                                                    <span className="question">Taxes Applied :</span>
                                                    <span className="answer">£42.00</span>
                                                </div>
                                                <div className="data">
                                                    <span className="question">Donation : </span>
                                                    <span className="answer">£3.00</span>
                                                </div>
                                                <div className="data-total data">
                                                    <span className="question">Order Total :</span>
                                                    <span className="answer">£1025.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="proced-thankyou">
                                <div className="check-abcd">
                                    <div className="form-group mb-3">
                                        <div className="custom-check-box-default checkout-check-2">
                                            <label class="label-checkbox">Proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                                <input type="checkbox" checked />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="proced-btn">
                                    <Link to="/thankyou" className="btn px-5">Confirm Payment</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

export default Checkout;