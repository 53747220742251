import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactSec from "../../components/ContactSec";
import FormHeadings from "../../components/FormHeadings";
import { Link } from "react-router-dom";
import { ClipIcon } from "../../constant";
import { useNavigate } from "react-router-dom";
const NikahDetailsWifeDivorce = () => {
	const navigate = useNavigate();
	const handleSubmit = (event) => {
		event.preventDefault();
		// perform form submission logic here
		navigate("/wife-divorce-civil-marriage"); // navigate to the success page
	};
	return (
		<>
			<Header />
			<section className="custom-forms">
				<div className="container">
					<FormHeadings
						SmallHeading="Manchester Islamic Centre"
						MainHeading="Wife Seeking Divorce From Her Husband"
						containerSize="col-xl-9 col-lg-9 col-md-10 col-sm-12 mx-auto px-xl-4"
						Text="Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas "
					/>

					<div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
						<div className="custom-form-start">
							<h6 className="form-inner-heading mt-1 mb-3">
								Nikkah/ Marriage Details
							</h6>
							<form onSubmit={handleSubmit}>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group mb-3">
											<label htmlFor="DateOfNikkah">Date Of Nikkah</label>
											<input
												type="text"
												name="DateOfNikkah"
												id="DateOfNikkah"
												className="form-control"
												placeholder="DD/MM/YYYY"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="PlaceOfNikkah">Place Of Nikkah *</label>
											<input
												type="text"
												name="PlaceOfNikkah"
												id="PlaceOfNikkah"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="CountryOfNikkah">
												Country Of Nikkah *
											</label>
											<input
												type="text"
												name="CountryOfNikkah"
												id="CountryOfNikkah"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">Do You Have Nikkah Certificate</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													Yes
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													No
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3 pt-3">
											<label htmlFor="">Nikkah Certificate Upload</label>
											<div className="file-uploader-custom">
												<label htmlFor="UploadFile" className="fileuploader">
													<input
														type="file"
														name="UploadFile"
														hidden
														id="UploadFile"
													/>
													<span>
														Drag & Drop .PDF Files here or click to upload
													</span>
												</label>
												<div className="col-md-6 px-0">
													<div className="files-list">
														<div className="single-file">
															<div className="icon-text">
																<img src={ClipIcon} alt="" />
																<span>Attachment 1 .pdf</span>
															</div>
															<span className="removefile">Remove</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="AmountMahragreed">
												Amount of Mahr agreed? *
											</label>
											<input
												type="text"
												name="AmountMahragreed"
												id="AmountMahragreed"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">Has it been paid in full?</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													Yes
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													No
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="outstandingtopay">
												If No, please state how much is outstanding to pay?
											</label>
											<input
												type="text"
												name="outstandingtopay"
												id="outstandingtopay"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">
												Have you been given any Land, Jewellery or any other
												item by your husband?
											</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													Yes
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													No
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="detailsofweight">
												If Yes, please give details of weight or value and who
												is currently in possession of them.
											</label>
											<input
												type="text"
												name="detailsofweight"
												id="detailsofweight"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="detailsofJewelleryorhousehold">
												Please give any details of an Jewellery or household
												items which were given to you by your parents , which
												are still in possession of your husband.
											</label>
											<input
												type="text"
												name="detailsofJewelleryorhousehold"
												id="detailsofJewelleryorhousehold"
												className="form-control"
												placeholder="DD/MM/YYYY"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">
												Did you live together after the Nikkah?
											</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													Yes
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													No
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">Was the marriage consummated?</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													Yes
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													No
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="DateofSeparation">
												Date of Separation
											</label>
											<input
												type="text"
												name="DateofSeparation"
												id="DateofSeparation"
												className="form-control"
												placeholder="DD/MM/YYYY"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">
												Are you both still living in the same property?
											</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													Yes
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													No
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">
												Are there any authorities /agencies involved?
											</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													Yes
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													No
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">
												Is there a Non-Molestation / Restraining Order in place
												?
											</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													Yes
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													No
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3 pt-3">
											<label htmlFor="">If Yes, please upload it here</label>
											<div className="file-uploader-custom">
												<label htmlFor="UploadFile" className="fileuploader">
													<input
														type="file"
														name="UploadFile"
														hidden
														id="UploadFile"
													/>
													<span>
														Drag & Drop .PDF Files here or click to upload
													</span>
												</label>
												<div className="col-md-6 px-0">
													<div className="files-list">
														<div className="single-file">
															<div className="icon-text">
																<img src={ClipIcon} alt="" />
																<span>Attachment 1 .pdf</span>
															</div>
															<span className="removefile">Remove</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">
												Has reconciliation been attempted?
											</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													Yes
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													No
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">
												Did both parties agree to sit together for
												reconciliation?
											</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													Yes
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													No
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="togetherforreconciliatio">
												If Yes, Please give details of the outcome and who was
												involved in this?
											</label>
											<input
												type="text"
												name="togetherforreconciliatio"
												id="togetherforreconciliatio"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="giveanydetails">
												If No, please give any details why?
											</label>
											<input
												type="text"
												name="giveanydetails"
												id="giveanydetails"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">Have you had a Civil Marriage ?</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													Yes
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													No
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">
												Would you like Manchester Shariah Council to attempt
												reconciliation?
											</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													Yes
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													No
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="">
												Have you been married before this marriage ?
											</label>
											<div className="custom-check-box-default">
												<label className="label-checkbox">
													Yes
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
												<label className="label-checkbox">
													No
													<input type="checkbox" />
													<span className="checkmark"></span>
												</label>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3 pt-3">
											<label htmlFor="">
												If Yes, please upload previous divorce certificate.
											</label>
											<div className="file-uploader-custom">
												<label htmlFor="UploadFile1" className="fileuploader">
													<input
														type="file"
														name="UploadFile1"
														hidden
														id="UploadFile1"
													/>
													<span>
														Drag & Drop .PDF Files here or click to upload
													</span>
												</label>
												<div className="col-md-6 px-0">
													<div className="files-list">
														<div className="single-file">
															<div className="icon-text">
																<img src={ClipIcon} alt="" />
																<span>Attachment 1 .pdf</span>
															</div>
															<span className="removefile">Remove</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="reasondissolutionmarriage">
												If Yes, what was the reason for dissolution of that
												marriage?
											</label>
											<input
												type="text"
												name="reasondissolutionmarriage"
												id="reasondissolutionmarriage"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12 mt-2">
										<div className="form-group d-flex gap-3 mt-md-4 mt-2">
											<Link
												to="/wife-divorce-statement"
												className="btn ms-auto px-5"
											>
												Back
											</Link>
											<button type="submit" className="btn px-5">
												Next
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			<ContactSec />
			<Footer />
		</>
	);
};
export default NikahDetailsWifeDivorce;
