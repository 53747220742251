import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactSec from "../../components/ContactSec";
import FormHeadings from "../../components/FormHeadings";
import { Link } from "react-router-dom";
import { ClipIcon } from "../../constant";
import { useNavigate } from "react-router-dom";

const MatrimonialProfilesForm = () => {
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    // perform form submission logic here
    navigate("/inhertance-form-deceased"); // navigate to the success page
  };
  return (
    <>
      <Header />
      <section className="custom-forms">
        <div className="container">
          <FormHeadings
            SmallHeading="Manchester Islamic Centre"
            MainHeading="Didsbury Mosque Matrimonial Profiles Submission"
            containerSize="col-xl-12 col-lg-9 col-md-10 col-sm-12 mx-auto px-xl-4"
            Text="Please fill in this form if you would like to add your profile to Didsbury Mosque Matrimonial Database. The survey will take approximately 8 minutes to complete."
          />
          <div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
            <div className="custom-form-start">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">Gender *</label>
                      <div className="custom-check-box-default">
                        <label className="label-checkbox">
                          Male
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          Female
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="FirstName">Name *</label>
                      <input
                        type="text"
                        name="FirstName"
                        id="FirstName"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="EmailAddress">Email Address *</label>
                      <input
                        type="email"
                        name="EmailAddress"
                        id="EmailAddress"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="EmailAddress">Contact Number *</label>
                      <input
                        type="number"
                        name="ContactNumber"
                        id="ContactNumber"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="EmailAddress">Age *</label>
                      <input
                        type="number"
                        name="Age"
                        id="Age"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="EmailAddress">
                        Height (in Feet & Inches) *
                      </label>
                      <input
                        type="text"
                        name="Height"
                        id="Height"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">Nationality *</label>
                      <div className="custom-check-box-default">
                        <label className="label-checkbox">
                          British
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          Other
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">Build *</label>
                      <div className="custom-check-box-default">
                        <label className="label-checkbox">
                          Slim
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          Average
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          Prefer not to say
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">Culture Background *</label>
                      <div className="custom-check-box-default">
                        <label className="label-checkbox">
                          Asian
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          Arab
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          Other
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">Location *</label>
                      <div className="custom-check-box-default">
                        <label className="label-checkbox">
                          Manchester
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          Other
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">Spoken Language *</label>
                      <div className="custom-check-box-default">
                        <label className="label-checkbox">
                          English
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          Arabic
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          Urdu
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">Education *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">Job *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">Martial Status *</label>
                      <div className="custom-check-box-default">
                        <label className="label-checkbox">
                          Never Married
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          Divorced
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">Do You Have Children *</label>
                      <div className="custom-check-box-default">
                        <label className="label-checkbox">
                          Yes
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          No
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">
                        How Religious Would You Describe Yourself *
                      </label>
                      <div className="custom-check-box-default">
                        <label className="label-checkbox">
                          Very religious
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          Some what religious
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          Not very religious
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">Hijab</label>
                      <div className="custom-check-box-default">
                        <label className="label-checkbox">
                          Yes
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          No
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          N/a
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">Beard </label>
                      <div className="custom-check-box-default">
                        <label className="label-checkbox">
                          Yes
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          No
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          N/a
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">3 words to describe yourself *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">
                        3 words to describe your ideal spouse *
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">Hobbies *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">Community/Voluntary Work</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">Further details About you *</label>
                      <i className="d-block">
                        (Please Specify Any Details About You For Example Your
                        Family Background Etc)
                      </i>
                      <textarea
                        name=""
                        className="form-control"
                        id=""
                        cols="30"
                        rows="5"
                        placeholder="Your answer"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">What you are looking for: *</label>
                      <i className="d-block">
                        (Please Specify Any Details About You For Example Your
                        Family Background Etc)
                      </i>
                      <textarea
                        name=""
                        className="form-control"
                        id=""
                        cols="30"
                        rows="5"
                        placeholder="Your answer"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">
                        I Understand And Agree To The{" "}
                        <a className="terms-link" href="">Terms Of The Privacy</a> Statement{" "}
                      </label>
                      <div className="custom-check-box-default">
                        <label className="label-checkbox">
                          Yes
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          No
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">
                        I Agree For The Information I Provide To Be Used At
                        Didsbury Mosque Marriageevents
                      </label>
                      <i className="d-block">
                        (We Will Be Printing The Details On This Form To Put Up
                        At The Event For Potential Matches To Contact You And
                        Have A Look At Your Profile)
                      </i>
                      <div className="custom-check-box-default">
                        <label className="label-checkbox">
                          Yes
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          No
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">
                        I Consent For Didsbury Mosque To Hold Information I
                        Provide Here For Futuremarriage Events Or Potential
                        Matches *
                      </label>
                      <div className="custom-check-box-default">
                        <label className="label-checkbox">
                          Yes
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          No
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="">
                        I Consent To Being Updated About Any Future Events
                        Around Marriagematrimonial Services That Didsbury Mosque
                        May Provide *
                      </label>
                      <div className="custom-check-box-default">
                        <label className="label-checkbox">
                          Yes
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="label-checkbox">
                          No
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-2">
                    <div className="form-group d-flex gap-3">
                      <Link to="" className="btn ms-auto px-5 opacity-0">
                        Back
                      </Link>
                      <button type="submit" className="btn px-5">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ContactSec />
      <Footer />
    </>
  );
};

export default MatrimonialProfilesForm;
