import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactSec from "../../components/ContactSec";
import FormHeadings from "../../components/FormHeadings";
const ConferenceRegister = () => {
  return (
    <>
      <Header />
      <section className="custom-forms">
        <div className="container">
          <FormHeadings
            SmallHeading="Manchester Islamic Centre"
            MainHeading="Family Strong Convenant Annual Conference"
            Text="Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas "
            containerSize="col-xxl-9 col-xl-10 col-lg-9 col-md-10 col-sm-12 mx-auto px-xl-4"
          />
          <div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
            <div className="custom-form-start">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="FirstName">First Name *</label>
                      <input
                        type="text"
                        name="FirstName"
                        id="FirstName"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="MiddleName">Middle Name</label>
                      <input
                        type="text"
                        name="MiddleName"
                        id="MiddleName"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="LastName">Last Name *</label>
                      <input
                        type="text"
                        name="LastName"
                        id="LastName"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="ContactNumber">Contact Number *</label>
                      <input
                        type="text"
                        name="ContactNumber"
                        id="ContactNumber"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label htmlFor="Email">Email *</label>
                      <input
                        type="text"
                        name="Email"
                        id="Email"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="AddressLine1">Address Line 1</label>
                      <input
                        type="text"
                        name="AddressLine1"
                        id="AddressLine1"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="AddressLine2">Address Line 2</label>
                      <input
                        type="text"
                        name="AddressLine2"
                        id="AddressLine2"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="City">City</label>
                      <select
                        name="City"
                        id="City"
                        className="form-select form-control"
                      >
                        <option value="Your Answer" selected>
                          Your Answer
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="State">State</label>
                      <select
                        name="City"
                        id="City"
                        className="form-select form-control"
                      >
                        <option value="Your Answer" selected>
                          Your Answer
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="Zipcode">Zipcode</label>
                      <input
                        type="text"
                        name="Zipcode"
                        id="Zipcode"
                        className="form-control"
                        placeholder="Your answer"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="Country">Country</label>
                      <select
                        name="City"
                        id="City"
                        className="form-select form-control"
                      >
                        <option value="Your Answer" selected>
                          Your Answer
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label htmlFor="NoOfAttendees">No Of Attendees</label>
                      <textarea
                        name="NoOfAttendees"
                        id="NoOfAttendees"
                        className="form-control lg"
                        placeholder="Your answer"
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-md-12 mt-2">
                    <div className="form-group">
                      <button type="submit" className="btn ms-auto px-5">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ContactSec />
      <Footer />
    </>
  );
};
export default ConferenceRegister;
