import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactSec from "../../components/ContactSec";
import FormHeadings from "../../components/FormHeadings";
import { Link } from "react-router-dom";
import { ClipIcon } from "../../constant";
import { useNavigate } from "react-router-dom";
const WillAssetDetails = () => {
	const navigate = useNavigate();
	const handleSubmit = (event) => {
		event.preventDefault();
		// perform form submission logic here
		navigate("/checkout"); // navigate to the success page
	};
	return (
		<>
			<Header />
			<section className="custom-forms">
				<div className="container">
					<FormHeadings
						SmallHeading="Manchester Islamic Centre"
						MainHeading="Islamic Will Form"
						Text="Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas "
					/>
					<div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
						<div className="custom-form-start">
							<h6 className="form-inner-heading mt-3 mb-2">Asset Details</h6>
							<form onSubmit={handleSubmit}>
								<div className="row">
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="BrieflyStateAssetDetails">
												Briefly State The Asset Details That You Wish To Include
												In The Will
											</label>
											<textarea
												name="BrieflyStateAssetDetails"
												id="BrieflyStateAssetDetails"
												className="form-control lg"
												placeholder="Your answer"
											></textarea>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3 pt-3">
											<label htmlFor="">Further Asset Details</label>
											<div className="file-uploader-custom">
												<label htmlFor="UploadFile" className="fileuploader">
													<input
														type="file"
														name="UploadFile"
														hidden
														id="UploadFile"
													/>
													<span>
														Drag & Drop .PDF Files here or click to upload
													</span>
												</label>
												<div className="col-md-6 px-0">
													<div className="files-list">
														<div className="single-file">
															<div className="icon-text">
																<img src={ClipIcon} alt="" />
																<span>Attachment 1 .pdf</span>
															</div>
															<span className="removefile">Remove</span>
														</div>
														<div className="single-file">
															<div className="icon-text">
																<img src={ClipIcon} alt="" />
																<span>Attachment 1 .pdf</span>
															</div>
															<span className="removefile">Remove</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="ExecutorsOrTrustees">
												Executors Or Trustees (Optional)
											</label>
											<small className="notes-line">
												Executors And Trustees Are Persons Chosen By You Who Are
												Responsible For Administering Your Estate Afteryour
												Death.
											</small>
											<input
												type="text"
												name="ExecutorsOrTrustees"
												id="ExecutorsOrTrustees"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="FullName">Full Name</label>
											<input
												type="text"
												name="FullName"
												id="FullName"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="FullAddress">Full Address</label>
											<input
												type="text"
												name="FullAddress"
												id="FullAddress"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="RelationshipToYou">
												Relationship To You
											</label>
											<input
												type="text"
												name="RelationshipToYou"
												id="RelationshipToYou"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>

									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="AdditionalInformation">
												Additional Information
											</label>
											<textarea
												type="text"
												name="AdditionalInformation"
												id="AdditionalInformation"
												className="form-control"
												placeholder="Your answer"
											></textarea>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="Anyother">
												Any other additional information
											</label>
											<textarea
												type="text"
												name="Anyother"
												id="Anyother"
												className="form-control lg"
												placeholder="Your answer"
											></textarea>
										</div>
									</div>
									<div className="col-md-12 mt-2">
										<div className="form-group d-flex gap-3">
											<Link
												to="/will-parent-details"
												className="btn ms-auto px-5"
											>
												Back
											</Link>
											<button type="submit" className="btn px-5">
												Submit
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			<ContactSec />
			<Footer />
		</>
	);
};
export default WillAssetDetails;
