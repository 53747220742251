import React from "react";
const BulletSection = (props) => {
	return (
		<>
			{/* Our polices starts here */}
			<div className="container">
				<section className="our-policies">
					<div className="row align-items-center py-4">
						<div className="col-lg-1"></div>
						<div className="col-lg-5">
							<div className="policies-content-wrapper">
								<h6>{props?.firstheading}</h6>
								<h2>{props?.secondheading}</h2>
								<p>{props?.paragraph}</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="policies-list-wrapper">
								<ul>
									{props?.arr.map((data, index) => {
										return (
											<>
												<li>
													<i
														className="fa fa-check-circle"
														aria-hidden="true"
													></i>{" "}
													<span>{data}</span>
												</li>
											</>
										);
									})}
								</ul>
							</div>
						</div>
					</div>
				</section>
			</div>

			{/* Our polices End here */}
		</>
	);
};

export default BulletSection;
