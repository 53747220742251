
import React from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import ContactSec from '../../components/ContactSec';
import FormHeadings from '../../components/FormHeadings';
import { Link } from 'react-router-dom'
import { ClipIcon } from '../../constant';
import { useNavigate } from 'react-router-dom';
const StatementDetailsWifeDivorce = () => {
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        // perform form submission logic here
        navigate('/wife-divorce-nikah-details');
    }
    return (
        <>
            <Header />
            <section className="custom-forms">
                <div className="container">
                    <FormHeadings SmallHeading="Manchester Islamic Centre" MainHeading="Wife Seeking Divorce From Her Husband" containerSize="col-xl-9 col-lg-9 col-md-10 col-sm-12 mx-auto px-xl-4" Text="Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas " />

                    <div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
                        <div className="custom-form-start">
                            <div className="text main-points-service mb-4 pb-1">
                                <h6 className='form-inner-heading mt-1 mb-3'>Statement for Dissolution of Marriage</h6>
                                <p className='mb-0'>This section is reserved for the applicant to make her grievances against her spouse and to state the main reasons for petitioning for divorce.</p>
                                <p> Please bear in mind that the Manchester Shariah Council may ask you to provide evidence to support your submission/statement. To help us with your application and to help us get through this process properly and quickly  please use the points below to when writing your submission/ statement.</p>
                                <ol>
                                    <li>Be brief and precise.</li>
                                    <li>Please try to write all your statement in ENGLISH.</li>
                                    <li>Please make sure to print your name in full in BLOCK CAPITAL LETTERS, on every additional sheet/ document.</li>
                                    <li>Please also sign and date every sheet/ document.</li>
                                    <li>Please upload the original signed copy of your statement to us.</li>
                                    <li>Please keep a copy for your own records.</li>
                                </ol>
                                <p className='mt-4'>Please Note: A COPY OF YOUR STATMENT MAY BE SENT TO YOUR SPOUSE</p>
                            </div>

                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="reasonofdesolution">Main reason for applying the dissolution of marriage (less than 20 words)</label>
                                            <textarea type="text" name='reasonofdesolution' id='reasonofdesolution' className="form-control" placeholder='Your answer'></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3 pt-3">
                                            <label htmlFor="">Please upload your statement here:</label>
                                            <div className="file-uploader-custom">
                                                <label htmlFor="UploadFile" className='fileuploader'>
                                                    <input type="file" name="UploadFile" hidden id="UploadFile" />
                                                    <span>Drag & Drop .PDF Files here or click to upload</span>
                                                </label>
                                                <div className="col-md-6 px-0">
                                                    <div className="files-list">
                                                        <div className="single-file">
                                                            <div className="icon-text">
                                                                <img src={ClipIcon} alt="" />
                                                                <span>Attachment 1 .pdf</span>
                                                            </div>
                                                            <span className='removefile'>Remove</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <div className="form-group d-flex gap-3">
                                            <Link to="/wife-divorce-husband-details" className='btn ms-auto px-5'>Back</Link>
                                            <button type='submit' className='btn px-5'>Next</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section >
            <ContactSec />
            <Footer />
        </>
    )
}
export default StatementDetailsWifeDivorce;


