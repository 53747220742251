
import React from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import ContactSec from '../../components/ContactSec';
import FormHeadings from '../../components/FormHeadings';
import { Link } from 'react-router-dom'
import { ClipIcon } from '../../constant';
import { useNavigate } from 'react-router-dom';
const HusbandDetailsWifeDivorce = () => {
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        // perform form submission logic here
        navigate('/wife-divorce-statement'); 
    }
    return (
        <>
            <Header />
            <section className="custom-forms">
                <div className="container">
                    <FormHeadings SmallHeading="Manchester Islamic Centre" MainHeading="Wife Seeking Divorce From Her Husband" containerSize="col-xl-9 col-lg-9 col-md-10 col-sm-12 mx-auto px-xl-4" Text="Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas " />

                    <div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
                        <div className="custom-form-start">
                            <h6 className='form-inner-heading mt-1 mb-3'>Respondents Details ( Your Husband)</h6>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="FirstName">First Name *</label>
                                            <input type="text" name='FirstName' id='FirstName' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="MiddleName">Middle Name</label>
                                            <input type="text" name='MiddleName' id='MiddleName' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Surname">Surname *</label>
                                            <input type="text" name='Surname' id='Surname' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="DateOfBirth">Date Of Birth *</label>
                                            <input type="text" name='DateOfBirth' id='DateOfBirth' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="CountryOfBirth">Country Of Birth *</label>
                                            <input type="text" name='CountryOfBirth' id='CountryOfBirth' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Nationality">Nationality *</label>
                                            <input type="text" name='Nationality' id='Nationality' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Language">First Language / Home Language </label>
                                            <input type="text" name='Language' id='Language' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Ethnicity">Ethnicity *</label>
                                            <input type="text" name='Ethnicity' id='Ethnicity' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="FullAddress">Full Address <small>(Kindly Ensure That You Put The Correct Address As We Need To Contact The Husband)</small></label>
                                            <input type="text" name='FullAddress' id='FullAddress' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="TelephoneNumber">Telephone Number</label>
                                            <input type="text" name='TelephoneNumber' id='TelephoneNumber' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Email">Email <small>(Kindly Ensure That You Put The Correct Email Address As We Need To Contact The Husband)</small></label>
                                            <input type="text" name='Email' id='Email' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="MotherName">Mother Full Name</label>
                                            <input type="text" name='MotherName' id='MotherName' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="FatherName">Father Full Name</label>
                                            <input type="text" name='FatherName' id='FatherName' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label htmlFor="">ID Type *</label>
                                            <div className="custom-check-box-default">
                                                <label className="label-checkbox">Passport

                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Driving License
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Residence Card
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Other
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3 pt-3">
                                            <label htmlFor="">Please Upload Your Photo ID Here</label>
                                            <div className="file-uploader-custom">
                                                <label htmlFor="UploadFile" className='fileuploader'>
                                                    <input type="file" name="UploadFile" hidden id="UploadFile" />
                                                    <span>Drag & Drop .PDF Files here or click to upload</span>
                                                </label>
                                                <div className="col-md-6 px-0">
                                                    <div className="files-list">
                                                        <div className="single-file">
                                                            <div className="icon-text">
                                                                <img src={ClipIcon} alt="" />
                                                                <span>Attachment 1 .pdf</span>
                                                            </div>
                                                            <span className='removefile'>Remove</span>
                                                        </div>
                                                        <div className="single-file">
                                                            <div className="icon-text">
                                                                <img src={ClipIcon} alt="" />
                                                                <span>Attachment 1 .pdf</span>
                                                            </div>
                                                            <span className='removefile'>Remove</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-lg-3 mt-2">
                                        <div className="form-group mb-3">
                                            <label htmlFor="">Employment Status *</label>
                                            <div className="custom-check-box-default">
                                                <label className="label-checkbox">Employed
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Self Employed
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="label-checkbox">Unemployed
                                                    <input type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-2">
                                        <div className="form-group mb-3">
                                            <label htmlFor="Ocupation">Occupation *</label>
                                            <input type="text" name='Ocupation' id='Ocupation' className="form-control" placeholder='Your answer' />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <div className="form-group d-flex gap-3">
                                            <Link to="/wife-divorce-applicant-details" className='btn ms-auto px-5'>Back</Link>
                                            <button type='submit' className='btn px-5'>Next</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section >
            <ContactSec />
            <Footer />
        </>
    )
}
export default HusbandDetailsWifeDivorce;


