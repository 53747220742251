import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactSec from "../../components/ContactSec";
import FormHeadings from "../../components/FormHeadings";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ClipIcon } from "../../constant";
const NikkahAdditionalInfo = () => {
	return (
		<>
			<Header />
			<section className="custom-forms">
				<div className="container">
					<FormHeadings
						SmallHeading="Manchester Islamic Centre"
						MainHeading="Nikah Application Form"
						Text="Diam ut venenatis tellus in metus vulputate eu scelerisque. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae congue mauris rhoncus aenean vel. Et egestas "
					/>
					<div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 mx-auto">
						<div className="custom-form-start">
							<div className="top-heading my-3">
								<h6 className="form-inner-heading mb-3">
									Additional Information
								</h6>
								<p>
									Diam ut venenatis tellus in metus vulputate eu scelerisque.
									Commodo nulla facilisi nullam vehicula ipsum a arcu cursus
									vitae. Sed risus ultricies tristique nulla. Arcu cursus vitae
									congue mauris rhoncus aenean vel. Et{" "}
								</p>
							</div>
							<form action="/checkout">
								<div className="row">
									<div className="col-md-12">
										<div className="form-group mb-3">
											<label htmlFor="FullName">
												Additional Information{" "}
												<span style={{ color: "#1DA59A" }}>*</span>
											</label>
											<input
												type="text"
												name="FullName"
												id="FullName"
												className="form-control"
												placeholder="Your answer"
											/>
										</div>
									</div>
									<div className="col-md-12 mt-2">
										<div className="form-group d-flex gap-3 sub-btn-wrapper">
											<Link
												to="/nikkah-witness-2-details"
												className="btn ms-auto px-5"
											>
												Back
											</Link>
											<button type="submit" className="btn px-5">
												Submit
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			<ContactSec />
			<Footer />
		</>
	);
};
export default NikkahAdditionalInfo;
